export const objectParseValues = (spy) => {
  const result = {};
  Object.keys(spy).forEach((key) => {
    result[key] = JSON.parse(spy[key]);
  });
  return result;
};

export const findLowestCategory = (category) => {
  if (category[0].Kids && category[0].Kids.length > 0) {
    return findLowestCategory(category[0].Kids);
  }
  return category;
};
