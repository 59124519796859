import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { matchRoutes, Outlet, useLocation, Link as RouterLink } from 'react-router-dom';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { routes } from '../../constants/routes';
import { useUser } from '../../utils/user.context';
import { PeopleIcon } from '../../icons/people.icon';

export const Profile = () => {
  const location = useLocation();
  const { token, user, logOut } = useUser();
  const [{ route: allRoutes }, { route: currentRoute }] = matchRoutes(
    routes(token && user),
    location
  );

  return (
    <PageWrapper>
      <Container maxWidth="1440" disableGutters sx={{ mt: 3.25, mb: 8 }}>
        {user ? (
          <Grid container spacing={5}>
            <Grid item md={3} xs={12}>
              <Stack sx={{ background: '#F6F6F6', paddingTop: 3, paddingBottom: 2.25 }}>
                <Box sx={{ paddingX: '24px' }}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box
                      sx={{
                        display: 'flex',
                        borderRadius: '100px',
                        width: '40px',
                        height: '40px',
                        background: '#fff',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconButton>
                        <PeopleIcon />
                      </IconButton>
                    </Box>
                    <Stack>
                      <Typography variant="label14Semibold">
                        {`${user?.FirstName} ${user?.LastName}`}
                      </Typography>
                      <Typography variant="label13Gray">{user?.Phone}</Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Divider sx={{ marginTop: 3.5, marginX: 3 }} />
                <nav aria-label="secondary menu">
                  <List className="side-menu" sx={{ padding: 0 }}>
                    {allRoutes &&
                      allRoutes.children.map(({ path, title }) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            className={path === currentRoute.path && 'active'}
                            component={RouterLink}
                            to={path}
                          >
                            <ListItemText primary={title} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    <Divider sx={{ marginTop: 2.75, marginBottom: 1.5, marginX: 3 }} />
                    <ListItem disablePadding>
                      <ListItemButton component={RouterLink} to="/" onClick={logOut}>
                        <ListItemText primary="Вийти" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Stack>
            </Grid>
            <Grid item md={9} xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={5}>
            <Grid item md={3} xs={12}>
              <Stack sx={{ background: '#F6F6F6', paddingTop: 3, paddingBottom: 2.25 }}>
                <Box sx={{ paddingX: '24px' }}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box
                      sx={{
                        display: 'flex',
                        borderRadius: '100px',
                        width: '40px',
                        height: '40px',
                        background: '#fff',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconButton>
                        <PeopleIcon />
                      </IconButton>
                    </Box>
                    <Stack width="100%">
                      <Skeleton variant="text" width="100%" height={28} />
                      <Skeleton variant="text" width="100%" height={28} />
                    </Stack>
                  </Stack>
                </Box>
                <Divider sx={{ marginTop: 3.5, marginX: 3 }} />
                <nav aria-label="secondary menu">
                  <List className="side-menu" sx={{ padding: 0 }}>
                    {allRoutes &&
                      allRoutes.children.map(({ path, title }) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            className={path === currentRoute.path && 'active'}
                            component={RouterLink}
                            to={path}
                          >
                            <ListItemText primary={title} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    <Divider sx={{ marginTop: 2.75, marginBottom: 1.5, marginX: 3 }} />
                    <ListItem disablePadding>
                      <ListItemButton component={RouterLink} to="/" onClick={logOut}>
                        <ListItemText primary="Вийти" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Stack>
            </Grid>
            <Grid item md={9} xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        )}
      </Container>
    </PageWrapper>
  );
};
