/* eslint-disable */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useLocalStorage } from './useLocalStorage';
import { GTMAddToWishlist } from '../services/analytic-events';
import { parsePrice } from './parse-price';
import { useUser } from './user.context';
import { useFavoritesAdd, useFavoritesDelete, useFavoritesRead } from '../services/profile.service';
import { isArray } from 'lodash';

export const WishlistContext = createContext({});

export const WishlistContextProvider = ({ children }) => {
  const [items, setItems] = useLocalStorage('saved', []);
  const [showDrawer, setShowDrawer] = useState(false);
  const { user } = useUser();
  const { mutate: readFavoritesFromServer, data: favoritesFromServer } = useFavoritesRead();
  const { mutate: addFavoritesToServer, isSuccess: addSuccess } = useFavoritesAdd();
  const { mutate: deleteFavoritesFromSever, isSuccess: deleteSuccess } = useFavoritesDelete();

  const totalCount = useMemo(
    () => (items && items.reduce((acc, item) => acc + item.count, 0)) || 0,
    [items]
  );

  const inWishlist = (id) => items.find(({ UID }) => UID === id);

  const toggleItem = (item, size) => {
    const itemsList = [...items];
    const itemIndex = items.findIndex(({ UID }) => UID === item.UID);
    const newItem = {
      UID: item.UID,
      size: size.GUID,
    };

    if (itemIndex >= 0) {
      removeItem(item.UID);
      user &&
        deleteFavoritesFromSever({
          GUID_Nomenclature: item.UID,
          GUID_Size: size.GUID,
        });
    } else {
      itemsList.push(newItem);
      user &&
        addFavoritesToServer({
          GUID_Nomenclature: item.UID,
          GUID_Size: size.GUID,
        });
      GTMAddToWishlist(item);

      setItems(itemsList);
    }
  };

  const removeItem = (UID) => {
    console.log(
      'items: ',
      items.filter((item) => item.UID !== UID)
    );
    setItems(items.filter((item) => item.UID !== UID));
  };

  const clearCart = () => {
    setItems([]);
  };

  const changeSize = (UID, size) => {
    setItems(items.map((item) => (item.UID === UID ? { ...item, size } : item)));
  };

  const updateFavoritesFromServer = () => {
    user && readFavoritesFromServer();
  };

  const props = useMemo(
    () => ({
      items,
      totalCount,
      addItem: toggleItem,
      removeItem,
      showDrawer,
      setShowDrawer,
      clearCart,
      changeSize,
      inWishlist,
    }),
    [items, showDrawer]
  );

  useEffect(() => {
    updateFavoritesFromServer();
  }, [user]);

  useEffect(() => {
    if (addSuccess) {
      updateFavoritesFromServer();
    }
  }, [addSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      updateFavoritesFromServer();
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (favoritesFromServer && isArray(favoritesFromServer)) {
      setItems(
        favoritesFromServer.map((item) => ({ UID: item.GUID_Nomenclature, size: item.GUID_Size }))
      );
    }
    if (typeof favoritesFromServer === 'string' || !user) {
      setItems([]);
    }
  }, [favoritesFromServer]);

  return <WishlistContext.Provider value={props}>{children}</WishlistContext.Provider>;
};

WishlistContextProvider.propTypes = {
  children: PropTypes.node,
};
