import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ProductListComponent } from './product-list.component';
import { useNomenclatureListGet } from '../../services/nomenclature-list.service';

export function ProductListContainer({ filter, priceOutlet, maxCount = 12, pagination, ...props }) {
  const { setParameters, data, isLoading } = useNomenclatureListGet();
  const [sortBy, setSortBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const products = useMemo(
    () => data && data?.data && Array.isArray(data.data) && data.data.slice(0, maxCount),
    [data?.data]
  );

  useEffect(() => {
    if (filter) {
      const parameters = {
        filter,
        sortBy,
        page_number: (currentPage - 1).toString(),
        items: maxCount.toString(),
      };
      if (priceOutlet) parameters.priceOutlet = priceOutlet.toString();
      setParameters(parameters);
    } else {
      setParameters({});
    }
  }, [filter, sortBy, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  return (
    <ProductListComponent
      {...props}
      products={products}
      isLoading={isLoading}
      sortBy={sortBy}
      setSortBy={setSortBy}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pagination={pagination && data?.meta}
    />
  );
}

ProductListContainer.propTypes = {
  columns: PropTypes.number,
  priceOutlet: PropTypes.bool,
  toAll: PropTypes.func,
  header: PropTypes.bool,
  pagination: PropTypes.bool,
  slider: PropTypes.bool,
  filter: PropTypes.object,
  maxCount: PropTypes.number,
  fromKey: PropTypes.string,
};
