import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, IconButton, Rating, Stack, Typography } from '@mui/material';
import { StarIcon } from '../../../../icons/star-icon';
import { ArrowRightIcon } from '../../../../icons/arrow-right.icon';
import { DislikeIcon } from '../../../../icons/dislike.icon';
import { LikeIcon } from '../../../../icons/like.icon';
import { AddCommentModal } from '../modal/add-comment-modal.component';
import { useGetComments } from '../../../../services/comments.service';
import chatImage from '../../../../assets/images/chat.svg';
import chatArrow from '../../../../assets/images/chat-arrow.svg';

export function Reviews() {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const { mutate, data } = useGetComments();

  const averageRating = useMemo(
    () =>
      data && Array.isArray(data)
        ? data.reduce((acc, item) => acc + parseInt(item.grade, 10), 0) / data.length
        : undefined,
    [data]
  );

  const loadComments = () => {
    mutate({ ID_nomenclature: id, moderation: 'true' });
  };

  useEffect(() => {
    loadComments();
  }, []);

  useEffect(() => {
    loadComments();
  }, [commentModalOpen]);

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
        mt={8}
      >
        <Typography
          variant="subtitle1"
          component="p"
          textTransform="uppercase"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('feedback')}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          sx={{ padding: '6px 14px' }}
          onClick={() => {
            setCommentModalOpen(true);
          }}
        >
          {t('addFeedback')}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }} mt={3}>
        {averageRating && (
          <Typography variant="headingSmall" component="span">
            {averageRating}
          </Typography>
        )}
        <StarIcon />
        {!averageRating && (
          <Typography variant="label13Gray" component="span">
            {t('noRating')}
          </Typography>
        )}
      </Stack>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <div>
        {Array.isArray(data) ? (
          data.map((comment) => (
            <Box>
              <Typography variant="textLink" className="dark" component="p">
                {comment.user}
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 1.5, ml: -0.5 }}>
                <Rating size="small" value={comment.grade} readOnly />
                <Typography
                  variant="subtitle1"
                  className="lato"
                  component="p"
                  sx={{ lineHeight: 2 }}
                >
                  12.12.2022
                </Typography>
              </Stack>
              <Typography variant="subtitle1" className="lato" component="p" sx={{ mt: 1.5 }}>
                {comment.comment}
              </Typography>
              <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt: 4.75 }}>
                <IconButton sx={{ padding: 0 }}>
                  <ArrowRightIcon />
                  <Typography variant="buttons" component="p" sx={{ ml: 1 }}>
                    {t('addAnswer')}
                  </Typography>
                </IconButton>
                <Stack direction="row" spacing={1}>
                  <IconButton>
                    <LikeIcon sx={{ width: '16px', height: '16px' }} />
                    <Typography variant="textLink" component="span" sx={{ ml: 1 }}>
                      0
                    </Typography>
                  </IconButton>
                  <IconButton>
                    <DislikeIcon />
                    <Typography variant="textLink" component="span" sx={{ ml: 1 }}>
                      0
                    </Typography>
                  </IconButton>
                </Stack>
              </Stack>
              <Divider sx={{ mt: 2.75, mb: 3 }} />
            </Box>
          ))
        ) : (
          <Box sx={{ mt: 4, mb: 12 }}>
            <Stack alignItems="center">
              <div>
                <img src={chatImage} alt="" />
              </div>
              <Stack
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="center"
                sx={{ width: '100%', position: 'relative' }}
              >
                <Typography
                  variant="p"
                  component="h2"
                  align="center"
                  className="montserrat"
                  sx={{ mt: 4, maxWidth: '300px' }}
                >
                  {t('noFeedback')}
                </Typography>
                <Box sx={{ position: 'absolute', right: '45px' }}>
                  <img src={chatArrow} alt="" />
                </Box>
              </Stack>
            </Stack>
          </Box>
        )}
      </div>
      <AddCommentModal
        show={commentModalOpen}
        handleClose={() => {
          setCommentModalOpen(false);
        }}
      />
    </Box>
  );
}
