import { useMutation } from '@tanstack/react-query';
import { apiService } from './api.service';

export function useGetComments() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.post(`comments/`, params),
  });
  return { mutate, isSuccess, data };
}

export function useAddComment() {
  const { mutate, isSuccess, isInitialLoading, data } = useMutation({
    mutationFn: (params) =>
      apiService.put(`comments/`, {
        parentID: '',
        ...params,
      }),
  });
  return { mutate, isSuccess, isInitialLoading, data };
}
