/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';
import { ProductItemContainer } from '../../../../components/product-item/product-item.container';
import { SampleNextArrow, SamplePrevArrow } from '../../../product/components/slider-arrows/arrows';
import { GtagViewItemList, GTMViewItemList } from '../../../../services/analytic-events';
import { normalizeNomenclature } from '../../../../utils/normalizers';

export const HitBlock = () => {
  const { data } = useGetBlockWithBanners(blockTypes.seazon_hit_banner);
  const [loadedProducts, setLoadedProducts] = useState([]);

  const settings = {
    slidesToScroll: 1,
    slidesToShow: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    infinite: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          appendDots: (dots) => (
            <div className="slick-dots slick-dots__flex">
              <ul>{dots}</ul>
            </div>
          ),
          dotsClass: 'slick-dots slick-dots__flex',
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const products = useMemo(() => {
    if (data && data.block && data.block.description) return JSON.parse(data.block.description);
    return [];
  }, [data]);

  const onProductLoaded = (item) => {
    setLoadedProducts([...loadedProducts, item]);
  };

  useEffect(() => {
    if (loadedProducts.length === products.length && loadedProducts.length !== 0) {
      const normalizedLoadedProducts = loadedProducts.map(normalizeNomenclature);
      GtagViewItemList(normalizedLoadedProducts);
      GTMViewItemList(normalizedLoadedProducts, 'Season Hit');
    }
  }, [products, loadedProducts]);

  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 7, xs: 2 },
        pt: 7.25,
        mb: 12,
        background: 'linear-gradient(0, #fff 54%, #EEE0D8 54%)',
      }}
    >
      <Typography variant="h5" component="h2" align="center" sx={{ mb: 4 }}>
        {data.block.title}
      </Typography>
      {products.length > 0 && (
        <Slider {...settings} className="products-list">
          {products.slice(0, 4).map(
            (item) =>
              item && (
                <Box>
                  <ProductItemContainer
                    key={item.value}
                    UID={item.value}
                    onProductLoaded={onProductLoaded}
                    fromKey={'SeasonHit'}
                  />
                </Box>
              )
          )}
        </Slider>
      )}
      <Stack justifyContent="center" direction="row">
        <Button href={data.block.link_button} variant="outlined" sx={{ mt: 4, mx: 'auto' }}>
          {data.block.link_button_text}
        </Button>
      </Stack>
    </Container>
  );
};
