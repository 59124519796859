import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import videoBackground from '../../../../assets/images/video-backgroud.png';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';

export const AboutUs = () => {
  const { data } = useGetBlockWithBanners(blockTypes.about_us);
  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 7, xs: 3 },
        mt: 7.25,
        pt: 12,
        pb: 15,
        background: `url(${videoBackground})`,
      }}
    >
      <Stack
        direction="column"
        sx={{ alignItems: 'center', maxWidth: '642px', textAlign: 'center', margin: 'auto' }}
      >
        <Typography variant="h5" component="h2">
          {data.block.title}
        </Typography>
        <Typography variant="p" component="p" sx={{ mt: 3 }}>
          {data.block.description}
        </Typography>
      </Stack>
    </Container>
  );
};
