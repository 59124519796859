import React, { useContext, useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { CategoryFiltersContext } from '../../../utils/categoryFiltersContex';
import { MobileMainList } from '../mobile-main-list/mobile-main-list';
import { MobileSublist } from '../mobile-sublist/mobile-sublist';
import { SubmenuMobileComponent } from '../../main-menu/sub-menu-mobile/submenu-mobile.component';

export function MobileFilterDrawer({ open, setOpen, filters, values, updatePath }) {
  const [submenu, setSubmenu] = useState();
  const [openMenu, setOpenMenu] = useState();
  const location = useLocation();
  const { genderCategory, currentUrl } = useContext(CategoryFiltersContext);
  const [updatedValues, setUpdatedValues] = useState(values);

  const onChange = (parent, value) => {
    const newOptions = parent.options.filter((item) => value.includes(item.Name));
    setUpdatedValues({ ...updatedValues, [parent.key]: newOptions });
  };

  const onSave = () => {
    updatePath({ filters: updatedValues });
  };

  useEffect(() => {
    setUpdatedValues(values);
  }, [values]);

  useEffect(() => {
    setSubmenu(undefined);
    setOpenMenu(false);
    setOpen(false);
  }, [location.pathname]);

  const renderMenuCurrentState = () => {
    if (openMenu)
      return (
        <SubmenuMobileComponent
          menu={genderCategory}
          onClose={() => setOpenMenu(false)}
          main={false}
          catalogPath={currentUrl}
        />
      );
    if (submenu === undefined)
      return (
        <MobileMainList
          filters={filters}
          values={values}
          setSubmenu={setSubmenu}
          setOpenMenu={setOpenMenu}
          setOpen={setOpen}
          options={updatedValues}
          onSave={onSave}
        />
      );
    return (
      <MobileSublist
        filter={submenu}
        value={
          Object.prototype.hasOwnProperty.call(updatedValues, submenu.key)
            ? updatedValues[submenu.key]
            : []
        }
        onClose={() => setSubmenu(undefined)}
        onChange={onChange}
        onSave={onSave}
      />
    );
  };

  return (
    <Drawer
      style={{ zIndex: 9999 }}
      PaperProps={{ style: { top: 'auto', maxHeight: 'calc(100vh)' } }}
      disablePortal
      anchor="top"
      open={open}
      onClose={() => {}}
      onOpen={() => {}}
    >
      {renderMenuCurrentState()}
    </Drawer>
  );
}

MobileFilterDrawer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updatePath: PropTypes.func,
  values: PropTypes.object,
};
