import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SliderArrowIcon } from '../../icons/slider-arrow.icon';

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Box
      sx={{
        display: visible ? 'inline' : 'none',
        cursor: 'pointer',
        position: 'fixed',
        right: '60px',
        bottom: '60px',
        transform: 'rotate(270deg) translate(0px, 24px)',
        width: '48px',
        height: '48px',
        zIndex: 99999,
      }}
      onClick={scrollToTop}
      role="button"
      tabIndex={0}
    >
      <SliderArrowIcon sx={{ width: '100%', height: '100%' }} />
    </Box>
  );
};
