/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import { Container, Grid, Paper, styled, Typography } from '@mui/material';
import { ProductDescription } from './components/product-description/product-description.component';
import { Reviews } from './components/reviews/reviews.component';
import { ProductSlider } from './components/product-slider/product-slider.desktop';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { useNomenclatureGet } from '../../services/nomenclature.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { findLowestCategory } from '../../utils/arrayUtils';
import { ProductListContainer } from '../../components/product-list/product-list.container';
import {
  GtagViewItem,
  GtagViewItemList,
  GTMViewItem,
  GTMViewItemList,
} from '../../services/analytic-events';
import { normalizeNomenclature } from '../../utils/normalizers';

const PaperStyle = styled(Paper)(({ image, height = '416px' }) => ({
  height,
  background: `url(${image})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  border: 'none',
}));

export function Product() {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const { mutate, data, isSuccess } = useNomenclatureGet();

  const productItem = useMemo(() => {
    if (isSuccess && data) {
      const item = normalizeNomenclature(data.data[0]);

      GtagViewItem(item);
      GTMViewItem(item);
      return item;
    }
    return undefined;
  }, [isSuccess, data]);

  const category = useMemo(() => {
    if (isSuccess && data) return findLowestCategory(data.data[0].categories);
    return undefined;
  }, [isSuccess, data]);

  useEffect(() => {
    mutate({ ID_nomenclature: id });
  }, [id]);

  return isSuccess && productItem ? (
    <PageWrapper productName={productItem.fullname}>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 3 } }}>
        <Grid container columnSpacing={0} mt={2}>
          <Grid item md={8} xs={12}>
            <ProductSlider photo={productItem.photos} />
            <Reviews />
          </Grid>
          <Grid item md={4} xs={12} sx={{ pl: { md: '87px', xs: 0 } }}>
            <ProductDescription product={productItem} />
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="1440"
        disableGutters
        sx={{ px: { md: 6, xs: 3 }, display: { md: 'block', xs: 'none' } }}
      >
        <Grid item mt={8.25}>
          <Typography variant="h6" component="h2" align="center" textTransform="uppercase">
            {t('complementImage')}
          </Typography>
          <Grid container columnGap={5}>
            <Grid item xs={3} sx={{ mt: 5 }}>
              <PaperStyle
                variant="outlined"
                square
                height="480px"
                image={productItem.mainPhoto.url}
              />
            </Grid>
            <Grid item xs={8}>
              {data && (
                <ProductListContainer
                  fromKey={'CrossSale'}
                  filter={{
                    menu: category,
                  }}
                  columns={3}
                  maxCount={3}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 3 }, mb: 12 }}>
        <Grid item mt={12}>
          <Typography
            variant="h5"
            component="h2"
            align="center"
            sx={{ mb: 5 }}
            textTransform="uppercase"
          >
            {t('recommended')}
          </Typography>
          {data && (
            <ProductListContainer
              slider
              fromKey={'Recommended'}
              filter={{
                menu: category,
              }}
              columns={4}
              maxCount={10}
            />
          )}
          {/*<Stack justifyContent="center" direction="row">*/}
          {/*  <Button variant="outlined" sx={{ mt: 8 }}>*/}
          {/*    ПЕРЕЙТИ К ПОКУПКАМ*/}
          {/*  </Button>*/}
          {/*</Stack>*/}
        </Grid>
      </Container>
    </PageWrapper>
  ) : (
    <div></div>
  );
}
