/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import crypto from 'crypto';
import * as PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export const LiqPay = forwardRef((props, ref) => {
  const formRef = useRef(null);
  const privateKey = process.env.REACT_APP_PRIVATE_KEY;
  const jsonString = {
    public_key: process.env.REACT_APP_PUBLIC_KEY,
    version: '3',
    action: 'pay',
    amount: props.amount,
    currency: 'UAH',
    description: 'Payment for product',
    order_id: props.orderId,
    result_url: `${process.env.REACT_APP_PAYMENT_RESULT_URL}?orderId=${props.orderId}`,
    product_description: 'Оплата товарів',
  };

  const data = utf8_to_b64(JSON.stringify(jsonString));
  const signString = privateKey + data + privateKey;
  const sha1 = crypto.createHash('sha1');
  sha1.update(signString);
  const signature = sha1.digest('base64');

  const handleSubmit = () => {
    formRef.current.submit();
  };

  useImperativeHandle(ref, () => ({
    sendPayment: () => {
      handleSubmit();
    },
  }));

  return (
    <form
      ref={formRef}
      method="POST"
      action="https://www.liqpay.ua/api/3/checkout"
      acceptCharset="utf-8"
    >
      <input type="hidden" name="data" value={data} />
      <input type="hidden" name="signature" value={signature} />
    </form>
  );
});

LiqPay.propTypes = {
  amount: PropTypes.number,
  orderId: PropTypes.string,
};
