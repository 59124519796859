import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { generatePath } from 'react-router-dom';

export function nameToURL(name) {
  const regExp = /(-)\1{1,}/;
  return cyrillicToTranslit().transform(name, '-').toLowerCase().replace(regExp, '-');
}
export function URLToName(name) {
  return cyrillicToTranslit().reverse(name, '-').toLowerCase();
}

export function removeTrailingSlash(str) {
  return str.replace(/([^:]\/)\/+/g, '$1');
}

export const generatePathWithParameters = (url, parameters) =>
  removeTrailingSlash(
    generatePath(
      `${url || '.'}/${Object.keys(parameters)
        .map((name) => `:${name}/`)
        .join('')}`,
      parameters
    )
  );
