import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const PaymentComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Оплата</Typography>
    <Divider />
    <Typography variant="subheadingMedium">Оплата замовлення</Typography>
    <Typography variant="body2">
      Ви можете оплатити замовлення одним з перерахованих способів:
    </Typography>
    <Typography variant="body2">
      <ul style={{ paddingLeft: '24px', margin: '4px' }}>
        <li>банківською картою на сайті; </li>
        <li>
          готівкою через накладений платіж при отриманні замовлення в компанії «Нова пошта» (при
          замовленнях на суму до 3500 грн до оплати буде також додана вартість доставки і комісія
          компанії «Нова пошта» за послуги грошового переказу).
        </li>
      </ul>
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Як оплатити банківською картою</Typography>
    <Typography variant="body2">
      В кошику з товаром виберіть оплату «Купити в один клік», введіть своє ім&apos;я та номер
      телефону, натисніть кнопку «оплатити з LIQPAY». Ви автоматично потрапите на сторінку платіжної
      системи, де необхідно ввести свої реквізити:
    </Typography>
    <Typography variant="body2">
      <ul style={{ paddingLeft: '24px', margin: '4px' }}>
        <li>номер банківської карти</li>
        <li>термін її дії</li>
        <li>CVV2 код</li>
      </ul>
    </Typography>
    <Typography variant="body2">
      Натисніть кнопку «Продовжити». Після цього на сторінці з&apos;явиться інформація про оплату.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Як оплатити готівкою при отриманні посилки</Typography>
    <Typography variant="body2">
      В кошику товару виберіть оплату «Готівкою при отриманні (післяплата)». Ви зможете оплатити
      замовлення готівкою в відділенні компанії «Нова пошта» при отриманні посилки.
    </Typography>
    <Typography variant="body2">
      Зверніть увагу: вибираючи цей спосіб оплати, необхідно окремо оплатити комісію компанії «Нова
      пошта» за послуги грошового переказу, а також вартість доставки, якщо сума вашого замовлення
      менше 3500 грн. Витрати на доставку для замовлень на суму від 3500 грн ми беремо на себе.
    </Typography>
    <Typography variant="body2">
      Увага! Якщо сума замовлень перевищує 5000 грн в день, для отримання замовлення необхідно
      пред&apos;явити паспорт для верифікації та ідентифікації. Це стосується операцій, як в
      готівковій формі, так і з використанням банківської платіжної картки.
    </Typography>
  </Stack>
);
