import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CatalogContainer } from '../../components/catalog/catalog.container';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';

export function CategoryCatalog() {
  const [searchParams] = useSearchParams();
  const menu = useMemo(() => JSON.parse(searchParams.get('menu')), [searchParams]);
  const sex = useMemo(() => JSON.parse(searchParams.get('sex')), [searchParams]);

  return (
    <PageWrapper>
      <CatalogContainer title={menu?.Parent || sex?.Name} />
    </PageWrapper>
  );
}
