import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CatalogContainer } from '../../components/catalog/catalog.container';
import { useGetBrand } from '../../services/brand.service';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';

export function BrandCatalog() {
  const [searchParams] = useSearchParams();
  const brand = useMemo(() => JSON.parse(searchParams.get('brand')), [searchParams]);
  const { data } = useGetBrand();

  const currentBrand = useMemo(
    () => brand && data?.find((item) => item.name === brand[0]?.Name),
    [data]
  );

  return (
    <PageWrapper>
      <CatalogContainer title={currentBrand?.name} description={currentBrand?.description} />
    </PageWrapper>
  );
}
