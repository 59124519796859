/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@mui/material';
import * as PropTypes from 'prop-types';

export const HeartIcon = (props) => (
  <SvgIcon viewBox="0 0 22 20" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M11 18.9325L9.6225 17.6785C4.73 13.242 1.5 10.316 1.5 6.725C1.5 3.799 3.799 1.5 6.725 1.5C8.378 1.5 9.9645 2.2695 11 3.4855C12.0355 2.2695 13.622 1.5 15.275 1.5C18.201 1.5 20.5 3.799 20.5 6.725C20.5 10.316 17.27 13.242 12.3775 17.688L11 18.9325Z"
      stroke="#121212"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinejoin="round"
      {...props}
    />
  </SvgIcon>
);

HeartIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
