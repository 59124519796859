/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Pagination,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as PropTypes from 'prop-types';
import { SORT_BY_VALUES } from '../../constants/productSort';
import { selectMenuProps } from '../../utils/dropdownUtils';
import { useTranslation } from 'react-i18next';
import { ProductListLoaderComponent } from './product-list-loader/product-list-loader.component';
import { ProductsComponent } from './products/products.component';
import { GtagViewItemList, GTMViewItemList } from '../../services/analytic-events';

const DefaultWrapper = ({ children }) => <div>{children}</div>;

export function ProductListComponent({
  columns = 4,
  header,
  pagination,
  toAll,
  slider,
  products,
  isLoading,
  currentPage,
  setCurrentPage,
  sortBy,
  setSortBy,
  fromKey,
  wrapper: WrapperComponent = DefaultWrapper,
}) {
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (!isLoading && Array.isArray(products) && fromKey) {
      GtagViewItemList(products);
      GTMViewItemList(products, fromKey);
    }
  }, [isLoading]);

  return (
    <WrapperComponent visible={!isLoading && Array.isArray(products) && products.length > 0}>
      <Stack sx={{ mt: 5, mb: header ? 7.25 : 4, minHeight: { sx: '300px', md: '50vh' } }}>
        {header && (
          <Stack
            sx={{ mt: 0, mb: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {isLoading ? t('loading') : `${pagination?.totalItems || 0} ${t('goods')}`}
            </Typography>
            <Select
              id="sort"
              value={sortBy}
              renderValue={(item) => <Typography>{item}</Typography>}
              onChange={(event) => setSortBy(event.target.value)}
              input={
                <Input
                  className="clear"
                  startAdornment={
                    <InputAdornment disableTypography position="start">
                      {t('sorting')}:
                    </InputAdornment>
                  }
                />
              }
              MenuProps={selectMenuProps(380)}
            >
              {SORT_BY_VALUES.map((item) => (
                <MenuItem key={item} value={item}>
                  <Radio checked={sortBy === item} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
        {!isLoading ? (
          <ProductsComponent
            slider={slider}
            products={products}
            columns={columns}
            fromKey={fromKey}
          />
        ) : (
          <ProductListLoaderComponent columns={columns} />
        )}
        <Grid container>
          {pagination && pagination.totalItems > 0 && pagination.totalItems > pagination.items && (
            <Grid item xs={12} sx={{ marginTop: 5 }} justifyContent="space-around" display="flex">
              <Pagination
                count={Math.ceil((pagination.totalItems || 0) / pagination.items)}
                page={currentPage}
                onChange={(v, page) => setCurrentPage(page)}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          )}
        </Grid>
        {toAll && (
          <Stack justifyContent="center" direction="row">
            <Button variant="outlined" sx={{ mt: 8 }} onClick={toAll}>
              {t('seeAll')}
            </Button>
          </Stack>
        )}
      </Stack>
    </WrapperComponent>
  );
}

ProductListComponent.propTypes = {
  columns: PropTypes.number,
  priceOutlet: PropTypes.bool,
  toAll: PropTypes.func,
  header: PropTypes.bool,
  pagination: PropTypes.bool,
  slider: PropTypes.bool,
  fromKey: PropTypes.string,
};
