/* eslint-disable */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { TopDrawer } from '../../top-drawer/top-drawer';
import { MainMenuDrawerMobileComponent } from '../main-menu-mobile/main-menu-drawer-mobile.component';
import { SubmenuMobileComponent } from '../sub-menu-mobile/submenu-mobile.component';
import { CATEGORY_CATALOG } from '../../../constants/routes';

export const MobileMenuDrawerComponent = ({ menu, open }) => {
  const [submenu, setSubmenu] = useState();

  return (
    <TopDrawer show={open} setShow={() => {}}>
      {menu !== undefined &&
        (submenu === undefined ? (
          <MainMenuDrawerMobileComponent menu={menu} setSubmenu={setSubmenu} />
        ) : (
          <SubmenuMobileComponent
            menu={submenu}
            onClose={() => setSubmenu(undefined)}
            catalogPath={CATEGORY_CATALOG.path}
          />
        ))}
    </TopDrawer>
  );
};

MobileMenuDrawerComponent.propTypes = {
  menu: PropTypes.array.isRequired,
};
