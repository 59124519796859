import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useTranslation } from 'react-i18next';
import { ArrowRightRoundedIcon } from '../../icons/arrow-right-rounded.icon';
import bannerPlaceholder from '../../assets/images/collection-placeholder.png';

const bannerStyles = () => ({
  position: 'relative',
  backgroundImage: `url("${bannerPlaceholder}")`,
  height: { md: '400px', xs: '432px' },
  flex: '1',
  backgroundSize: 'cover',
  mt: 2,
});

const introStyles = () => ({
  maxWidth: '377px',
});

export function Banner({ title, description, image, toAll }) {
  const [swiper, setSwiper] = useState(null);
  const { t } = useTranslation('translation');

  const slideTo = (index) => swiper.slideTo(index);

  return (
    <Swiper slidesPerView={1} onSwiper={setSwiper}>
      <SwiperSlide style={{ paddingBottom: 0 }}>
        <Box sx={bannerStyles}>
          <Grid
            container
            alignItems={{ md: 'center', xs: 'flex-end' }}
            justifyContent="flex-end"
            sx={{ height: '100%' }}
          >
            <Grid item md={6} xs={12} sx={{ px: { md: 10, xs: 2 }, py: { md: 10, xs: 4 } }}>
              <Stack alignItems="flex-start" sx={introStyles}>
                {image && (
                  <img
                    src={
                      image ||
                      'https://s1.hostingkartinok.com/uploads/images/2022/02/8f85d6c80c54d3f5a00744d766417c06.png'
                    }
                    alt="brand logo"
                  />
                )}
                {title && (
                  <Typography
                    className="white"
                    variant="h4"
                    component="h4"
                    sx={{ textAlign: { md: 'left', xs: 'center' } }}
                  >
                    {title}
                  </Typography>
                )}
                <Stack
                  direction={{ md: 'row', xs: 'column' }}
                  sx={{ mt: { md: 8.75, xs: 5 }, width: '100%' }}
                  gap={{ md: 4, xs: 1 }}
                >
                  <Button variant="contained" color="secondary" onClick={toAll} fullWidth>
                    {t('seeAll')}
                  </Button>
                  <Button onClick={() => slideTo(1)} variant="outlined" color="secondary" fullWidth>
                    {t('more')}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Stack alignItems={{ md: 'center', xs: 'flex-start' }} sx={{ p: { md: 8, xs: 2 } }}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'center', xs: 'flex-start' }}
            gap={{ md: 2, xs: 3 }}
          >
            <IconButton onClick={() => slideTo(0)}>
              <ArrowRightRoundedIcon />
            </IconButton>
            {image && (
              <img
                src={
                  image ||
                  'https://s1.hostingkartinok.com/uploads/images/2022/02/8f85d6c80c54d3f5a00744d766417c06.png'
                }
                alt="brand logo"
              />
            )}
            {title && (
              <Typography className="white" variant="h5" component="h5" sx={{ maxWidth: '377px' }}>
                {title}
              </Typography>
            )}
          </Stack>
          <Typography
            variant="p"
            component="p"
            textAlign={{ md: 'center', xs: 'left' }}
            sx={{
              maxWidth: '806px',
              mt: 5,
              overflow: 'hidden',
              maxHeight: { md: '400px', xs: '217px' },
            }}
          >
            {description ||
              'Бренд Lauren Ralph Lauren был основан американским модельером, дизайнером и предпринимателем Ральфом Лореном. Стиль этой легендарной марки — это своеобразный микс английского кантри и американского вестерна, спортивных элементов и элегантности. В женской линейке Lauren Ralph Lauren можно найти роскошные вечерние платья в пол: серебряно-золотые модели с отливом и элегантные черные на бретелях. Также в коллекции представлены разнообразные сумки: объемные тоуты, выполненные в черном оттенке, с контрастными коричневыми ручками, сумки-трапеции красных тонов и маленькие полукруглые черные saddle bags из натуральной кожи с длинной ручкой.'}
          </Typography>
        </Stack>
      </SwiperSlide>
    </Swiper>
  );
}

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.bool,
  toAll: PropTypes.func,
};
