import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { PlusIcon } from '../../../icons/plus.icon';
import { generatePathWithParameters, nameToURL } from '../../../utils/friendlyURl';
import { MenuBannerComponent } from '../menu-banner/menu-banner.component';

export const SubmenuMobileComponent = ({ menuBanner, menu, onClose, main = true, catalogPath }) => {
  const [open, setOpen] = useState({});

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#fff',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <IconButton onClick={onClose}>
          <KeyboardBackspace sx={{ color: '#121212' }} />
        </IconButton>
        <Typography variant="subtitle3" className="bold" component="div">
          {menu.Name}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: main ? '#fff' : '#121212' }} />
        </IconButton>
      </Stack>
      <List component="nav" aria-label="submenu" sx={{ mt: 3.25 }}>
        {menu !== undefined &&
          menu.Kids &&
          menu.Kids.map(({ Name, Kids, GUID }) => (
            <>
              <Divider />
              <ListItemButton
                disableGutters
                component="div"
                onClick={() => setOpen({ ...open, [GUID]: !open[GUID] || false })}
              >
                <ListItemText primary={Name} />
                <PlusIcon />
              </ListItemButton>
              <Collapse in={open[GUID] || false} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {Kids.map((item) => (
                    <ListItemButton
                      sx={{ pl: 1.5 }}
                      component={RouterLink}
                      to={generatePathWithParameters(catalogPath, {
                        gender: nameToURL(menu.Name),
                        category: nameToURL(Name),
                        subcategory: nameToURL(item.Name),
                      })}
                    >
                      <ListItemText primary={item.Name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ))}
        <Divider />
      </List>
      {main && <MenuBannerComponent bannerSrc={menuBanner} menu={menu} />}
    </Box>
  );
};

SubmenuMobileComponent.propTypes = {
  menuBanner: PropTypes.string,
  menu: PropTypes.object,
  onClose: PropTypes.func,
  main: PropTypes.bool,
  catalogPath: PropTypes.string,
};
