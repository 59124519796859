import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const PrivacyPolicyComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Політики конфіденційності</Typography>
    <Divider />
    <Typography variant="body2">
      Інтернет-магазин To the nines приділяє велику увагу захисту персональних даних своїх
      користувачів. користувачі інтернет – магазина можуть переглядати сторінки сайту не
      повідомляючи про себе ніяких персональних даних.
    </Typography>
    <Typography variant="body2">
      Однак, для надання послуг, пов&apos;язаних з купівлею товарів, представлених в нашому інтернет
      - магазині, нам потрібні Ваші контактні дані для того, щоб ми могли зв&apos;язатися з Вами,
      підтвердити замовлення і доставити Вам замовлений товар.
    </Typography>
    <Typography variant="body2">
      Ваші персональні дані ми не передаємо третім особам і захищаємо їх конфіденційність.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</Typography>
    <Typography variant="body2">
      1.1. Дана Політика конфіденційності встановлює порядок отримання, зберігання, обробки,
      використання і розкриття персональних даних Користувача. Персональні дані Користувача ми
      отримуємо від Користувача при оформленні замовлення через корзину магазина, при оформленні
      замовлення за телефоном, вказаним на сайті, і при реєстрації самим Користувачем (створення
      облікового запису). Персональні дані Користувача заповнюються Користувачем (при оформленні
      замовлення через корзину магазина або при реєстрації на сайті). Обов&apos;язкова для надання
      послуги інформація позначена спеціальним чином. Інша інформація надається Користувачем на його
      розсуд.
    </Typography>
    <Typography variant="body2">
      1.2. Для оформлення замовлення через корзину інтернет-магазину Користувач повинен:
    </Typography>
    <Typography variant="body2">
      - уважно ознайомитися з цією політикою збереження конфіденційності;
    </Typography>
    <Typography variant="body2">
      - в формі оформлення замовлення Користувач сам заповнює необхідні персональні дані (ім&apos;я,
      електронну адресу, контактний номер телефону,
    </Typography>
    <Typography variant="body2">адреса доставки товару);</Typography>
    <Typography variant="body2">
      - висловити свою повну згоду з цією політикою Користувач може шляхом натискання кнопки
      «Замовлення підтверджую».
    </Typography>
    <Typography variant="body2">
      1.3 Щоб зареєструватися на сайті інтернет - магазина Користувач повинен уважно ознайомитися з
      Даною Політикою конфіденційності та висловити свою повну згоду з її умовами шляхом натискання
      кнопки «Зареєструватися».
    </Typography>
    <Typography variant="body2">
      1.4. Користувач не має права використовувати інтернет - магазин, а саме оформляти замовлення і
      реєструватися, якщо він не згоден з умовами даної Політики конфіденційності.
    </Typography>
    <Typography variant="body2">
      1.5 Оформлюючи замовлення товару або реєструючись в інтернет - магазині і вносячи персональні
      дані або надаючи ці дані по телефону, вказаному на сайті, Користувач дає свою однозначну згоду
      з умовами даної Політики конфіденційності. Користувач надає право адміністрації інтернет -
      магазина отримувати, зберігати, обробляти, використовувати і розкривати свої персональні дані
      на умовах даної Політики конфіденційності.
    </Typography>
    <Typography variant="body2">
      1.6 Користувач визнає, що в разі недбалого ставлення самим Користувачем до безпечного
      зберігання свого логіна і пароля, що дає доступ до його персональних даних, треті особи можуть
      отримати несанкціонований доступ до облікового запису і персональним та іншими даними
      користувача. Інтернет - магазин не несе відповідальність за збитки завдані таким доступом. Ми
      рекомендуємо Користувачам використовувати складні паролі, що складаються з цифр і букв.
      Змінити пароль для доступу до своїх особистих даних Ви можете скориставшись функцією «Вхід в
      магазин» і зайшовши в розділ «Мої дані».
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">2. ЯКІ ПЕРСОНАЛЬНІ ДАНІ МИ ЗБИРАЄМО</Typography>
    <Typography variant="body2">
      2.1 Ми збираємо персональні дані, а саме: ім&apos;я, контактний телефон, адрес електронної
      пошти, адресу доставки. Також ми можемо уточнити і отримати від Вас цю інформацію по телефону.
    </Typography>
    <Typography variant="body2">
      2.2 Інша інформація надається користувачем на його розсуд.
    </Typography>
    <Typography variant="body2">
      2.3 Інтернет – магазин To the nines не збирає IP - адреси користувачів.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">
      3. ЦІЛІ ЗБОРУ І ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧІВ
    </Typography>
    <Typography variant="body2">
      3.1 Інтернет – магазинTo the nines збирає і зберігає тільки ті персональні дані, які необхідні
      для надання послуг з продажу і доставки замовлених Вами товарів.
    </Typography>
    <Typography variant="body2">
      3.2 Інтернет – магазин To the nines збирає дані для зв&apos;язку з Користувачем, в тому числі
      подання відповідного повідомлення про надання послуги, інформування користувача про наявність
      товару, підтвердження замовлення, доставки замовлених Користувачем товарів, для зв&apos;язку
      кур&apos;єрської служби з користувачем, а також для обробки запитів і заявок від Користувача.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">4. УМОВИ НАДАННЯ ДОСТУПУ ДО БАЗИ ДАНИХ</Typography>
    <Typography variant="body2">
      4.1 Ми не передаємо персональні дані Користувача третім особам крім випадків визначених
      законодавством України.
    </Typography>
    <Typography variant="body2">
      4.2 Розкриття персональних даних Користувача проводиться при замовленні Користувачем товарів в
      інтернет - магазиніTo the nines тільки в тому обсязі, який необхідний для ідентифікації
      Користувача і його замовлення і тільки співробітниками магазину, мають право обробляти
      персональні дані користувачів.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">
      5. ЗМІНА КОРИСТУВАЧЕМ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ
    </Typography>
    <Typography variant="body2">
      5.1. Користувач може в будь-який момент змінити (оновити, доповнити, видалити) надану їм
      персональну інформацію або її частина, скориставшись функцією «Вхід в магазин» і зайшовши в
      розділ «Мої дані».
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">6. ЗАХИСТ ІНФОРМАЦІЇ, ЩО ЗБИРАЄТЬСЯ</Typography>
    <Typography variant="body2">
      6.1 Ми приймаємо всі необхідні і достатні організаційні та технічні заходи і заходи для
      захисту конфіденційності даних користувачів. Тільки ті співробітники інтернет – магазину To
      the nines, яким необхідно виконувати робочі функції, для яких потрібен доступ до персональних
      даних користувачів, мають доступ до інформації про користувачів. Всі співробітники, що мають
      доступ до інформації про користувачів, підписали угоду про нерозголошення інформації про
      користувачів третім особам.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">7. ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</Typography>
    <Typography variant="body2">
      7.1 Ми маємо право змінити умови Політики конфіденційності. В такому випадку ми замінимо
      версію документа на сторінці «Політика конфіденційності». Будь ласка, періодично
      передивляйтесь ці умови, щоб бути поінформованими про те, як інтернет – магазин To the nines
      захищає персональні дані своїх користувачів.
    </Typography>
  </Stack>
);
