/* eslint-disable */
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { useMediaQuery, useTheme } from '@mui/material';
import * as PropTypes from 'prop-types';

export const Slider = ({
  slidesPerView = 3,
  slidesPerViewMobile = 1,
  children,
  navigation,
  pagination,
  breakpoints,
  ...props
}) => {
  const swiperRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const slidesPerViewProps = breakpoints
    ? undefined
    : isDesktop
    ? slidesPerView
    : slidesPerViewMobile;

  return (
    <div>
      <Swiper
        {...props}
        ref={swiperRef}
        breakpoints={breakpoints}
        slidesPerView={slidesPerViewProps}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={isDesktop ? 40 : 20}
        onSlideChange={() => {}}
        onSwiper={(swiper) => console.log(swiper)}
        navigation={Boolean(navigation && isDesktop)}
        pagination={Boolean(pagination && !isDesktop)}
      >
        {children.map((item) => (
          <SwiperSlide
            onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
            onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  slidesPerView: PropTypes.number,
  slidesPerViewMobile: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node),
  navigation: PropTypes.bool,
  pagination: PropTypes.bool,
  autoplay: PropTypes.object,
};
