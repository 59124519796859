import React from 'react';
import { Box, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeliveryIcon } from '../../icons/delivery.icon';
import { ArrowClockIcon } from '../../icons/arrow-clock.icon';

export function DeliveryReturnBlocksComponent() {
  const { t } = useTranslation('translation');

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 5 }}>
      <Box sx={{ display: 'flex', flex: '1 0' }}>
        <DeliveryIcon />
        <Link
          className="underline lato"
          sx={{ ml: 1, lineHeight: '24px', fontSize: '14px', maxWidth: '150px' }}
          href="/information/delivery-payment"
        >
          {t('deliveryWithinUkraine')}
        </Link>
      </Box>
      <Box sx={{ display: 'flex', flex: '1 0' }}>
        <ArrowClockIcon />
        <Link
          className="underline lato"
          sx={{ ml: 1, lineHeight: '24px', fontSize: '14px', maxWidth: '150px' }}
          href="/information/return-and-swap"
        >
          {t('returnsGoods')}
        </Link>
      </Box>
    </Stack>
  );
}
