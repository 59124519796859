export const deliveryMethods = {
  courier: 'courier',
  post: 'post',
  self: 'self',
};

export const paymentMethod = {
  self: 'self',
  card: 'card',
};
