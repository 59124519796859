import React from 'react';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import videoBackground from '../../../../assets/images/video-backgroud.png';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';

export const Video = () => {
  const { data: videoBlock } = useGetBlockWithBanners(blockTypes.video_block_banner);
  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 7, xs: 0 },
        pb: { md: 0, xs: 5 },
        mt: 7.25,
        mb: 5,
        background: `url(${videoBackground})`,
        backgroundSize: { md: '100% 423px', xs: 'cover' },
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container spacing={{ md: 9, xs: 0 }} rowSpacing={3} alignItems="center">
        <Grid item md={8} xs={12}>
          {videoBlock.block.link_video && (
            <video
              className="video"
              height="560"
              preload="auto"
              // eslint-disable-next-line
              autoPlay={true}
              loop
              playsInline
              muted
            >
              <source src={videoBlock.block.link_video} />
            </video>
          )}
        </Grid>
        <Grid item md={4} xs={12} sx={{ px: { md: 0, xs: 3 } }}>
          <Stack direction="column" sx={{ alignItems: 'flex-start' }}>
            <Typography variant="h4" component="h2">
              {videoBlock.block.title}
            </Typography>
            <Typography variant="subtitle1" component="p" sx={{ mt: 2 }}>
              {videoBlock.block.description}
            </Typography>
            <Button
              href={videoBlock.block.link_button}
              color="secondary"
              variant="contained"
              sx={{ mt: 5 }}
            >
              {videoBlock.block.link_button_text}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
