import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import * as PropTypes from 'prop-types';
import { selectMenuProps } from '../../../utils/dropdownUtils';

export function FilterItem({ item, columns, value, onChange }) {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);

  const renderItem = (option) => (
    <MenuItem key={option.Name} value={option.Name}>
      <Checkbox checked={value && !!value.find(({ Name }) => option.Name === Name)} />
      <ListItemText primary={option.Name} />
    </MenuItem>
  );

  useLayoutEffect(() => {
    setWidth(containerRef?.current.offsetWidth);
  }, []);

  return (
    <Grid item xs={12 / columns}>
      <FormControl fullWidth ref={containerRef}>
        <Select
          id={item.label}
          disabled={item.disabled}
          multiple={item.multiple}
          value={value.map(({ Name }) => Name) || []}
          renderValue={(items) => items.join(', ')}
          onChange={(event) => {
            onChange(item, event.target.value);
          }}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment disableTypography position="start">
                  {item.label}
                </InputAdornment>
              }
            />
          }
          MenuProps={selectMenuProps(width)}
        >
          {item.options.map(renderItem)}
        </Select>
      </FormControl>
    </Grid>
  );
}

FilterItem.propTypes = {
  item: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.object,
  onChange: PropTypes.func,
};
