import React from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { theme } from './theme';
import Routes from './routes';
import ScrollToTop from './HOC/ScrollToTop';
import { CartContextProvider } from './utils/cart.context';
import { WishlistContextProvider } from './utils/wishlist.context';
import { ErrorBoundary } from './components/error-boundary/error-boundary';
import { NotificationProvider } from './utils/notification.context';
import { Notifications } from './components/notifications/notifications';
import { UserContextProvider } from './utils/user.context';

const App = () => {
  const { t } = useTranslation('translation');

  return (
    <ThemeProvider theme={theme({})}>
      <NotificationProvider>
        <UserContextProvider>
          <CartContextProvider>
            <WishlistContextProvider>
              <Router>
                <ScrollToTop>
                  <ErrorBoundary t={t}>
                    <Helmet>
                      <title>To The Nines</title>
                    </Helmet>
                    <Routes />
                    <Notifications />
                  </ErrorBoundary>
                </ScrollToTop>
              </Router>
            </WishlistContextProvider>
          </CartContextProvider>
        </UserContextProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
