import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';
import { useBannerList } from './banners.service';

export function useGetBlock() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.post(`adminpanel/get/`, { token_user: '', ...params }),
  });
  return { mutate, isSuccess, data: data || [] };
}

export function useGetBlockWithBanners(type, isArray) {
  const {
    data: block,
    mutate: getBlock,
    isInitialLoading: isBlockLoading,
    isSuccess: isBlockSuccess,
  } = useGetBlock();
  const {
    data: banners,
    mutate: getBanners,
    isInitialLoading: isBannersLoading,
    isSuccess: isBannersSuccess,
  } = useBannerList();

  useEffect(() => {
    getBanners({ type, language: 'UA' });
    getBlock({ type, language: i18n.language });
  }, [i18n.language]);

  return {
    data: {
      banners:
        banners && Array.isArray(banners)
          ? banners.filter((item) => item.language === i18n.language)
          : [],
      block: isArray ? block : block[0] || {},
    },
    isInitialLoading: isBlockLoading || isBannersLoading,
    isSuccess: isBlockSuccess && isBannersSuccess,
  };
}
