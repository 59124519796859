import { useQuery } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useFilterList() {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: 'useFilterList',
    queryFn: () => apiService.post(`filterNomenclature`, { language: i18n.language }),
    refetchOnMount: false,
  });
  return { data, isLoading, isSuccess };
}
