/* eslint-disable */
import { Box, Button, IconButton, Link, Stack, Modal, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { InputField } from '../input/input-field';
import { useNotification } from '../../utils/notification.context';
import { useGetOtp, useLogin } from '../../services/profile.service';

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
});

const PinSchema = Yup.object().shape({
  pin: Yup.string().required(),
});

export function PinModal({ phone, open, handleClose, onSubmit, submitLabel }) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { mutate, isSuccess, data, isError } = useGetOtp();
  const { addNotification } = useNotification();

  const formik = useFormik({
    initialValues: {
      pin: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: PinSchema,
    onSubmit: (values) => {
      onSubmit(values.pin);
    },
  });

  const sentOtp = () => {
    mutate({ NumberPhone: phone });
  };

  useEffect(() => {
    open && sentOtp();
  }, [open]);

  useEffect(() => {
    if (data) {
      if (isError) {
        addNotification(JSON.stringify(data), 'error');
      }
    }
  }, [isSuccess, data, isError]);

  return (
    <StyledModal open={open} onClose={handleClose} BackdropComponent={Backdrop}>
      <Box sx={style('440px')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="headingSmall" component="p">
            {t('logIn')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack mt={3}>
            <Typography variant="p" sx={{ mb: 3, fontSize: '15px' }}>
              {t('pinInfo')} {phone}
            </Typography>
            <InputField
              placeholder={t('pinPlaceholder')}
              id="pin"
              name="pin"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pin}
              error={formik.touched.pin && formik.errors.pin}
            />
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              {t('pinNotReceived')}
              <Typography
                variant="subtitle2"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={(event) => {
                  event.preventDefault();
                  sentOtp();
                }}
              >
                {t('pinSendNow')}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 3, width: '100%' }}
              onClick={() => formik.submitForm()}
            >
              {submitLabel}
            </Button>
          </Stack>
        </form>
      </Box>
    </StyledModal>
  );
}
