import { useMutation } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useCollectionService() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: () => apiService.post(`collection`, { language: i18n.language }),
  });
  return { mutate, isSuccess, data };
}
