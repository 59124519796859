import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HalfColorBlock } from '../../../../components/half-color-block/half-color-block.component';
import { CategoryFiltersContext } from '../../../../utils/categoryFiltersContex';
import { ProductListContainer } from '../../../../components/product-list/product-list.container';

const SaleWrapper = ({ children, visible }) => {
  const { t } = useTranslation('translation');
  return visible ? (
    <HalfColorBlock color="beige">
      <Typography variant="subtitle3" component="h2" align="center" sx={{ mb: 4 }}>
        {t('saleProducts')}
      </Typography>
      {children}
    </HalfColorBlock>
  ) : (
    <div />
  );
};

SaleWrapper.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

export function Sale() {
  const { filterValues } = useContext(CategoryFiltersContext);

  return filterValues.brand ? (
    <ProductListContainer
      fromKey="SaleProducts"
      filter={{
        brand: filterValues.brand,
      }}
      priceOutlet
      columns={4}
      maxCount={4}
      wrapper={SaleWrapper}
    />
  ) : (
    <div />
  );
}
