import React, { useContext, useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';

const staticCrumbs = { '/search-brand': 'Бренди', '/collection': 'Колекції' };

export const PageBreadcrumbs = ({ productName }) => {
  const { t } = useTranslation('translation');
  const { genderCategory, category, subcategory, filterValues } =
    useContext(CategoryFiltersContext);
  const location = useLocation();

  const generatedBreadcrumbs = useMemo(() => {
    if (staticCrumbs[location.pathname]) {
      return [{ name: staticCrumbs[location.pathname] }];
    }
    if (productName) {
      return [{ name: productName }];
    }
    if (productName) {
      return [{ name: productName }];
    }
    const breadCrumbs = [];
    if (genderCategory && !filterValues.brand) {
      breadCrumbs.push({ name: genderCategory.Name });
    }
    if (filterValues.brand) {
      breadCrumbs.push({ name: filterValues.brand[0].Name });
    }
    if (category) {
      breadCrumbs.push({ name: category.Name });
    }
    if (subcategory) {
      breadCrumbs.push({ name: subcategory.Name });
    }
    if (filterValues.collection) {
      breadCrumbs.push({ name: filterValues.collection[0].Name });
    }

    return breadCrumbs;
  }, [genderCategory, category, subcategory, location.pathname, productName, filterValues]);

  const breadcrumbs = [
    <Link variant="textLink" underline="hover" key="1" href="/">
      {t('breadcrumbsMain')}
    </Link>,
    ...generatedBreadcrumbs.map((item) => (
      <Link
        variant="textLink"
        underline="hover"
        key="2"
        href="/"
        style={{ textTransform: 'capitalize' }}
      >
        {item.name}
      </Link>
    )),
  ];

  return (
    <Stack spacing={2}>
      <Helmet>
        <title>TTN • {generatedBreadcrumbs.map(({ name }) => name).join(' • ')}</title>
      </Helmet>
      <Breadcrumbs
        separator={
          <svg
            width="3"
            height="4"
            viewBox="0 0 3 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1.5" cy="2" r="1.5" fill="#4F4F4F" />
          </svg>
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

PageBreadcrumbs.propTypes = {
  productName: PropTypes.string,
};
