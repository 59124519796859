import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmptyCart } from '../../components/empty-cart/empty-cart.component';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { CartContext } from '../../utils/cart.context';
import { GTMAddPurchase } from '../../services/analytic-events';

export function ThankYou() {
  const { clearCart, cart, totalPrice, totalCount } = useContext(CartContext);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const navigate = useNavigate();

  const toMain = () => {
    navigate('/');
  };

  useEffect(() => {
    if (cart && cart.length > 0) GTMAddPurchase(orderId, totalPrice, cart, totalCount);
    clearCart(true);
  }, []);

  return (
    <PageWrapper main>
      <EmptyCart title={`Дякуємо за покупку! ID замовлення: ${orderId}`} onClose={toMain} />
    </PageWrapper>
  );
}
