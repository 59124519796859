/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, Modal, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { InputField } from '../input/input-field';
import { useNotification } from '../../utils/notification.context';
import { useLogin } from '../../services/profile.service';
import { PinModal } from './pin-modal';
import { useUser } from '../../utils/user.context';

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
});

const LoginSchema = Yup.object().shape({
  phone: Yup.string().required(),
});

export function LoginModal({ open, handleClose, openRegistration }) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { mutate, isSuccess, data, isError } = useLogin();
  const [pinModal, setPinModal] = useState();
  const { setToken } = useUser();
  const { addNotification } = useNotification();

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setPinModal(true);
    },
  });

  const onSubmit = (pin) => {
    mutate({
      phone: formik.values.phone,
      pin,
    });
  };

  useEffect(() => {
    if (data) {
      if (isError || !data[0]?.token_user) {
        addNotification(JSON.stringify(data), 'error');
      } else {
        setPinModal(false);
        handleClose();
      }
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (data && isSuccess && !isError && data[0].token_user) {
      setToken(data[0].token_user);
    }
  }, [isSuccess, data, isError]);

  return (
    <div>
      <StyledModal open={open && !pinModal} onClose={handleClose} BackdropComponent={Backdrop}>
        <Box sx={style('440px')}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography variant="title1" component="p" className="bold">
                {t('logIn')}
              </Typography>
              <Typography variant="headingDesktopSmall">або </Typography>
              <Typography
                variant="headingSmall"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={openRegistration}
              >
                Зареєструватися
              </Typography>
            </Stack>
            <IconButton onClick={handleClose} sx={{ marginLeft: 'auto' }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <form>
            <Stack spacing={3} mt={3}>
              <InputField
                label={t('phone')}
                placeholder={t('phonePlaceholder')}
                id="phone"
                name="phone"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.touched.phone && formik.errors.phone}
              />
              <Button
                variant="contained"
                sx={{ mt: 2, width: '100%' }}
                onClick={() => setPinModal(true)}
              >
                {t('logIn')}
              </Button>
            </Stack>
          </form>
        </Box>
      </StyledModal>
      <PinModal
        phone={formik.values.phone}
        open={pinModal}
        onSubmit={(pin) => onSubmit(pin)}
        handleClose={() => setPinModal(false)}
        submitLabel={t('logIn')}
      />
    </div>
  );
}
