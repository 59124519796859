import { Chip, styled } from '@mui/material';

export const FilterChip = styled(Chip)(() => ({
  fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
  backgroundColor: '#E9EBFC',
  color: '#121212',
  fontSize: '13px',
  lineHeight: '24px',
  borderRadius: '1px',
  padding: 0,
  '& .MuiChip-label': {
    padding: 0,
    margin: '4px 8px',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
  },
}));
