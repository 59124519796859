/* eslint-disable */
import React from 'react';
import { Container } from '@mui/material';
import { Banner } from '../../components/banner/banner.component';
import { Filter } from '../../components/filter/filter.container';
import { NewGoods } from './components/new-goods/new-goods.component';
import { Sale } from './components/sale/sale.component';
import { NewCollections } from './components/new-collections/new-collections.component';

export function BrandComponent({ brand, toCatalog }) {
  return (
    <>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 3 } }}>
        <Banner image={brand?.link} description={brand?.description} toAll={toCatalog} />
        {brand && <Filter preview maxCount={4} toAll={toCatalog} fromKey="BrandCatalog" />}
      </Container>
      <NewGoods />
      <Sale />
      <NewCollections />
    </>
  );
}
