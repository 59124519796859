import { useQuery } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useGetBrand() {
  const { isSuccess, data } = useQuery({
    queryKey: 'useGetBrand',
    queryFn: () => apiService.post(`Brand/`, { language: i18n.language }),
    refetchInterval: 0,
    refetchOnMount: false,
  });
  return { isSuccess, data };
}
