export const normalizeNomenclature = (item) => {
  const result = {};
  result.UID = item.UID;
  result.fullname = item.fullname || item.fullname1 || '';
  result.additionalinfo =
    item.additionalinfo && item.additionalinfo.length > 0 ? item.additionalinfo[0] : undefined;
  result.category = item.categories[0] ? item.categories[0].Kids[0].Name : undefined;
  result.mainPhoto =
    (item.photo && item.photo.find((str) => str.url.endsWith('10.jpg'))) ||
    (item.photo && item.photo[0]) ||
    '';
  result.photos = item.photo || [];
  result.price = item.price || '';
  result.vendorCode = item.vendorCode || '';
  result.firstPrice =
    item.FirstPrice && item.FirstPrice !== item.price ? item.FirstPrice : undefined;
  result.discount =
    item.PercentDiscounts === '0' || !item.PercentDiscounts
      ? ''
      : parseInt(item?.PercentDiscounts, 10);
  result.newproduct = item.new === 'true';

  return result;
};

export const normalizeOrders = (list) =>
  list.map((item) => ({
    orderDate: item['ДатаЗаказа'],
    loadDate: item['ДатаОтгрузки'],
    address: item['АдресДоставки'],
    paymentType: item['ФормаОплаты'],
    status: item['Статус'],
    products: item['Товары']
      ? item['Товары'].map((product) => ({
          name: product['Наименование'],
          productId: product.GUID_Nomenclature,
          sizeId: product.GUID_Size,
          price: product['Цена'],
          count: product['Количество'],
        }))
      : [],
  }));
