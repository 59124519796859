import React from 'react';
import { Container, Grid, styled, Typography } from '@mui/material';
import { Slider } from '../../../../components/slider/slider.component';
import { SmallBanner } from '../../../../components/small-banner/small-banner.component';
import { ArrowIcon } from '../../../../icons/arrow.icon';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';
import { ArrowButton as ArrowButtonComponent } from '../../../../components/arrow-button/arrow-button.component';

const ArrowButton = styled(ArrowButtonComponent)(() => ({
  paddingLeft: '0',
  marginTop: '32px',
}));

export const Collections = () => {
  const { data } = useGetBlockWithBanners(blockTypes.collection_banner, true);

  return (
    <>
      {data.block[0] ? (
        <Container maxWidth="1440" disableGutters sx={{ px: { md: 7, xs: 3 }, pt: 7.25, mb: 4 }}>
          <Grid container spacing={5} rowSpacing={2} alignItems="center" wrap>
            <Grid item xs={12} md={8}>
              <Slider slidesPerView={2} pagination>
                <SmallBanner banner={data.banners[0]} clickable />
                <SmallBanner banner={data.banners[1]} clickable />
              </Slider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" component="p" sx={{ mt: { xs: 2, md: -9.75 } }}>
                {data.block[0].description}
              </Typography>
              <ArrowButton
                href={data.block[1].link_button}
                variant="text"
                size="big"
                startIcon={<ArrowIcon />}
              >
                {data.block[0].link_button_text}
              </ArrowButton>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div />
      )}
      {data.block[1] ? (
        <Container maxWidth="1440" disableGutters sx={{ px: { md: 7, xs: 3 }, mb: 5 }}>
          <Grid
            container
            spacing={5}
            rowSpacing={2}
            alignItems="center"
            wrap
            sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" component="p" sx={{ mt: { xs: 2, md: -9.75 } }}>
                {data.block[1].description}
              </Typography>
              <ArrowButton
                href={data.block[1].link_button}
                variant="text"
                size="big"
                startIcon={<ArrowIcon />}
              >
                {data.block[1].link_button_text}
              </ArrowButton>
            </Grid>
            <Grid item md={8} xs={12}>
              <Slider slidesPerView={2} pagination>
                <SmallBanner banner={data.banners[2]} clickable />
                <SmallBanner banner={data.banners[3]} clickable />
              </Slider>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div />
      )}
    </>
  );
};
