import React, { useMemo, useState } from 'react';
import { Backdrop, Box, Drawer, styled, useMediaQuery, useTheme } from '@mui/material';
import * as PropTypes from 'prop-types';

const HEADER_HEIGHT_DESKTOP = 134;
const HEADER_HEIGHT_MOBILE = 115;

const BackdropStyled = styled(Backdrop)(() => ({
  height: '100vh',
}));

export const TopDrawer = ({ setShow, show, children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const headerHeight = useMemo(
    () => (isDesktop ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE),
    [isDesktop]
  );
  const [topGutter, setTopGutter] = useState(headerHeight);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (setTopGutter) {
      setTopGutter(Math.max(67, headerHeight - scrolled));
    }
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Drawer
      anchor="top"
      open={show}
      disablePortal
      ModalProps={{ BackdropComponent: BackdropStyled }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      style={{ position: 'fixed', top: topGutter }}
      PaperProps={{ style: { position: 'absolute' } }}
    >
      <Box sx={{ overflow: 'auto' }}>{children}</Box>
    </Drawer>
  );
};

TopDrawer.propTypes = {
  children: PropTypes.node,
  setShow: PropTypes.func,
  show: PropTypes.bool,
};
