import React from 'react';
import { Container, Grid } from '@mui/material';
import * as PropTypes from 'prop-types';
import { Header } from '../header/header.component';
import { MainMenu } from '../main-menu/main-menu.container';
import { Footer } from '../footer/footer.component';
import { PageBreadcrumbs } from '../breadcrumbs/breadcrumbs.component';
import { ScrollButton } from '../scroll-to-top-button/scroll-to-top-button';
import { CategoryFiltersContextProvider } from '../../utils/categoryFiltersContex';

export function PageWrapper({ children, main, productName }) {
  return (
    <CategoryFiltersContextProvider>
      <Grid className="App">
        <ScrollButton />
        <Header />
        <MainMenu />
        {!main && (
          <Container
            maxWidth="1440"
            disableGutters
            sx={{ display: { xs: 'none', md: 'block' }, mt: 5.5 }}
          >
            <PageBreadcrumbs productName={productName} />
          </Container>
        )}
        {children}
        <Footer />
      </Grid>
    </CategoryFiltersContextProvider>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node,
  main: PropTypes.bool,
  productName: PropTypes.string,
};
