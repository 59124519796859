/* eslint-disable */
import React from 'react';
import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { matchRoutes, Outlet, useLocation, Link as RouterLink } from 'react-router-dom';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { mainRoutes, routes } from '../../constants/routes';

export const Information = () => {
  const location = useLocation();
  const [{ route: allRoutes }, { route: currentRoute }] = matchRoutes(mainRoutes, location);

  return (
    <PageWrapper>
      <Container maxWidth="1440" disableGutters sx={{ mt: 3.25, mb: 8 }}>
        <Grid container spacing={5}>
          <Grid item md={3} xs={12}>
            <Stack sx={{ background: '#F6F6F6', paddingTop: 3, paddingBottom: 4.75 }}>
              <nav aria-label="secondary menu">
                <List className="side-menu">
                  {allRoutes &&
                    allRoutes.children.map(({ path, title }) => (
                      <ListItem disablePadding>
                        <ListItemButton
                          className={path === currentRoute.path && 'active'}
                          component={RouterLink}
                          to={path}
                        >
                          <ListItemText primary={title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </nav>
              <Divider sx={{ marginTop: 3.5, marginX: 3 }} />
              <Stack sx={{ marginTop: 7, marginX: 3 }}>
                <Typography variant="body2">Не знайшли відповідь?</Typography>
                <Typography variant="body2">Звʼяжіться з нами:</Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  +38 067 491 83 81
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Пн-Пт 9:00 – 21:00
                </Typography>
                <Typography variant="body2">Сб-Вс 9:00 – 19:00</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={9} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
};
