/* eslint-disable */
import axios from 'axios';

export const API_PREFIX = 'https://api.novaposhta.ua/v2.0/json/';
export const API_KEY_NOVAPOSHTA = process.env.REACT_APP_API_KEY_NOVAPOSHTA;

export class ApiNovaposhtaService {
  async baseRequest(method, body) {
    try {
      const response = await axios(`${API_PREFIX}`, {
        method,
        data: {
          apiKey: API_KEY_NOVAPOSHTA,
          ...body,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return {};
  }

  post(body) {
    return this.baseRequest('POST', body);
  }
}

export const apiNovaposhtaService = new ApiNovaposhtaService();
