export const compare = (a, b) => {
  // check for wordhood
  const wordA = /^[a-zA-Z]+$/.test(a.name);
  const wordB = /^[a-zA-Z]+$/.test(b.name);
  if (wordA && wordB) {
    return a.name.localeCompare(b.name);
  }
  if (wordA || wordB) return (wordA && -1) || 1;

  // check for numberhood
  const numA = !Number.isNaN(a.name);
  const numB = !Number.isNaN(b.name);
  if (numA && numB) {
    return Number(a.name) - Number(b.name);
  }
  if (numA) return -1;
  if (numB) return 1;

  return 1;
};
