import React from 'react';
import { SvgIcon } from '@mui/material';

export const CloseIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m16 8-8 8m8 0L8 8"
    />
  </SvgIcon>
);
