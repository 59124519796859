import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const DeliveryComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Доставка</Typography>
    <Divider />
    <Typography variant="subheadingMedium">Кур&apos;єрська доставка</Typography>
    <Typography variant="body2">
      Щоб вибрати кур&apos;єрську доставку «Нова пошта», вкажіть при оформленні замовлення адресу
      доставки. Доставка здійснюється компанією «Нова пошта» безкоштовно при замовленні на суму
      понад 3500 грн. В інших випадках її вартість залежить від міста доставки і обсягу замовлення
      згідно з тарифами компанії «Нова пошта». Доставка здійснюється протягом 5 робочих днів після
      підтвердження наявності товару.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Доставка на відділення Нова пошта</Typography>
    <Typography variant="body2">
      Для отримання замовлення в відділенні компанії «Нова пошта», вкажіть в анкеті номер / адресу
      зручного відділення, а при отриманні пред&apos;явіть паспорт і номер накладної. Він прийде в
      повідомленні на ваш мобільний телефон відразу після відправки замовлення. Доставка
      здійснюється компанією «Нова пошта» безкоштовно при замовленні на суму понад 3500 грн. В інших
      випадках її вартість залежить від міста доставки і обсягу замовлення згідно з тарифами
      компанії «Нова пошта». Доставка здійснюється протягом 5 робочих днів після підтвердження
      наявності товару.
    </Typography>
    <Typography variant="body2">
      Важливо! Якщо сума замовлень перевищує 5000 грн в день, для отримання замовлення необхідно
      пред&apos;явити паспорт для верифікації та ідентифікації. Це стосується операцій, як в
      готівковій формі, так і з використанням банківської платіжної картки.
    </Typography>
    <Typography variant="body2">
      Зверніть увагу, в періоди розпродажів доставка може зайняти більше заявленого терміну.
    </Typography>
  </Stack>
);
