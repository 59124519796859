import { useQuery } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useMainCategoriesService() {
  const { isSuccess, data, isInitialLoading } = useQuery({
    queryKey: ['mainCategories'],
    queryFn: () => apiService.post(`menu`, { language: i18n.language }),
  });
  return { isSuccess, data, isInitialLoading };
}
