import React from 'react';
import { SvgIcon } from '@mui/material';

export const ArrowIcon = () => (
  <SvgIcon viewBox="0 0 40 40">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      d="M27 20H13M27 20L22 25M27 20L22 15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
