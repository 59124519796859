import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { theme } from '../../../../theme';
import { useGetCity } from '../../../../services/city.service';
import { useGetRemnants } from '../../../../services/remnants.service';

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: 999999;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: `transparent`,
  padding: 0,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon />} {...props} />
))(() => ({
  padding: 0,
  minHeight: 'unset',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

const SmallDivider = styled(Divider)(() => ({
  margin: '12px 0',
}));

export function AvailabilityModal({ show, handleClose }) {
  const { t } = useTranslation('translation');
  const { data } = useGetCity();
  const { id } = useParams();
  const { mutate, data: available } = useGetRemnants();
  const [city, setCity] = useState('Киев');
  const storageList = useMemo(
    () => (available ? available.filter(({ storage }) => storage !== '') : []),
    [available]
  );
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  useEffect(() => {
    mutate({
      city,
      ID_nomenclature: id,
    });
  }, [city]);

  return (
    <StyledModal open={show} onClose={handleClose} BackdropComponent={Backdrop}>
      <Box sx={style('496px')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="headingSmall" component="p">
            {t('remnantsInShop')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" sx={{ mt: 3 }}>
          <Box sx={{ minWidth: 173, flex: '1 0' }}>
            <FormControl fullWidth>
              <InputLabel id="city-label" shrink={false}>
                {t('city')}
              </InputLabel>
              <Select
                labelId="city-label"
                value={city}
                placeholder={t('chooseCity')}
                onChange={handleCityChange}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ sx: { zIndex: 999999 } }}
              >
                {data && data.map((item) => <MenuItem value={item.city}>{item.city}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Stack direction="column" sx={{ mt: 3 }}>
          <Typography variant="label12Medium" component="p">
            Знайдено {storageList.length} магазинів
          </Typography>
          <SmallDivider />
          <Stack spacing={3}>
            {storageList.map(({ storage, WorkingHours }) => (
              <Accordion>
                <AccordionSummary>
                  <Typography variant="subtitle1">{storage}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">+38 (044) 123 45 67</Typography>
                  <Stack direction="row" alignItems="center" sx={{ mt: 0.5 }}>
                    <AccessTimeIcon fontSize="2" style={{ color: theme.color?.primary200 }} />
                    <Typography variant="subtitle1" sx={{ ml: 0.5 }}>
                      {WorkingHours}
                    </Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      </Box>
    </StyledModal>
  );
}

AvailabilityModal.propTypes = {
  show: PropTypes.func,
  handleClose: PropTypes.func,
};
