import { useMutation } from '@tanstack/react-query';
import { apiNovaposhtaService } from './api-novaposhta.service';

export function warehouseService() {
  const { mutate, isSuccess, data, isInitialLoading, isLoading } = useMutation({
    mutationFn: (cityName) =>
      apiNovaposhtaService.post({
        modelName: 'Address',
        calledMethod: 'getWarehouses',
        methodProperties: {
          CityName: cityName,
          Limit: '50',
          Page: '1',
        },
      }),
  });
  return { mutate, isSuccess, data, isInitialLoading, isLoading };
}
