import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const TermsOfUseComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Умови використання</Typography>
    <Divider />
    <Typography variant="body2">
      Увага! Перед переглядом цього сайту уважно прочитайте ці умови. Якщо ви не згодні з цими
      умовами, не використовуйте цей сайт.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Використання сайту</Typography>
    <Typography variant="body2">
      Сайт shop.lerros.net.ua дозволяє вам переглядати і завантажувати матеріали цього сайту
      (shop.lerros.net.ua) тільки для особистого некомерційного використання, за умови збереження
      вами всієї інформації про авторське право та інших відомостей про право власності, що
      містяться у вихідних матеріалах і будь-яких їх копіях. Забороняється змінювати матеріали цього
      Сайту, а також поширювати або демонструвати їх в будь-якому вигляді або використовувати їх
      будь-яким іншим чином для суспільних або комерційних цілей. Будь-яке використання цих
      матеріалів на інших сайтах або в комп&apos;ютерних мережах забороняється.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Вiдмова вiд вiдповiдальностi</Typography>
    <Typography variant="body2">
      Матеріали і послуги цього сайту надаються «як є» без будь-яких гарантій. Сайт не гарантує
      точності і повноти матеріалів, програм і послуг, що надаються на цьому Сайті. Сайт в будь-який
      час без повідомлення може вносити зміни в матеріали і послуги, що надаються на цьому Сайті, а
      також у згадані в них продукти і ціни. У разі неактуальності матеріалів і послуг на цьому
      Сайті To the nines не зобов&apos;язаний оновлювати їх. Сайт ні за яких обставин не несе
      відповідальності за будь-які збитки (включаючи, але не обмежуючись збитком від втрати
      прибутку, даних або від переривання ділової активності), що виник внаслідок використання,
      неможливості використання або результатів використання цього сайту.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">РЕЄСТРАЦІЯ НА САЙТІ</Typography>
    <Typography variant="body2">
      Реєструючись на Сайті, ви погоджуєтеся надати достовірну і точну інформацію про себе і свої
      контактні данні
    </Typography>
    <Typography variant="body2">
      В результаті реєстрації ви отримуєте логін і пароль, за безпеку яких ви несете
      відповідальність. Ви також несете відповідальність за всі дії під вашим логіном і паролем на
      Сайті. У разі втрати реєстраційних даних ви зобов&apos;язуєтеся повідомити нам про це.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">ЗВОРОТНІЙ ЗВ&apos;ЯЗОК І КОМЕНТАРІ</Typography>
    <Typography variant="body2">
      Звертаючись до нас або залишаючи коментарі на сайті, ви несете відповідальність, що дане
      повідомлення не є незаконним, шкідливим, загрозливим, наклепницьким, ображає моральність,
      порушує авторські права, пропагує ненависть і / або дискримінацію людей за расовою, етнічною,
      статевою, релігійною, соціальною ознаками, містить образи на адресу конкретних осіб або
      організацій, а також будь-яким іншим чином порушує чинне законодавство України. Ви
      погоджуєтеся, що будь-яке ваше повідомлення Сайт може видаляти без вашої на те згоди, а також
      безоплатно використовувати на свій розсуд. Сайт не несе відповідальності за будь-яку
      інформацію розміщену користувачами Сайту.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Використання персональних даних</Typography>
    <Typography variant="body2">
      Ми використовуємо різні технології для збору і зберігання інформації, коли ви відвідуєте сайт.
    </Typography>
  </Stack>
);
