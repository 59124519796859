import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../utils/cart.context';
import { PageWrapper } from '../page-wrapper/page-wrapper.component';
import { EmptyCart } from '../empty-cart/empty-cart.component';
import { CartItem } from './cart-item/cart-item.component';
import { PreviewCartItem } from '../preview-cart-item/preview-cart-item.component';
import { DeliveryReturnBlocksComponent } from '../delivery-return-blocks/delivery-return-blocks.component';
import { GtagAddToCart } from '../../services/analytic-events';
import { ORDER } from '../../constants/routes';

export function Cart() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { cart, totalPrice } = useContext(CartContext);
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const toOrder = () => {
    navigate(ORDER.path);
  };

  const toMain = () => {
    navigate('/');
  };

  useEffect(() => {
    GtagAddToCart(totalPrice, cart);
  }, []);

  return (
    <PageWrapper main>
      {cart.length > 0 ? (
        <Container maxWidth="1440" disableGutters sx={{ mt: 7, mb: 11 }}>
          <Typography variant="subtitle3" component="h2">
            {t('products')}
          </Typography>
          <Grid container wrap={isDesktop && 'nowrap'}>
            <Grid item md={8} xs={12} sx={{ paddingRight: { md: 5, xs: 0 } }}>
              <Stack
                sx={{ marginTop: 5 }}
                divider={<Divider orientation="horizontal" flexItem />}
                spacing={4}
              >
                {cart.map((props) =>
                  isDesktop ? <CartItem {...props} /> : <PreviewCartItem {...props} actionButton />
                )}
              </Stack>
              <Divider sx={{ marginTop: 4 }} orientation="horizontal" flexItem />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={4} xs={12}>
              <Stack sx={{ paddingLeft: { md: 5, xs: 0 } }}>
                {isDesktop && (
                  <Typography variant="subtitle3" component="h2">
                    {t('sum')}
                  </Typography>
                )}
                <Stack sx={{ marginTop: 4.75 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="p" component="p" className="montserrat dark">
                      {t('orderPrice')}
                    </Typography>
                    <Typography variant="p" component="p" className="montserrat dark">
                      {totalPrice} грн
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '26px' }}>
                    <Typography variant="p" component="p" className="montserrat dark">
                      {t('delivery')}
                    </Typography>
                    <Typography variant="p" component="p" className="montserrat dark">
                      0 грн
                    </Typography>
                  </Stack>
                  <Divider orientation="horizontal" sx={{ marginTop: '26px' }} flexItem />
                  <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 3 }}>
                    <Typography variant="headingSmall" component="p" className="semi-bold dark">
                      {t('total')}
                    </Typography>
                    <Typography variant="headingSmall" component="p" className="semi-bold dark">
                      {totalPrice} грн
                    </Typography>
                  </Stack>
                </Stack>
                <Button variant="contained" sx={{ mt: 4, width: '100%' }} onClick={toOrder}>
                  {t('checkoutOrder')}
                </Button>
                {isDesktop && <DeliveryReturnBlocksComponent />}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <EmptyCart onClose={toMain} />
      )}
    </PageWrapper>
  );
}
