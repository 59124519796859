/* eslint-disable */
import React, { useContext } from 'react';
import { Badge, Button, Container, Grid, IconButton, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeartIcon } from '../../icons/heart.icon';
import { ShoppingBag } from '../../icons/shopping-bag.icon';
import { CartDrawer } from '../cart-drawer/cart-drawer';
import { CartContext } from '../../utils/cart.context';
import { WishlistContext } from '../../utils/wishlist.context';
import { useUser } from '../../utils/user.context';
import { PeopleIcon } from '../../icons/people.icon';

export const Header = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'mainMenu' });
  const { cart, setShowCart } = useContext(CartContext);
  const { items } = useContext(WishlistContext);
  const { onLogin, user } = useUser();

  return (
    <Container
      disableGutters
      maxWidth="1440"
      sx={{
        px: { md: 7, xs: 3 },
        py: { xs: 1.5 },
        background: '#E9EBFC',
        position: 'relative',
        zIndex: '9999',
      }}
    >
      <Grid container spacing={2} rowSpacing={2} alignItems="center">
        <Grid item md={1.5} xs={6}>
          <a href="tel:+380674918381">
            <Link component="button" variant="textLink">
              + 38 067 491 83 81
            </Link>
          </a>
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  md={2}*/}
        {/*  xs={6}*/}
        {/*  sx={{ display: 'flex', justifyContent: { md: 'flex-start', xs: 'flex-end' } }}*/}
        {/*>*/}
        {/*  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>*/}
        {/*    <Link*/}
        {/*      className={languages.ru === i18n.language ? 'active' : ''}*/}
        {/*      component="button"*/}
        {/*      variant="textLink"*/}
        {/*      onClick={() => i18n.changeLanguage(languages.ru)}*/}
        {/*    >*/}
        {/*      RUS*/}
        {/*    </Link>*/}
        {/*    <Link*/}
        {/*      className={languages.ua === i18n.language ? 'active' : ''}*/}
        {/*      component="button"*/}
        {/*      variant="textLink"*/}
        {/*      onClick={() => i18n.changeLanguage(languages.ua)}*/}
        {/*    >*/}
        {/*      UA*/}
        {/*    </Link>*/}
        {/*  </Stack>*/}
        {/*</Grid>*/}
        <Grid item sx={{ ml: 'auto', display: { xs: 'none', md: 'block' } }}>
          <Stack direction="row" sx={{ mr: -3 }}>
            {user ? (
              <IconButton sx={{ py: '10px' }} href="/profile/settings">
                <PeopleIcon />
              </IconButton>
            ) : (
              <Button size="small" variant="text" sx={{ py: '10px' }} onClick={() => onLogin()}>
                {t('logIn')}
              </Button>
            )}
            <IconButton sx={{ py: '10px' }} href="/saved">
              <Badge badgeContent={items.length}>
                <HeartIcon />
              </Badge>
            </IconButton>
            <IconButton sx={{ py: '10px' }} onClick={() => setShowCart(true)}>
              <Badge badgeContent={cart.length}>
                <ShoppingBag />
              </Badge>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <CartDrawer />
    </Container>
  );
};
