import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FilterChip } from './filter.styled';
import { CloseIcon } from '../../icons/close.icon';
import { FilterItem } from './filter-item/filter-item.component';

export function FilterDesktop({ filters, columns, optionsList, setFilters, values }) {
  const { t } = useTranslation('translation');

  const chips = useMemo(
    () =>
      filters.map((filter) => ({
        ...filter,
        options: filter.options.filter((item) => optionsList.includes(item.Name)),
      })),
    [filters, optionsList]
  );

  return (
    <Stack sx={{ mt: 5 }}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        {filters
          .filter(({ hidden }) => !hidden)
          .map((item) => (
            <FilterItem
              key={item.Name}
              item={item}
              columns={columns}
              value={values[item.key] || []}
              onChange={setFilters}
            />
          ))}
      </Grid>
      {optionsList.length > 0 && (
        <Stack direction="row" alignItems="center" flexWrap="wrap" sx={{ mt: 3 }} gap={2}>
          {chips.map((item) =>
            item.options.map((option) => (
              <FilterChip
                key={option.Name}
                label={option.Name}
                deleteIcon={<CloseIcon sx={{ mr: 2 }} />}
                onClick={() =>
                  setFilters(
                    item,
                    values[item.key]
                      .filter((subItem) => subItem.Name !== option.Name)
                      .map(({ Name }) => Name)
                  )
                }
                onDelete={() =>
                  setFilters(
                    item,
                    values[item.key]
                      .filter((subItem) => subItem.Name !== option.Name)
                      .map(({ Name }) => Name)
                  )
                }
              />
            ))
          )}
          {values.length ? (
            <Typography variant="textLink" onClick={() => {}}>
              {t('clearAll')}
            </Typography>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
}

FilterDesktop.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.number,
  optionsList: PropTypes.arrayOf(PropTypes.object),
  setFilters: PropTypes.func,
  values: PropTypes.object,
};
