import React from 'react';
import { SvgIcon } from '@mui/material';

export const LikeIcon = () => (
  <SvgIcon
    sx={{ width: '16px', height: '16px' }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.667 14.667h-2a1.333 1.333 0 0 1-1.334-1.334V8.667a1.333 1.333 0 0 1 1.334-1.334h2M9.333 6V3.333a2 2 0 0 0-2-2l-2.666 6v7.334h7.52a1.333 1.333 0 0 0 1.333-1.134l.92-6A1.333 1.333 0 0 0 13.107 6H9.333Z"
    />
  </SvgIcon>
);
