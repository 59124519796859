import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { generatePathWithParameters, nameToURL } from '../../../utils/friendlyURl';
import { CATEGORY_CATALOG } from '../../../constants/routes';
import { ArrowButton } from '../../arrow-button/arrow-button.component';
import { ArrowIcon } from '../../../icons/arrow.icon';
import { WOMEN_MENU_GUID } from '../../../constants/categories';
import womenBanner from '../../../assets/images/women-menu-banner.jpg';
import manBanner from '../../../assets/images/man-menu-banner.jpg';

export const MenuBannerComponent = ({ menu }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'mainMenu' });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const menuBanner = useMemo(
    () => (menu.GUID === WOMEN_MENU_GUID ? womenBanner : manBanner),
    [menu.GUID]
  );

  return (
    <Stack direction="column">
      <Box sx={{ width: isDesktop ? 'auto' : '100%' }}>
        <img style={{ width: isDesktop ? 'auto' : '100%' }} src={menuBanner} alt="brand logo" />
      </Box>
      <Typography variant="textLink">{t('bannerTitle')}</Typography>
      <Typography variant="p" className="montserrat dark">
        {t('bannerDescription')}
      </Typography>
      <ArrowButton
        variant="text"
        size="big"
        sx={{ paddingLeft: 0 }}
        startIcon={<ArrowIcon />}
        href={generatePathWithParameters(CATEGORY_CATALOG.path, {
          gender: nameToURL(menu.Name),
        })}
      >
        {t('bannerButton')}
      </ArrowButton>
    </Stack>
  );
};

MenuBannerComponent.propTypes = {
  menu: PropTypes.object,
};
