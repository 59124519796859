/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import 'yup-phone';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { CartContext } from '../../utils/cart.context';
import { OrderForm } from './order-form/order-form';
import { useOrder } from '../../services/order.service';
import { PreviewCartItem } from '../../components/preview-cart-item/preview-cart-item.component';
import { LiqPay } from '../../components/liq-pay/liq-pay';
import { deliveryMethods, paymentMethod } from './order.const';
import { useTranslation } from 'react-i18next';
import {
  GtagAddToCart,
  GtagPurchase,
  GtagViewItemList,
  GtagViewPage,
  GTMBeginCheckout,
} from '../../services/analytic-events';
import { useUser } from '../../utils/user.context';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const OrderScheme = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Обовʼязкове поле'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Обовʼязкове поле'),
  email: Yup.string().email('Невірний формат').required('Обовʼязкове поле'),
  phoneNumber: Yup.string()
    .phone('UA', true, 'Неправильний номер телефону')
    .required('Обовʼязкове поле'),
  street: Yup.string().when('delivery', {
    is: (val) => val === deliveryMethods.courier,
    then: (schema) => schema.min(2, 'Too Short!').max(50, 'Too Long!').required('Обовʼязкове поле'),
  }),
  house: Yup.string().when('delivery', {
    is: (val) => val === deliveryMethods.courier,
    then: (schema) => schema.min(1, 'Too Short!').max(50, 'Too Long!').required('Обовʼязкове поле'),
  }),
  delivery: Yup.string().required('Оберіть спосіб доставки'),
  payment: Yup.string().required('Оберіть спосіб оплати'),
  warehouse: Yup.string().when('delivery', {
    is: (val) => val === deliveryMethods.post,
    then: (schema) => schema.required('Оберіть відділення'),
  }),
  noCall: Yup.array(),
  comment: Yup.string(),
  city: Yup.string().required('Оберіть місто'),
});

export function Order() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation('translation');
  const { cart, totalPrice, totalCount } = useContext(CartContext);
  const payRef = useRef(null);
  const [orderSent, setOrderSent] = useState();
  const { mutate, isSuccess, data } = useOrder();
  const { user } = useUser();
  const navigate = useNavigate();

  const toCart = () => {
    navigate('/thank-you?orderId=' + (data[0].OrderNumber || ''));
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      comment: '',
      street: '',
      house: '',
      noCall: [],
      delivery: '',
      payment: '',
      city: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: OrderScheme,
    onSubmit: (values) => {
      mutate({
        phone: values.phoneNumber,
        email: values.email,
        name: `${values.firstName} ${values.lastName}`,
        city_user: values.city,
        street_user: values.street,
        house_user: values.house,
        rows: cart.map(({ UID, price, count, size }) => ({
          ID_tovar: UID,
          ID_size: size.GUID,
          price,
          count,
        })),
        delivery_method: values.delivery === deliveryMethods.self ? 0 : 1,
        payment_method: values.payment === paymentMethod.self ? 0 : 1,
        comments: values.comment,
      });
      setOrderSent(true);
    },
  });

  useEffect(() => {
    if (orderSent) {
      if (formik.values.payment === paymentMethod.self) {
        toCart();
      } else if (formik.values.payment === paymentMethod.card) {
        if (data) {
          payRef.current.sendPayment();
        }
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    GTMBeginCheckout(totalPrice, cart, totalCount);
    GtagPurchase(cart);
  }, []);

  useEffect(() => {
    if (user) {
      formik.setFieldValue('firstName', user.FirstName);
      formik.setFieldValue('lastName', user.LastName);
      formik.setFieldValue('phoneNumber', user.Phone);
      formik.setFieldValue('email', user.email);
      formik.setFieldValue('city', user.city);
      formik.setFieldValue('street', user.street);
      formik.setFieldValue('house', user.house);
    }
  }, [user]);

  return (
    <PageWrapper main>
      <Container maxWidth="1440" disableGutters sx={{ mt: 7, mb: 11 }}>
        <Grid container wrap={isDesktop && 'nowrap'}>
          <Grid item md={8} xs={12} sx={{ paddingRight: { md: 5, xs: 0 } }}>
            <OrderForm formik={formik} />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item md={4} xs={12}>
            <Box
              sx={{
                padding: { md: 5, xs: 0 },
              }}
            >
              {isDesktop &&
                cart.map((item) => (
                  <PreviewCartItem {...item} imageSx={{ width: '86px', height: '179px' }} />
                ))}
              {isDesktop && (
                <Stack sx={{ marginTop: 4 }}>
                  <Divider orientation="horizontal" flexItem />
                  <Stack direction="row" sx={{ marginTop: 4 }} justifyContent="space-between">
                    <Typography variant="p" component="p" className="montserrat dark">
                      {totalCount} {t('goodsWorth')}:
                    </Typography>
                    <Typography variant="p" component="p" className="montserrat dark">
                      {totalPrice} грн
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '26px' }}>
                    <Typography variant="p" component="p" className="montserrat dark">
                      {t('delivery')}:
                    </Typography>
                    <Typography variant="p" component="p" className="montserrat dark">
                      0 грн
                    </Typography>
                  </Stack>
                  <Divider orientation="horizontal" sx={{ marginTop: '26px' }} flexItem />
                </Stack>
              )}
              <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 3 }}>
                <Typography variant="headingSmall" component="p" className="semi-bold dark">
                  {t('orderPrice')}
                </Typography>
                <Typography variant="headingSmall" component="p" className="semi-bold dark">
                  {totalPrice} грн
                </Typography>
              </Stack>
              <Stack sx={{ marginTop: 3 }}>
                <LiqPay
                  ref={payRef}
                  amount={totalPrice}
                  orderId={(data && data[0].OrderNumber) || ''}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 2, width: '100%' }}
                  onClick={() => formik.submitForm()}
                >
                  {t('confirmOrder')}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
}
