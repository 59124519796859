/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNomenclatureGet } from '../../../services/nomenclature.service';
import { normalizeNomenclature } from '../../../utils/normalizers';
import { isArray } from 'lodash';

export const OrderProductCard = ({ id, sizeId }) => {
  const { mutate, data, isSuccess } = useNomenclatureGet();

  const productItem = useMemo(() => {
    if (isSuccess && data) {
      return normalizeNomenclature(data.data[0]);
    }
    return undefined;
  }, [isSuccess, data]);

  const size = useMemo(() => {
    if (productItem && isArray(productItem.additionalinfo.size)) {
      return (
        productItem.additionalinfo.size.find(({ GUID }) => GUID === sizeId) ||
        productItem.additionalinfo.size[0]
      );
    }
    return undefined;
  }, [productItem, sizeId]);

  useEffect(() => {
    mutate({ ID_nomenclature: id });
  }, [id]);

  return productItem ? (
    <Stack direction="row">
      <Box
        sx={{
          width: '128px',
          height: '179px',
          background: `url(${productItem.mainPhoto.url}) no-repeat center center`,
          backgroundSize: 'contain',
          backgroundOrigin: 'border-box',
          marginRight: '24px',
        }}
      />
      <div>
        <Typography variant="subheadingMedium">{productItem.fullname}</Typography>
        <Stack direction="column" spacing={1.5} sx={{ mt: 1.5 }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" className="gray">
              код товару: {'  '}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {productItem.vendorCode}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" className="gray">
              ціна: {'  '}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {productItem.price}
            </Typography>
          </Stack>
          {size && (
            <Stack direction="row" spacing={1}>
              <Typography variant="body1" className="gray">
                розмір
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {size.name}
              </Typography>
            </Stack>
          )}
        </Stack>
      </div>
    </Stack>
  ) : (
    <div></div>
  );
};
