import React, { useMemo, useState } from 'react';
import {
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Stack,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { SearchIcon } from '../../icons/search.icon';
import { BrandItem } from './components/brand-item/brand-item';
import { useGetBrand } from '../../services/brand.service';
import { compare } from '../../utils/sort-brand';

const BrandLetter = styled('div')({
  fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
  transition: 'all .2s ease-out',
  'text-transform': 'uppercase',
  cursor: 'pointer',
  'font-weight': '400',
  'font-size': '15px',
  'line-height': '28px',
  padding: '10px 18.5px',
  'min-width': '48px',
  height: '48px',
  'box-sizing': 'border-box',
  'text-align': 'center',
  '&.selected, &:hover': {
    'background-color': '#e9ebfc',
  },
});

const BrandLetterTitle = styled('div')({
  fontFamily: `Montserrat, Tahoma, Sans-Serif`,
  'font-weight': '700',
  'font-size': '24px',
  'line-height': '29px',
  'margin-bottom': '45px',
});

const letters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export function SearchBrand() {
  const { t } = useTranslation('translation');
  const [currentLetter, setCurrentLetter] = useState('');
  const [search, setSearch] = useState('');
  const { data, isSuccess } = useGetBrand();

  const sortedArray = useMemo(() => (data ? data.sort(compare) : []), [data]);

  return (
    <PageWrapper>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 3 } }}>
        <Stack direction="row" sx={{ mt: 4, display: { md: 'flex', xs: 'none' } }}>
          {letters.map((item) => (
            <BrandLetter
              className={currentLetter === item && 'selected'}
              onClick={() => setCurrentLetter(item)}
            >
              {item}
            </BrandLetter>
          ))}
          <BrandLetter
            className={currentLetter === '0-9' && 'selected'}
            onClick={() => setCurrentLetter('0-9')}
          >
            0-9
          </BrandLetter>
        </Stack>
        <Grid container sx={{ mt: 4 }}>
          <Grid item md={3} xs={12}>
            <FormControl color="primary" variant="filled" sx={{ width: '300px' }}>
              <OutlinedInput
                className="no-margin"
                placeholder={t('searchByBrand')}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                startAdornment={
                  <InputAdornment
                    disableTypography
                    position="start"
                    style={{
                      'margin-top': '8px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        'align-items': 'center',
                        'justify-content': 'center',
                      }}
                    >
                      <SearchIcon />
                    </div>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={9} xs={12} sx={{ px: { md: '120px' } }}>
            {isSuccess ? (
              sortedArray
                .filter((item) => !search || item.name.toUpperCase().includes(search.toUpperCase()))
                .filter(
                  (item) =>
                    !currentLetter ||
                    Array.from(item.name)[0].toUpperCase() === currentLetter.toUpperCase()
                )
                .map((item, index, brandArray) => {
                  const firstLetter =
                    index === 0 ||
                    item.name.charCodeAt(0) !== brandArray[index - 1].name.charCodeAt(0)
                      ? Array.from(item.name)[0]
                      : '';
                  const isLastItem =
                    index < brandArray.length - 1 &&
                    item.name.charCodeAt(0) !== brandArray[index + 1].name.charCodeAt(0);
                  return (
                    <div>
                      {firstLetter && (
                        <BrandLetterTitle sx={{ mt: { xs: 5, md: 0 } }}>
                          {firstLetter}
                        </BrandLetterTitle>
                      )}
                      <BrandItem
                        name={item.name}
                        image={item.link}
                        description={item.description}
                        lastItem={isLastItem}
                        searchText={search}
                      />
                      {isLastItem && <Divider sx={{ mb: 7, mt: 7.5 }} />}
                    </div>
                  );
                })
            ) : (
              <Stack spacing={5}>
                <Skeleton variant="rectangular" width="100%" height={240} />
                <Skeleton variant="rectangular" width="100%" height={240} />
                <Skeleton variant="rectangular" width="100%" height={240} />
                <Skeleton variant="rectangular" width="100%" height={240} />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  );
}
