/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@mui/material';

export const StarIcon = ({ sx }) => (
  <SvgIcon height="12" viewBox="0 0 14 12" fill="none" sx={sx}>
    <path
      d="M13.0798 4.22554L9.18268 3.67806L7.44055 0.264102C7.39297 0.170629 7.31469 0.0949614 7.21799 0.0489671C6.97547 -0.0667604 6.68077 0.0296792 6.55951 0.264102L4.81738 3.67806L0.920236 4.22554C0.812792 4.24038 0.714557 4.28934 0.639346 4.36352C0.548421 4.45386 0.498316 4.5754 0.500043 4.70142C0.50177 4.82745 0.555187 4.94766 0.648556 5.03563L3.46819 7.69291L2.80204 11.4452C2.78642 11.5324 2.79641 11.6222 2.83088 11.7043C2.86536 11.7864 2.92293 11.8575 2.99708 11.9095C3.07123 11.9616 3.15898 11.9925 3.25039 11.9988C3.3418 12.0051 3.4332 11.9865 3.51424 11.9452L7.00003 10.1736L10.4858 11.9452C10.581 11.9941 10.6915 12.0104 10.7974 11.9926C11.0645 11.9481 11.2441 11.7033 11.198 11.4452L10.5319 7.69291L13.3515 5.03563C13.4283 4.96293 13.4789 4.86798 13.4943 4.76412C13.5357 4.50447 13.3484 4.26412 13.0798 4.22554Z"
      fill="currentColor"
    />
  </SvgIcon>
);
