import React from 'react';
import * as PropTypes from 'prop-types';

export const Dots = ({ dots }) => (
  <div className="slick-dots slick-dots__flex">
    <ul>{dots}</ul>
  </div>
);

Dots.propTypes = {
  dots: PropTypes.element,
};
