import React from 'react';
import { SvgIcon } from '@mui/material';

export const ArrowRightIcon = () => (
  <SvgIcon
    sx={{ width: '32px', height: '32px' }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m18 13.333 3.333 3.334L18 20"
    />
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.667 12.667V14a2.666 2.666 0 0 0 2.666 2.667h8"
    />
    <rect fill="none" width="31" height="31" x=".5" y=".5" stroke="#121212" rx="15.5" />
  </SvgIcon>
);
