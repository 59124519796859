/* eslint-disable */
import React from 'react';
import { styled } from '@mui/material';
import { MainBanner } from './components/main-banner/main-banner.component';
import { HitBlock } from './components/hit-block/hit-block.component';
import { Video } from './components/video/video.component';
import { Collections } from './components/collections/collections.component';
import { SelectionProducts } from './components/selection-products/selection-products.component';
import { AboutUs } from './components/about-us/about-us.component';
import curveLogo from '../../assets/images/curve-logo.svg';
import { ContactUs } from './components/contact-us/contact-us.component';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { BrandList } from './components/brand-list/brand-list';
import { BannersList } from './components/banners-list/banners-list.component';

const CurveLogo = styled('div')(() => ({
  position: 'relative',
  '& div': {
    position: 'absolute',
    width: '40px',
    height: '40px',
    marginRight: '12px',
    transform: 'translate(-77px, -77px)',
    left: '50%',
  },
}));

export const Main = () => {
  return (
    <PageWrapper main>
      <MainBanner />
      <HitBlock />
      <BannersList />
      <BrandList />
      <Video />
      <Collections />
      <SelectionProducts />
      <AboutUs />
      <CurveLogo>
        <div>
          <img src={curveLogo} alt="" />
        </div>
      </CurveLogo>
      <ContactUs />
    </PageWrapper>
  );
};
