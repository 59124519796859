import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import * as PropTypes from 'prop-types';
import { CloseIcon } from '../../../icons/close.icon';

export function MobileMainList({ filters, setSubmenu, setOpen, setOpenMenu, values, onSave }) {
  const { t } = useTranslation('translation');
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#fff',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle3" className="bold" component="div">
          {t('filter')}
        </Typography>
        <IconButton sx={{ margin: 0 }} onClick={() => setOpen(false)}>
          <CloseIcon sx={{ color: '#121212' }} />
        </IconButton>
      </Stack>
      <List component="nav" className="mobile-menu" aria-label="main-menu" sx={{ mt: 2 }}>
        <Divider />
        <ListItem
          disableGutters
          component="div"
          onClick={() => setOpenMenu(true)}
          secondaryAction={
            <IconButton edge="end">
              <NavigateNextIcon />
            </IconButton>
          }
        >
          <ListItemText primary="Подкатегория" />
        </ListItem>
        {filters
          .filter(({ hidden }) => !hidden)
          .map((item) => (
            <>
              <Divider />
              <ListItem
                disableGutters
                component="div"
                onClick={() => setSubmenu(item)}
                secondaryAction={
                  <IconButton edge="end">
                    <NavigateNextIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={item.label}
                  secondary={
                    values ? (values[item.key] || []).map(({ Name }) => Name).join(', ') : ''
                  }
                />
              </ListItem>
            </>
          ))}
        <Divider />
      </List>
      <Button variant="contained" color="primary" sx={{ mt: 11, width: '100%' }} onClick={onSave}>
        {t('apply')}
      </Button>
      <Button color="error" sx={{ mt: 2, width: '100%' }} onClick={() => setOpen(false)}>
        {t('cancel')}
      </Button>
    </Box>
  );
}

MobileMainList.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object,
  setOpen: PropTypes.func,
  setOpenMenu: PropTypes.func,
  setSubmenu: PropTypes.func,
  onSave: PropTypes.func,
};
