import React from 'react';
import { SvgIcon } from '@mui/material';

export const DeliveryIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18.704 18.296a1.583 1.583 0 1 1-2.24 2.24 1.583 1.583 0 1 1 2.24-2.24M7.704 18.296a1.583 1.583 0 1 1-2.238 2.24 1.583 1.583 0 0 1 2.238-2.24M10 4h4a1 1 0 0 1 1 1v10H2"
    />
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5 19.416H3a1 1 0 0 1-1-1V13M15 7h4.323c.409 0 .777.249.928.629l1.606 4.014c.094.236.143.488.143.742v5.948a1 1 0 0 1-1 1h-1.831M16 19.42H8.17"
    />
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M22 14h-4v-4h3.2M2 4h5M2 7h3M3 10H2"
    />
  </SvgIcon>
);
