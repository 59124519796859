import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href || ''} {...other} />;
});

LinkBehavior.propTypes = {
  href: PropTypes.string,
};

export default function componentStyleOverrides(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          variant: 'contained',
          fontFamily: `Montserrat, Tahoma, Sans-Serif`,
          fontWeight: 500,
          borderRadius: '2px',
          padding: '16px 24px',
          fontSize: '12px',
          letterSpacing: '0.02em',
          lineHeight: '16px',
          '&.uppercase': {
            textTransform: 'uppercase',
          },
          '&.MuiButton-contained.MuiButton-containedSecondary': {
            color: theme.colors?.darkPrimaryMain,
            '&:hover': {
              boxShadow: 'none',
            },
            '&.MuiButton-containedSizeSmall': {
              padding: '12px 22px',
            },
          },
          '&.MuiButton-sizeSmall': { padding: '11px 22px' },
          '&.MuiButton-outlined': {
            paddingTop: '15px',
            paddingBottom: '15px',
          },
        },
        text: {
          '&': {
            fill: 'transparent',
          },
          '&:hover': {
            background: 'none',
            '& .MuiSvgIcon-root': {
              fill: '#121212',
              color: '#fff',
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          textAlign: 'start',
          color: theme.colors?.darkPrimaryMain,
          '&.active': {
            fontWeight: '600',
            color: theme.colors?.darkPrimaryMain,
          },
          '&.uppercase': {
            textTransform: 'uppercase',
          },
          '&.main-menu': {
            padding: '21px 0px',
            '&.active, &:hover': {
              borderBottom: '1px solid #282932',
            },
          },
          '&.main-menu-link': {
            borderBottom: '1px solid #fff',
            '&.active, &:hover': {
              borderBottom: '1px solid #282932',
            },
          },
          '&.underline': {
            textDecoration: 'underline',
          },
          '&.lato': {
            fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
          },
          '&.center': {
            textAlign: 'center',
          },
          '&.main-menu:hover': { textDecoration: 'underline' },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.colors?.selectBorder,
          opacity: 1,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          '&.no-vertical-paddings': {
            padding: 0,
          },
          [theme.breakpoints.up('xs')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
            '&.no-vertical-paddings-mobile': {
              paddingLeft: '0px',
              paddingRight: '0px',
            },
          },
          [theme.breakpoints.up('md')]: {
            paddingLeft: '56px',
            paddingRight: '56px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#EEE0D8',
          fontSize: '14px',
          '$::before': {
            borderBottom: '1px solid #fff',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.clear': {
            borderBottom: 'none',
            '&::before': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: theme.colors?.darkPrimaryMain,
          borderRadius: '2px',
          borderColor: theme.colors?.selectBorder,
        },
        input: {
          padding: '12px 16px',
        },
        notchedOutline: {
          focused: {
            border: '1px solid #121212',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
          position: 'relative',
          color: theme.colors?.darkPrimaryMain,
          left: '-14px',
          top: '-22px',
          fontSize: '13px',
          lineHeight: '22px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: theme.colors?.darkPrimaryMain,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: '0 24px',
          boxShadow: 'none',
          backgroundColor: theme.colors?.lightGrey,
          '&.menu': {
            fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.darkPrimaryMain,
            backgroundColor: '#fff',
            padding: '0',
          },
          '&:last-of-type': {
            borderRadius: '0',
          },
          '&:first-of-type': {
            borderRadius: '0',
          },
          '&::before': {
            content: 'none',
          },
          '&.Mui-expanded': {
            marginTop: 0,
          },
        },
        region: {
          marginTop: '8px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 8px',
          '&.menu': {
            padding: '0',
            borderBottom: `1px solid ${theme.colors?.selectBorder}`,
          },
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          margin: '16px 0',
          '&.Mui-expanded': {
            margin: '16px 0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          '&.menu': {
            fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
            fontSize: '14px',
            padding: '8px 12px',
            lineHeight: '24px',
            color: theme.colors.primary200,
            cursor: 'pointer',
            '&:hover, &.active': { backgroundColor: theme.colors.lightGrey },
            '&.active': { color: theme.colors.darkPrimaryMain },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 28,
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 28,
          padding: '8px 0',
          textTransform: 'unset',
          color: theme.colors?.primary200,
          fontWeight: 600,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root:nth-child(odd)': {
            backgroundColor: theme.colors?.lightGrey,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '12px',
          marginRight: '12px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
          fontSize: '14px',
          color: theme.colors?.darkPrimaryMain,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {},
          '& .MuiDrawer-paper': {},
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '.side-menu & ': {
            paddingLeft: '24px',
            '&.active': {
              borderLeft: '1px solid #121212',
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '.mobile-menu &': {
            display: 'flex',
            alignItems: 'center',
          },
          '.side-menu &': {
            color: theme.colors?.primaryDark,
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
        primary: {
          fontWeight: 'inherit',
        },
        secondary: {
          '.mobile-menu &': {
            marginLeft: '12px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.brand-logo': {
            position: 'relative',
            opacity: 0.8,
            '&:hover': {
              opacity: 1,
              '&::after': {
                content: '" "',
                display: 'block',
                zIndex: -1,
                position: 'absolute',
                width: '64px',
                height: '64px',
                backgroundColor: '#E9EBFC',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '100%',
              },
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: 'inherit',
          '&.Mui-disabled': {
            fill: 'currentColor',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.square': {
            borderRadius: '0',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        input: {
          padding: ' 3px !important',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '13px',
          fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
          color: '#000',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          background: 'none',
          border: 'none',
          '&.Mui-selected': {
            background: 'none',
            border: `1px solid ${theme.colors?.darkPrimaryMain}`,
            borderRadius: '2px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'block',
          marginBottom: '4px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiAlert-standard': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
  };
}
