import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../../utils/cart.context';
import { NumberMinusIcon } from '../../../icons/number-minus.icon';
import { NumberPlusIcon } from '../../../icons/number-plus.icon';
import { HeartIcon } from '../../../icons/heart.icon';
import { CancelIcon } from '../../../icons/cancel.icon';
import { WishlistContext } from '../../../utils/wishlist.context';

export function CartItem({ mainPhoto, brand, price, name, size, color, UID, count }) {
  const { removeItem, changeCount } = useContext(CartContext);
  const { addItem } = useContext(WishlistContext);
  const { t } = useTranslation('translation');

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: '128px',
          height: '179px',
          background: `url("${mainPhoto.url}") no-repeat center center`,
          backgroundSize: 'contain',
          backgroundOrigin: 'border-box',
        }}
      />
      <Stack direction="column" sx={{ marginLeft: 3, flex: 1, paddingRight: 2.25 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" className="gray montserrat medium">
            {brand}
          </Typography>
          <Typography variant="headingSmall" component="p" className="semi-bold dark">
            {price} грн
          </Typography>
        </Stack>
        <Typography variant="subtitle3" sx={{ marginTop: 1 }}>
          {name}
        </Typography>
        <Stack direction="row" sx={{ marginTop: 2 }} spacing={4} alignItems="center">
          <Stack direction="row">
            <Typography variant="body1" className="gray">
              {t('size')}:
            </Typography>
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: 1 }}>
              {size.name}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body1" className="gray">
              {t('color')}:
            </Typography>
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: 1 }}>
              {color}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" className="gray">
              {t('count')}:
            </Typography>
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: 1 }}>
              <IconButton
                sx={{ color: count > 1 ? '#121212' : '#D7D7D7' }}
                onClick={() => count > 1 && changeCount(UID, size, count - 1)}
              >
                <NumberMinusIcon />
              </IconButton>
              {count}
              <IconButton
                sx={{ color: '#121212' }}
                onClick={() => changeCount(UID, size, count + 1)}
              >
                <NumberPlusIcon />
              </IconButton>
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{ marginTop: 'auto' }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ cursor: 'pointer' }} direction="row" alignItems="center">
            <Typography variant="body1">{t('favorites')}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 1.25,
              }}
              onClick={() => addItem({ mainPhoto, brand, price, name, UID }, size)}
            >
              <HeartIcon
                sx={{
                  width: '14px',
                  height: '14px',
                }}
              />
            </Box>
          </Stack>
          <Stack
            sx={{ cursor: 'pointer' }}
            direction="row"
            alignItems="center"
            onClick={() => removeItem(UID, size)}
          >
            <Typography variant="body1">{t('remove')}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 1.25,
              }}
            >
              <CancelIcon
                sx={{
                  width: '14px',
                  height: '14px',
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

CartItem.propTypes = {
  mainPhoto: PropTypes.string,
  brand: PropTypes.string,
  price: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  UID: PropTypes.string,
  count: PropTypes.string,
};
