/* eslint-disable */
import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Slider from 'react-slick';
import * as PropTypes from 'prop-types';
import { blockTypes } from '../../../../constants/blockTypes';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { SliderArrowIcon } from '../../../../icons/slider-arrow.icon';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{ right: '2vw', width: '48px', height: '48px' }}
      onKeyPress={onClick}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <SliderArrowIcon />
    </Box>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        left: '37vw',
        transform: 'rotate(180deg) translate(0px, 24px)',
        width: '48px',
        height: '48px',
      }}
      onKeyPress={onClick}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <SliderArrowIcon />
    </Box>
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export const MainBanner = () => {
  const { data } = useGetBlockWithBanners(blockTypes.main_banner);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const settings = {
    nextArrow: isDesktop ? <SampleNextArrow /> : <div />,
    prevArrow: isDesktop ? <SamplePrevArrow /> : <div />,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          appendDots: (dots) => (
            <div
              style={{
                position: 'absolute',
                top: '300px',
                height: '50px',
              }}
            >
              <ul style={{ margin: '0px' }}> {dots} </ul>
            </div>
          ),
          dotsClass: 'slick-dots slick-dots__flex',
          dots: true,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{ px: { md: 7, xs: 0 }, py: { md: 7.25, xs: 4 } }}
    >
      <Slider {...settings} slidesToShow={1}>
        {data.banners.map((banner) => (
          <div>
            <Grid
              container
              rowSpacing={0}
              alignItems="start"
              sx={{ background: { md: '#F6F6F6', xs: '#fff' } }}
              wrap
            >
              <Grid
                item
                xs={12}
                md={4.5}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: { md: 12.5, xs: 2 },
                  px: { md: 7, xs: 2 },
                  order: { xs: 1, md: 0 },
                }}
              >
                <Typography variant="h4" component="h1">
                  {banner.title}
                </Typography>
                <Typography variant="body2" component="p" sx={{ mt: 2 }}>
                  {banner.description}
                </Typography>
                <Stack direction={{ md: 'row', xs: 'column' }} spacing={2} sx={{ mt: 5 }}>
                  {banner.button_text1 && (
                    <Button variant="contained" href={banner.button_link1}>
                      {banner.button_text1}
                    </Button>
                  )}
                  {banner.button_text2 && (
                    <Button variant="contained" href={banner.button_link2}>
                      {banner.button_text2}
                    </Button>
                  )}
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                md={7.5}
                sx={{ order: { xs: 0, md: 1 }, mb: { xs: '50px', md: 0 } }}
              >
                <Paper
                  sx={{
                    width: '100%',
                    paddingTop: '73%',
                    display: 'flex',
                    background: `url(${banner.image}) no-repeat center center`,
                    backgroundSize: 'cover',
                    backgroundOrigin: 'border-box',
                  }}
                  variant="outlined"
                  square
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>
    </Container>
  );
};
