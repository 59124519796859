import React from 'react';
import { SvgIcon } from '@mui/material';

export const NumberMinusIcon = () => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" fill="#fff" />
    <path
      d="M7.66699 12H16.3334"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
