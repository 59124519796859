import React from 'react';
import { Container, Grid, Link, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Filter } from '../filter/filter.container';
import { SideMenuComponent } from '../side-menu/side-menu.component';

export function CatalogComponent({ title, description }) {
  const { t } = useTranslation('translation');

  return (
    <Container maxWidth="1440" disableGutters sx={{ mt: 3.25 }}>
      <Grid container spacing={5}>
        <SideMenuComponent />
        <Grid item md={9} xs={12}>
          {title && (
            <Typography variant="title1" component="h2" style={{ textTransform: 'capitalize' }}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="p" component="p" sx={{ mt: 2.75 }}>
              {description}
              <Link variant="p" href="src/components/catalog/catalog.container#">
                {t('readMore')}
              </Link>
            </Typography>
          )}
          <Filter columns={3} header pagination fromKey="Catalog" />
        </Grid>
      </Grid>
    </Container>
  );
}

CatalogComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
