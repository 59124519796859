import React from 'react';
import { SvgIcon } from '@mui/material';

export const ShoppingBag = () => (
  <SvgIcon viewBox="0 0 22 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.76 20.64H6.24C5.1792 20.64 4.32 19.7808 4.32 18.72V8.64001C4.32 7.84513 4.96512 7.20001 5.76 7.20001H18.24C19.0349 7.20001 19.68 7.84513 19.68 8.64001V18.72C19.68 19.7808 18.8208 20.64 17.76 20.64Z"
      stroke="#121212"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.839 10.368C15.7066 10.368 15.599 10.4755 15.6 10.608C15.6 10.7405 15.7075 10.848 15.84 10.848C15.9725 10.848 16.08 10.7405 16.08 10.608C16.08 10.4755 15.9725 10.368 15.839 10.368"
      stroke="#121212"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.15904 10.368C8.02656 10.368 7.91904 10.4755 7.92 10.608C7.92 10.7405 8.02752 10.848 8.16 10.848C8.29248 10.848 8.4 10.7405 8.4 10.608C8.4 10.4755 8.29248 10.368 8.15904 10.368"
      stroke="#121212"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.16 7.19999V6.95999V6.95999C8.16 4.97183 9.77184 3.35999 11.76 3.35999H12.24C14.2282 3.35999 15.84 4.97183 15.84 6.95999V6.95999V7.19999"
      stroke="#121212"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
