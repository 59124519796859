import React from 'react';
import { Button, Container, Grid, Stack } from '@mui/material';
import { SmallBanner } from '../../../../components/small-banner/small-banner.component';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';

export const SelectionProducts = () => {
  const { data: selectionBlock } = useGetBlockWithBanners(blockTypes.selection_banner);

  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 7, xs: 3 },
        pt: 7.25,
        background: 'linear-gradient(0, #fff 49%, #E9EBFC 49%)',
      }}
    >
      {selectionBlock.banners && selectionBlock.banners.length > 0 && (
        <Grid container direction="row" spacing={5}>
          <Grid item md={4} xs={12}>
            <SmallBanner banner={selectionBlock.banners[0]} />
          </Grid>
          <Grid item md={8} xs={12}>
            <SmallBanner banner={selectionBlock.banners[1]} double />
          </Grid>
        </Grid>
      )}
      <Stack justifyContent="center" direction="row">
        <Button
          variant="outlined"
          sx={{ mt: 4, mx: 'auto' }}
          href={selectionBlock.block.link_button}
        >
          {selectionBlock.block.link_button_text}
        </Button>
      </Stack>
    </Container>
  );
};
