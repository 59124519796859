import React from 'react';
import { SvgIcon } from '@mui/material';

export const ArrowClockIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3.52 8.999a8.996 8.996 0 1 1 2.113 9.368"
    />
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.999 15.001 12 12V6.998M6.998 8.999H2.996V4.997"
    />
  </SvgIcon>
);
