import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { PlusIcon } from '../../icons/plus.icon';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';

export function SideMenuComponent() {
  const { updatePath, genderCategory, subcategory } = useContext(CategoryFiltersContext);

  const isCurrentItem = (name) => subcategory?.Name === name;

  /**
   * TODO refactor to list
   */
  return (
    <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
      {genderCategory ? (
        genderCategory?.Kids.map(({ Name, Kids }) => (
          <Accordion key={Name} className="menu">
            <AccordionSummary className="menu" expandIcon={<PlusIcon />}>
              <Typography
                variant="label14Semibold"
                component="p"
                style={{ textTransform: 'capitalize' }}
              >
                {Name}
              </Typography>
            </AccordionSummary>
            {Kids.map((item) => (
              <AccordionDetails
                className={`menu ${isCurrentItem(item.Name) && 'active'} `}
                style={{ textTransform: 'capitalize' }}
                onClick={() =>
                  updatePath({
                    gender: genderCategory.Name,
                    category: item.Parent,
                    subcategory: item.Name,
                  })
                }
              >
                {item.Name}
              </AccordionDetails>
            ))}
          </Accordion>
        ))
      ) : (
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
          <Skeleton variant="rectangular" width="100%" height={46} />
        </Stack>
      )}
    </Grid>
  );
}
