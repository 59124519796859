import React from 'react';
import { SvgIcon } from '@mui/material';

export const RulerIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
    <path
      fill="#4F4F4F"
      d="M3.5 5A1.5 1.5 0 0 0 2 6.5v3A1.5 1.5 0 0 0 3.5 11h9A1.5 1.5 0 0 0 14 9.5v-3A1.5 1.5 0 0 0 12.5 5h-9ZM3 6.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H11V8.5a.5.5 0 0 0-1 0V10H8.5V8a.5.5 0 1 0-1 0v2H6V8.5a.5.5 0 1 0-1 0V10H3.5a.5.5 0 0 1-.5-.5v-3Z"
    />
  </SvgIcon>
);
