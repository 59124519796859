/* eslint-disable */
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import React from 'react';

export const INPUT_VARIANTS = { outline: 'outline', contain: 'contain' };

export function InputField({
  minRows = 1,
  label,
  placeholder,
  onChange,
  value,
  id,
  name,
  error,
  ...props
}) {
  return (
    <FormControl sx={{ width: '100%' }}>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <OutlinedInput
        id={id}
        name={name}
        sx={{ marginTop: 0.5 }}
        aria-labelledby={placeholder}
        placeholder={placeholder}
        minRows={minRows}
        multiline={minRows > 1}
        onChange={onChange}
        value={value}
        error={!!error}
        {...props}
      />
      <FormHelperText error={!!error} sx={{ position: 'absolute', top: '100%', mx: 0 }}>
        {error}
      </FormHelperText>
    </FormControl>
  );
}
