import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import emptyCart from '../../assets/images/empty-cart.svg';

export function EmptyCart({ title, description, onClose }) {
  const { t } = useTranslation('translation', { keyPrefix: 'emptyCart' });
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ padding: { md: '100px 127px', xs: '100px 55px' } }}
    >
      <div>
        <img src={emptyCart} alt="" />
      </div>
      <Typography variant="label14Semibold" sx={{ mt: 7.5, textAlign: 'center' }} component="p">
        {title || t('title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 2, textAlign: 'center' }}
        className="regular"
        component="p"
      >
        {description || t('description')}
      </Typography>
      {onClose && (
        <Button variant="outlined" sx={{ mt: 4 }} onClick={onClose}>
          Повернутись до покупок
        </Button>
      )}
    </Stack>
  );
}

EmptyCart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
};
