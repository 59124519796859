/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Chip,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import * as PropTypes from 'prop-types';
import { createSearchParams, useNavigate, Link } from 'react-router-dom';
import { CloseIcon } from '../../../icons/close.icon';
import { SearchIcon } from '../../../icons/search.icon';
import { CATEGORY_CATALOG, routes } from '../../../constants/routes';
import { TopDrawer } from '../../top-drawer/top-drawer';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { generatePathWithParameters, nameToURL } from '../../../utils/friendlyURl';
import { normalizeNomenclature } from '../../../utils/normalizers';
import { useNomenclatureListGet } from '../../../services/nomenclature-list.service';

function findById(array, value, parentGUID) {
  const results = [];
  for (const item of array) {
    if (item.Name.toUpperCase().includes(value.toUpperCase())) {
      results.push({ ...item, gender: item.Name, label: item.Name });
    }
    if (item.Kids?.length) {
      const innerResult = findById(item.Kids, value, parentGUID ? parentGUID : item.GUID);
      if (innerResult) {
        results.push(
          ...innerResult.map((subItem) => ({
            ...subItem,
            gender: item.Name,
            category: subItem.Parent,
            subcategory: subItem.Name,
            label: `${subItem.Name} (${subItem.Parent}${item.Name ? ' - ' + item.Name : ''})`,
            parentGUID,
          }))
        );
      }
    }
  }

  return results;
}

const DrawerItem = ({ item }) => {
  const product = normalizeNomenclature(item);
  return (
    <Link to={`/product/${product.UID}`}>
      <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={() => {}}>
        <Paper
          sx={{
            width: '110px',
            height: '136px',
            background: `url(${product.mainPhoto.url}) no-repeat center center`,
            backgroundSize: 'contain',
            backgroundOrigin: 'border-box',
          }}
        />
        <Stack direction="column" sx={{ ml: 1.5 }}>
          <Typography variant="label12Medium">{product.additionalinfo.manufacturer}</Typography>
          <Typography variant="body1" sx={{ mt: 0.5 }}>
            {product.fullname}
          </Typography>
          <Typography variant="p" className="montserrat bold dark">
            {product.price} грн
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export const SearchDrawer = ({ setShow, show, mainMenu }) => {
  const { t } = useTranslation('translation');
  const [value, setValue] = useState('');
  const [items, setItems] = useState(undefined);
  const { data, setParameters } = useNomenclatureListGet('search-recommendation');
  const navigate = useNavigate();

  const categories = useMemo(() => (value !== '' ? findById(mainMenu, value) : []), [value]);

  const clearField = () => {
    setValue('');
  };

  const toSearch = () => {
    navigate({
      pathname: '/search-catalog',
      search: `?${createSearchParams({ vendorCode: JSON.stringify(value) })}`,
    });
    setShow(false);
  };

  useEffect(() => {
    if (value.length > 3) {
      setParameters({ filter: { vendorCode: value }, page_number: '0', items: '3' });
    }
  }, [value]);

  // useEffect(() => {
  //   setParameters({ filter: { vendorCode: 'Теніска' }, page_number: '0', items: '3' });
  // }, []);

  useEffect(() => {
    if (data?.meta?.totalItems) {
      setItems(data?.data);
    }
  }, [data]);

  useEffect(() => {
    clearField();
  }, [show]);

  return (
    <TopDrawer show={show} setShow={setShow}>
      <Container
        maxWidth="md"
        disableGutters
        sx={{ mt: 8, mb: 9.5 }}
        onMouseLeave={() => setShow(false)}
      >
        <FormControl sx={{ width: '100%' }}>
          <OutlinedInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={t('searchByBrandOrProduct')}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                toSearch();
              }
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ cursor: 'pointer' }} onClick={toSearch}>
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={clearField}>
                <CloseIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <Grid container sx={{ mt: 1 }} spacing={1.5}>
          {categories.map(({ label, GUID, gender, category, subcategory }) => (
            <Grid key={GUID} item>
              <Chip
                label={label}
                className="square"
                component={RouterLink}
                to={generatePathWithParameters(CATEGORY_CATALOG.path, {
                  gender: nameToURL(gender),
                  category: nameToURL(category),
                  subcategory: nameToURL(subcategory),
                })}
                onClick={() => {
                  setShow(false);
                }}
                clickable
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="subtitle3" component="div" sx={{ mt: 7 }}>
          {t('popularGoods')}
        </Typography>
        <Stack
          sx={{ mt: 3 }}
          direction={{ md: 'row', xs: 'column' }}
          spacing={{ md: 5, xs: 4 }}
          justifyContent="space-between"
        >
          {items && items?.map((item) => <DrawerItem key={item.UID} item={item} />)}
        </Stack>
      </Container>
    </TopDrawer>
  );
};

SearchDrawer.propTypes = {
  setShow: PropTypes.func,
  show: PropTypes.bool,
};
