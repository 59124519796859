/* eslint-disable */
import { nameToURL } from './friendlyURl';

export const splitUrlValue = (value) => value.split('+');

export const joinUrlValues = (value) => value.join('+');

export const findAllItemsInArray = (array, value) =>
  array.filter((item) => value.includes(nameToURL(item.Name)));

export const findSome = (values, options) => {
  let updatedValue = [];
  values.some((value) => {
    const newValue = findAllItemsInArray(options, splitUrlValue(value));
    if (newValue.length > 0) {
      updatedValue = newValue;
      return true;
    }
  });

  return updatedValue;
};
