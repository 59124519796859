import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const PublicOfferComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Публічна оферта</Typography>
    <Divider />
    <Typography variant="subheadingMedium">Загальні положення</Typography>
    <Typography variant="body2">
      1.1. Справжня оферта, є офіційною пропозицією ТМ «ТОРГОВИЙ ДІМ &quot;МАНН&quot;», далі за
      текстом - «Продавець», укласти Договір купівлі-продажу товарів дистанційним способом, тобто
      через Інтернет-магазин, далі по тексту - «Договір», і розміщує Публічну оферту (пропозицію) на
      офіційному інтернет-сайті Продавця «https://tothenines.com.ua/ (далі -« Інтернет-сайт »).
    </Typography>
    <Typography variant="body2">
      1.2. Моментом повного і безумовного прийняття Покупцем пропозиції Продавця (акцептом) укласти
      електронний договір купівлі-продажу товарів, вважається факт оплати Покупцем замовлення на
      умовах цього Договору, у строки та за цінами, вказаними на Інтернет-сайті Продавця.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Поняття і визначення</Typography>
    <Typography variant="body2">
      2.1. У цій оферті, якщо контекст не вимагає іншого, наведені нижче терміни мають таке
      значення:
    </Typography>
    <Typography variant="body2">
      * «Товар» - моделі, аксесуари, комплектуючі та супровідні предмети;
    </Typography>
    <Typography variant="body2">
      * «Інтернет-магазин» - відповідно до Закону України «про електронну комерцію», засіб для
      подання або реалізації товару, роботи або послуги шляхом здійснення електронної угоди.
    </Typography>
    <Typography variant="body2">
      * «Продавець» - компанія, яка реалізує товари, представлені на Інтернет-сайті.
    </Typography>
    <Typography variant="body2">
      * «Покупець» - фізична особа, що уклала з Продавцем Договір на умовах, викладених нижче.
    </Typography>
    <Typography variant="body2">
      * «Замовлення» - вибір окремих позицій з переліку товарів, зазначених Покупцем при розміщенні
      замовлення і проведенні оплати.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Предмет договору</Typography>
    <Typography variant="body2">
      3.1. Продавець зобов&apos;язується передати у власність Покупця Товар, а Покупець
      зобов&apos;язується оплатити і прийняти Товар на умовах даного Договору.
    </Typography>
    <Typography variant="body2">
      Цей Договір регулює купівлю-продаж товарів в Інтернет-магазині, в тому числі:
    </Typography>
    <Typography variant="body2">
      - добровільний вибір Покупцем товарів в Інтернет-магазині;
    </Typography>
    <Typography variant="body2">
      - самостійне оформлення Покупцем замовлення в Інтернет-магазині;
    </Typography>
    <Typography variant="body2">
      - оплата Покупцем замовлення, оформленого в Інтернет-магазині;
    </Typography>
    <Typography variant="body2">
      - обробка і доставка замовлення Покупцеві у власність на умовах цього Договору.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Порядок оформлення замовлення</Typography>
    <Typography variant="body2">
      4.1. Покупець має право оформити замовлення на будь-який товар, представлений на Сайті
      Інтернет-магазину і наявний.
    </Typography>
    <Typography variant="body2">
      4.2. Кожна позиція може бути представлена ​​в замовленні в будь-якій кількості.
    </Typography>
    <Typography variant="body2">
      4.3. При відсутності товару на складі, Менеджер компанії зобов&apos;язаний поставити Покупця
      до відома (по телефону або через електронну пошту).
    </Typography>
    <Typography variant="body2">
      4.4. При відсутності товару Покупець має право замінити його товаром аналогічної моделі,
      відмовитися від даного товару, анулювати замовлення.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Порядок оплати замовлення</Typography>
    <Typography variant="body2">Накладеним платежем</Typography>
    <Typography variant="body2">
      5.1. Оплата здійснюється за фактом отримання товару у відділенні транспортних компанії за
      готівковий розрахунок в гривнях.
    </Typography>
    <Typography variant="body2">
      5.2. При не надходження коштів Інтернет-магазин залишає за собою право анулювати замовлення.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Умови доставки замовлення</Typography>
    <Typography variant="body2">
      6.1. Доставка товарів, придбаних в Інтернет-магазині, здійснюється до складів транспортних
      компаній, де і здійснюється видача замовлень.
    </Typography>
    <Typography variant="body2">
      6.2. Разом із замовленням Покупцеві надаються документи згідно законодавства України.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Права та обов&apos;язки сторін</Typography>
    <Typography variant="body2">7.1. Продавець має право:</Typography>
    <Typography variant="body2">
      7.1. Продавець має право: - в односторонньому порядку припинити надання послуг за цим
      договором у разі порушення Покупцем умов цього договору.
    </Typography>
    <Typography variant="body2">7.2. Покупець зобов&apos;язаний:</Typography>
    <Typography variant="body2">
      - своєчасно оплатити та отримати замовлення на умовах цього договору.
    </Typography>
    <Typography variant="body2">7.3. Покупець має право:</Typography>
    <Typography variant="body2">- оформити замовлення в Інтернет-магазині;</Typography>
    <Typography variant="body2">- оформити електронний договір;</Typography>
    <Typography variant="body2">- вимагати від Продавця виконання умов цього Договору.</Typography>
  </Stack>
);
