/* eslint-disable */
import React from 'react';
import { Container, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';
import { SmallBanner } from '../../../../components/small-banner/small-banner.component';

export const BannersList = () => {
  const { data: bestsellerData } = useGetBlockWithBanners(blockTypes.best_seller_banner);

  const settings = {
    slidesToScroll: 1,
    slidesToShow: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          appendDots: (dots) => (
            <div className="slick-dots slick-dots__flex">
              <ul>{dots}</ul>
            </div>
          ),
          dotsClass: 'slick-dots slick-dots__flex',
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 6, xs: 2 },
        pt: 7.25,
        pb: { xs: 2, md: 0 },
        background: 'linear-gradient(0, #fff 48%, #E9EBFC 48%)',
      }}
    >
      <Typography variant="h5" component="h2" align="center" sx={{ mb: 4 }}>
        {bestsellerData.block.title}
      </Typography>
      <Slider {...settings} className="products-list">
        {bestsellerData.banners &&
          bestsellerData.banners.length > 0 &&
          bestsellerData.banners.map((item) => <SmallBanner banner={item} />)}
      </Slider>
    </Container>
  );
};
