import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useGetBlockWithBanners } from '../../../../services/blocks.service';
import { blockTypes } from '../../../../constants/blockTypes';
import { SmallBanner } from '../../../../components/small-banner/small-banner.component';
import { HalfColorBlock } from '../../../../components/half-color-block/half-color-block.component';

export function NewCollections() {
  const { data: selectionBlock } = useGetBlockWithBanners(blockTypes.selection_banner);

  return (
    <HalfColorBlock color="purple">
      <Typography variant="h5" component="h2" align="center" sx={{ mb: 5.5 }}>
        Новые коллекции
      </Typography>
      {selectionBlock.banners && selectionBlock.banners.length > 0 && (
        <Grid container direction="row" spacing={5}>
          <Grid item md={4} xs={12}>
            <SmallBanner banner={selectionBlock.banners[0]} />
          </Grid>
          <Grid item md={8} xs={12}>
            <SmallBanner banner={selectionBlock.banners[1]} double />
          </Grid>
        </Grid>
      )}
    </HalfColorBlock>
  );
}
