/* eslint-disable */
import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeartIcon } from '../../../../icons/heart.icon';
import { RulerIcon } from '../../../../icons/ruler.icon';
import { SizesModal } from '../modal/sizes-modal.component';
import { AvailabilityModal } from '../modal/availability-modal.component';
import { CartContext } from '../../../../utils/cart.context';
import { ProductChip } from '../../../../components/chip/chip.component';
import { DeliveryReturnBlocksComponent } from '../../../../components/delivery-return-blocks/delivery-return-blocks.component';
import { WishlistContext } from '../../../../utils/wishlist.context';

export function ProductDescription({ product }) {
  const { t } = useTranslation('translation');
  const [size, setSize] = React.useState(product.additionalinfo.size[0]);
  const [color, setColor] = React.useState(product.additionalinfo.colors[0]?.name || '');
  const { addItem, setShowCart } = useContext(CartContext);
  const { addItem: addSavedItem, inWishlist } = useContext(WishlistContext);
  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const [isSizeModalOpen, setModalSizeOpen] = React.useState(false);
  const [isAvailabilityModalOpen, setAvailabilityModalOpen] = React.useState(false);
  const handleModalSizeOpen = () => setModalSizeOpen(true);
  const handleModalSizeClose = () => setModalSizeOpen(false);
  const handleModalAvailabilityOpen = () => setAvailabilityModalOpen(true);
  const handleModalAvailabilityClose = () => setAvailabilityModalOpen(false);
  const heartIconStyles = {
    position: 'absolute',
    right: '0',
    bottom: '0',
  };

  return product ? (
    <Stack direction="column">
      <Stack direction="column" spacing={1} sx={{ mb: 3.25, position: 'relative' }}>
        <Typography variant="subtitle1" component="p">
          {product?.additionalinfo?.manufacturer}
        </Typography>
        <Typography variant="subHeadingMedium" component="p" sx={{ mt: 1 }}>
          {product.fullname}
        </Typography>
        <IconButton style={heartIconStyles} onClick={() => addSavedItem(product, size)}>
          <HeartIcon fill={inWishlist(product.UID) ? '#121212' : 'white'} />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mb: 4 }}>
        {product.firstPrice && (
          <Typography variant="crossedText" component="span">
            {product.firstPrice} грн.
          </Typography>
        )}
        <Typography variant="headingSmall" component="span">
          {product.price} грн
        </Typography>
        {product.discount && <ProductChip label={`-${product.discount}%`} />}
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Box sx={{ flex: '1 0' }}>
          <FormControl fullWidth>
            <InputLabel id="size-label" shrink={false}>
              {t('size')}
            </InputLabel>
            <Select
              labelId="size-label"
              value={size}
              placeholder={t('chooseSize')}
              onChange={handleSizeChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              {product.additionalinfo.size.map((item) => (
                <MenuItem value={item}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {product.additionalinfo.colors && product.additionalinfo.colors[0] !== null ? (
          <Box sx={{ minWidth: 173, flex: '1 0' }}>
            <FormControl fullWidth>
              <InputLabel id="color-label" shrink={false}>
                {t('color')}
              </InputLabel>
              <Select
                labelId="color-label"
                value=""
                onChange={handleColorChange}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Відповідовідно до фото</em>;
                  }

                  return selected.join(', ');
                }}
                displayEmpty
                disabled
              >
                {product.additionalinfo.colors.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <div />
        )}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mb: 4.75 }}>
        <RulerIcon />
        <Link className="underline" component="button" sx={{ mb: 4 }} onClick={handleModalSizeOpen}>
          {t('sizeTable')}
        </Link>
      </Stack>
      <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
        <Button
          variant="contained"
          sx={{ mt: 2, width: '100%', textTransform: 'uppercase' }}
          onClick={() => {
            addItem(product, size);
            setShowCart(true);
          }}
        >
          {t('addToCart')}
        </Button>
        <Button variant="outlined" sx={{ mt: 2, width: '100%', textTransform: 'uppercase' }}>
          {t('complementImage')}
        </Button>
      </Stack>
      <Link
        className="underline uppercase lato center"
        component="button"
        onClick={handleModalAvailabilityOpen}
      >
        {t('remnantsInShop')}
      </Link>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
        <div>
          <Typography variant="subtitle1" component="p" className="lato">
            {t('aboutProduct')}
          </Typography>
          <Typography variant="subtitle1" component="p" className="lato" sx={{ mt: 0.5 }}>
            {product.additionalinfo.collection}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" component="p" className="lato">
            Артикул
          </Typography>
          <Typography variant="subtitle1" component="p" className="lato" sx={{ mt: 0.5 }}>
            {product.vendorCode}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" component="p" className="lato">
            Бренд
          </Typography>
          <Typography variant="subtitle1" component="p" className="lato" sx={{ mt: 0.5 }}>
            {product.additionalinfo.manufacturer}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" component="p" className="lato">
            Сезон
          </Typography>
          <Typography variant="subtitle1" component="p" className="lato" sx={{ mt: 0.5 }}>
            {product.additionalinfo.season}
          </Typography>
        </div>
      </Stack>
      <DeliveryReturnBlocksComponent />
      <SizesModal show={isSizeModalOpen} handleClose={handleModalSizeClose} />
      <AvailabilityModal
        show={isAvailabilityModalOpen}
        handleClose={handleModalAvailabilityClose}
      />
    </Stack>
  ) : (
    <div />
  );
}

ProductDescription.propTypes = {
  product: PropTypes.object,
};
