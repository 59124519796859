/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { Autocomplete } from '@mui/lab';
import { settlementsService } from '../../../services/settlements.service';
import { deliveryMethods, paymentMethod } from '../order.const';
import { warehouseService } from '../../../services/warehouse.service';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../../utils/cart.context';
import { GTMAddPaymentInfo, GTMAddShippingInfo } from '../../../services/analytic-events';
import { InputField } from '../../../components/input/input-field';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+000 (00) 000-00-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const initialAddresses = [
  {
    Present: 'м. Київ, Київська обл.',
    Warehouses: 6037,
    MainDescription: 'Київ',
    Area: 'Київська',
    Region: '',
    SettlementTypeCode: 'м.',
    Ref: 'e718a680-4b33-11e4-ab6d-005056801329',
    DeliveryCity: '8d5a980d-391c-11dd-90d9-001a92567626',
    AddressDeliveryAllowed: true,
    StreetsAvailability: true,
    ParentRegionTypes: 'область',
    ParentRegionCode: 'обл.',
    RegionTypes: '',
    RegionTypesCode: '',
  },
  {
    Present: 'м. Харків, Харківська обл.',
    Warehouses: 1289,
    MainDescription: 'Харків',
    Area: 'Харківська',
    Region: '',
    SettlementTypeCode: 'м.',
    Ref: 'e71f8842-4b33-11e4-ab6d-005056801329',
    DeliveryCity: 'db5c88e0-391c-11dd-90d9-001a92567626',
    AddressDeliveryAllowed: true,
    StreetsAvailability: true,
    ParentRegionTypes: 'область',
    ParentRegionCode: 'обл.',
    RegionTypes: '',
    RegionTypesCode: '',
  },
  {
    Present: 'м. Дніпро, Дніпропетровська обл.',
    Warehouses: 2422,
    MainDescription: 'Дніпро',
    Area: 'Дніпропетровська',
    Region: '',
    SettlementTypeCode: 'м.',
    Ref: 'e717110a-4b33-11e4-ab6d-005056801329',
    DeliveryCity: 'db5c88f0-391c-11dd-90d9-001a92567626',
    AddressDeliveryAllowed: true,
    StreetsAvailability: true,
    ParentRegionTypes: 'область',
    ParentRegionCode: 'обл.',
    RegionTypes: '',
    RegionTypesCode: '',
  },
  {
    Present: 'м. Черкаси, Черкаська обл.',
    Warehouses: 309,
    MainDescription: 'Черкаси',
    Area: 'Черкаська',
    Region: '',
    SettlementTypeCode: 'м.',
    Ref: 'e71fe3ca-4b33-11e4-ab6d-005056801329',
    DeliveryCity: 'db5c8902-391c-11dd-90d9-001a92567626',
    AddressDeliveryAllowed: true,
    StreetsAvailability: true,
    ParentRegionTypes: 'область',
    ParentRegionCode: 'обл.',
    RegionTypes: '',
    RegionTypesCode: '',
  },
];

export const OrderForm = ({ formik }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderForm' });
  const { mutate, isInitialLoading, data, isLoading: isSettlementsLoading } = settlementsService();
  const {
    mutate: searchWarehouse,
    isInitialLoading: warehouseLoading,
    data: warehouseData,
    isLoading: isWarehouseLoading,
  } = warehouseService();
  const [city, setCity] = useState('');
  const { cart, totalPrice, totalCount } = useContext(CartContext);

  useEffect(() => {
    if (city.length >= 1) {
      mutate(city);
    }
  }, [city]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={3.75} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography variant="subtitle3" component="h2">
            {t('contactDetails')}*
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputField
            label={`${t('name')}*`}
            placeholder={t('namePlaceholder')}
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputField
            label={`${t('surname')}*`}
            placeholder={t('surnamePlaceholder')}
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputField
            label={`${t('number')}*`}
            placeholder={t('numberPlaceholder')}
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            inputComponent={TextMaskCustom}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputField
            label="Email*"
            id="email"
            name="email"
            type="text"
            placeholder={t('emailPlaceholder')}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 4.5 }} orientation="horizontal" flexItem />
      <Grid container rowSpacing={3.75} columnSpacing={5} sx={{ marginTop: 0.5 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle3" component="h2">
            {t('deliveryMethod')}*
          </Typography>
          <FormHelperText error={!!formik.errors.delivery}>{formik.errors.delivery}</FormHelperText>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="delivery"
                name="delivery"
                checked={formik.values.delivery === deliveryMethods.post}
                value={deliveryMethods.post}
                type="radio"
                onChange={(event, value) => {
                  GTMAddShippingInfo(event.target.value, totalPrice, cart);
                  formik.handleChange(event, value);
                }}
              />
            }
            label={t('deliveryNovaPoshta')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="delivery"
                name="delivery"
                checked={formik.values.delivery === deliveryMethods.courier}
                value={deliveryMethods.courier}
                type="radio"
                onChange={(event, value) => {
                  GTMAddShippingInfo(event.target.value, totalPrice, cart);
                  formik.handleChange(event, value);
                }}
              />
            }
            label={t('deliveryCourier')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="delivery"
                name="delivery"
                checked={formik.values.delivery === deliveryMethods.self}
                value={deliveryMethods.self}
                type="radio"
                onChange={(event, value) => {
                  GTMAddShippingInfo(event.target.value, totalPrice, cart);
                  formik.handleChange(event, value);
                }}
                disabled
              />
            }
            label={t('deliverySelf')}
          />
        </Grid>
      </Grid>
      {formik.values.delivery === deliveryMethods.courier && (
        <Grid container rowSpacing={3.75} columnSpacing={5} sx={{ marginTop: 0.5 }}>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <FormLabel id="name">Город*</FormLabel>
              <Autocomplete
                id="city"
                name="city"
                noOptionsText={t('noOptionsText')}
                getOptionLabel={(option) => option?.Present || option}
                options={
                  data?.data && data?.data[0]?.Addresses
                    ? data?.data[0]?.Addresses
                    : initialAddresses
                }
                loading={isSettlementsLoading}
                autoHighlight
                autoSelect
                onInputChange={(event, newInputValue) => {
                  setCity(newInputValue);
                }}
                onChange={(event, value) => {
                  formik.setFieldValue('city', value?.Present || '');
                }}
                renderInput={(params) => (
                  <OutlinedInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    {...params.InputProps}
                    sx={{ marginTop: 0.5 }}
                    placeholder={t('chooseCity')}
                    error={!!formik.errors.city}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                error={!!formik.errors.city}
                sx={{ position: 'absolute', top: '100%', mx: 0 }}
              >
                {formik.errors.city}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <InputField
              label={`${t('street')}*`}
              placeholder={t('streetPlaceholder')}
              id="street"
              name="street"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.street}
              error={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <InputField
              label={`${t('house')}*`}
              placeholder={t('housePlaceholder')}
              id="house"
              name="house"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.house}
              error={formik.touched.house && formik.errors.house}
            />
          </Grid>
        </Grid>
      )}
      {formik.values.delivery === deliveryMethods.post && (
        <Grid container rowSpacing={3.75} columnSpacing={5} sx={{ marginTop: 0.5 }}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <FormLabel id="name">{t('city')}*</FormLabel>
              <Autocomplete
                id="city"
                name="city"
                noOptionsText={t('noOptionsText')}
                optionsetOptionLabel={(option) => option.Present}
                getOptionLabel={(option) => option?.Present || option}
                options={
                  data?.data && data?.data[0]?.Addresses
                    ? data?.data[0]?.Addresses
                    : initialAddresses
                }
                loading={isInitialLoading || isSettlementsLoading}
                autoHighlight
                autoSelect
                onInputChange={(event, newInputValue) => {
                  setCity(newInputValue);
                }}
                onChange={(event, value) => {
                  formik.setFieldValue('city', value?.Present || '');
                  value?.MainDescription && searchWarehouse(value?.MainDescription);
                }}
                renderInput={(params) => (
                  <OutlinedInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    {...params.InputProps}
                    sx={{ marginTop: 0.5 }}
                    placeholder={t('chooseCity')}
                    error={!!formik.errors.city}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                error={!!formik.errors.city}
                sx={{ position: 'absolute', top: '100%', mx: 0 }}
              >
                {formik.errors.city}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <FormLabel id="name">{t('warehouseNumber')}*</FormLabel>
              <Autocomplete
                id="warehouse"
                name="warehouse"
                noOptionsText={t('noOptionsText')}
                getOptionLabel={(option) => option.Description}
                options={warehouseData?.data || []}
                loading={warehouseLoading || isWarehouseLoading}
                onChange={(event, value) => formik.setFieldValue('warehouse', value?.Description)}
                renderInput={(params) => (
                  <OutlinedInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    {...params.InputProps}
                    sx={{ marginTop: 0.5 }}
                    placeholder={t('chooseWarehouse')}
                    error={!!formik.errors.warehouse}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                error={!!formik.errors.warehouse}
                sx={{ position: 'absolute', top: '100%', mx: 0 }}
              >
                {formik.errors.warehouse}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ marginTop: 4 }} orientation="horizontal" flexItem />
      <Grid container rowSpacing={3.75} columnSpacing={5} sx={{ marginTop: 0.5 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle3" component="h2">
            {t('paymentMethod')}*
          </Typography>
          <FormHelperText error={!!formik.errors.payment}>{formik.errors.payment}</FormHelperText>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="payment"
                name="payment"
                checked={formik.values.payment === paymentMethod.self}
                value={paymentMethod.self}
                type="radio"
                onChange={(event, value) => {
                  GTMAddPaymentInfo(event.target.value, totalPrice, cart);
                  formik.handleChange(event, value);
                }}
              />
            }
            label={t('paymentAfterDelivery')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="payment"
                name="payment"
                checked={formik.values.payment === paymentMethod.card}
                value={paymentMethod.card}
                type="radio"
                onChange={(event, value) => {
                  GTMAddPaymentInfo(event.target.value, totalPrice, cart);
                  formik.handleChange(event, value);
                }}
              />
            }
            label={t('paymentCard')}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 4 }} orientation="horizontal" flexItem />
      <Grid container rowSpacing={3.75} columnSpacing={5} sx={{ marginTop: 0.5 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle3" component="h2">
            {t('comment')}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputField
            minRows={5}
            placeholder={t('addComment')}
            id="comment"
            name="comment"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.comment}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="noCall"
                name="noCall"
                checked={formik.values.noCall.includes('noCall') ? true : false}
                value={'noCall'}
                type="checkbox"
                onChange={formik.handleChange}
              />
            }
            label={t('noReCall')}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 4 }} orientation="horizontal" flexItem />
    </form>
  );
};
