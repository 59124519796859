import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack } from '@mui/material';
import { BrandComponent } from './brand.component';
import { useFilterList } from '../../services/filter-list.service';
import { useGetBrand } from '../../services/brand.service';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';
import { generatePathWithParameters, nameToURL } from '../../utils/friendlyURl';
import { BRAND, CATEGORY_CATALOG } from '../../constants/routes';

export function BrandContainer() {
  const { t } = useTranslation('translation');
  const { data: filtersData } = useFilterList();
  const { data } = useGetBrand();
  const navigate = useNavigate();
  const { setFilters, setCurrentUrl, filterValues } = useContext(CategoryFiltersContext);

  const currentBrand = useMemo(
    () => filterValues?.brand && data?.find((item) => item.GUID === filterValues?.brand[0]?.GUID),
    [data, filterValues]
  );

  const toCatalog = () =>
    Object.keys(filterValues).length > 0 &&
    navigate({
      pathname: generatePathWithParameters(CATEGORY_CATALOG.path, {
        brand: nameToURL(filterValues.brand[0].Name),
      }),
    });

  const filters = useMemo(
    () => [
      { label: t('brand'), key: 'brand', options: filtersData?.brand || [], hidden: true },
      { label: t('gender'), key: 'sex', options: filtersData?.sex || [] },
      {
        label: t('size'),
        key: 'size',
        options: [{ Name: '30' }, { Name: '31' }, { Name: '32' }, { Name: '33' }],
      },
      { label: t('color'), key: 'color', options: filtersData?.color || [] },
      { label: t('season'), key: 'season', options: filtersData?.season || [] },
    ],
    [filtersData]
  );

  useEffect(() => {
    setCurrentUrl(BRAND.path);
  }, []);

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  return currentBrand ? (
    <BrandComponent brand={currentBrand} toCatalog={toCatalog} />
  ) : (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '600px' }}>
      <CircularProgress />
    </Stack>
  );
}
