import React from 'react';
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ContactUs = () => {
  const { t } = useTranslation('translation');
  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 25.75, xs: 3 },
        pt: { md: 18.25, xs: 16.25 },
        pb: { md: 14.5, xs: 5 },
        background: '#282932',
      }}
    >
      <Stack
        direction="column"
        sx={{ alignItems: 'center', maxWidth: '642px', textAlign: 'center', margin: 'auto' }}
      >
        <Typography
          variant="p"
          component="p"
          sx={{ mt: 3, color: 'secondary.main', width: { md: '388px', xs: 'auto' } }}
        >
          {t('aboutUsDescription')}
        </Typography>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={{ md: 3, xs: 6 }}
          alignItems={{ md: 'flex-end', xs: 'stretch' }}
          sx={{ mt: { md: 7, xs: 4 }, width: '100%' }}
        >
          <TextField
            id="standard-basic"
            variant="standard"
            color="secondary"
            placeholder={t('name')}
            sx={{ width: { md: '366px', xs: '100%' } }}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            color="secondary"
            placeholder={t('number')}
            sx={{ width: { md: '366px', xs: '100%' } }}
          />
          <Button color="secondary" variant="contained" sx={{ flex: '1 0 auto' }}>
            {t('aboutUsButton')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
