/* eslint-disable */
import React, { useState } from 'react';
import { Stack, Typography, Collapse, Divider } from '@mui/material';
import plusAccordion from '../../../assets/images/plus-accordion.svg';

const FAQ = [
  {
    header: 'Як часто оновлюється асортимент товарів в інтернет-магазині ТМ To the nines?',
    body: 'Асортимент в інтернет-магазині оновлюється щомісяця. Особливість ТМ To the nines - випуск 12 колекцій на рік. To the nines створює невимушену одяг для роботи і відпочинку, і в той же час слід останнім тенденціям моди. Щоб першими дізнаватися про надходження нових колекцій, акції та пропозиції - ви можете зареєструватися на сайті або просто підписатися на нашу розсилку.',
  },
  {
    header:
      'Чи відрізняються ціни вказані в інтернет-магазині від цін на аналогічні товари в мережі магазинів ТМ To the nines?',
    body: 'Ні. Цінова політика є єдиною і для мережі магазинів ТМ To the nines, і для нашого інтернет магазину. Різниця може проявлятися тільки в акції та спеціальні пропозиції доступних для користувачів, що зареєструвалися на нашому сайті.',
  },
  {
    header: 'Чи обов`язково мені реєструватися для здійснення замовлення?',
    body: 'Ні, ви можете зробити швидкий замовлення, вказавши тільки номер свого мобільного телефону, щоб наш менеджер міг з вами зв`язатися.',
  },
  {
    header: 'Навіщо мені реєструватися?',
    body: 'Реєстрація дає вам більше можливостей: ви можете відстежувати свою історію покупок, брати участь в програмі лояльності для зареєстрованих користувачів, акціях і т.д. Так само реєстрація дозволить економити ваш час, так як не потрібно буде щоразу вводити свої дані при оформленні замовлення, окрім того ви будете першими дізнаватися про надходження нових колекцій, розпродажах і інших цікавих пропозиціях від ТМ To the nines.',
  },
  {
    header: 'Як зареєструватися на сайті?',
    body: 'Вам достатньо натиснути на особистий кабінет в правому верхньому куті сайту і вибрати у випадаючому меню пункт реєстрація. Після чого вам буде запропоновано заповнити невелику анкету.',
  },
  {
    header: 'Що ви робите з особистими даними користувачів?',
    body: 'Вся інформація, отримана нами від користувачів, є конфіденційною і використовується тільки нашою компанією.',
  },
  {
    header: 'Як зробити замовлення?',
    body: 'Використовуючи засоби навігації по сайту, додайте в корзину ваші товари, попередньо вказавши їх розмір колір і кількість. В кошику, ви зможете швидко сформувати замовлення, вказавши відповідні для вас способи оплати та доставки, після чого з вами зв`яжеться наш менеджер для підтвердження замовлення і уточнення додаткових деталей.',
  },
  {
    header: 'Чи можна мені скасувати замовлення?',
    body: 'Так. Ви можете скасувати замовлення, зателефонувавши нашому менеджеру за номером або надіславши електронного листа на адресу. Скасування замовлення можливо тільки до відправки товару в службу доставки. В іншому випадку, вам необхідно буде повертати замовлений товар. Для цього заповніть спеціальну форму для повернення / обміну товару, яка знаходиться у нас на сайті, після чого потрібно буде відправити товар назад. У разі передоплати гроші надійдуть на ваш рахунок в 14 днів.',
  },
  {
    header: 'Чи можна повернути або обміняти речі, якщо розмір, колір або фасон вам не підійшли?',
    body: 'Так звісно. Якщо товар з тієї чи іншої причини вам не підійшов ви можете повернути його протягом 14 днів з моменту отримання товару за умови, що речі зберегли товарний вигляд, всі ярлики бирки залишилися незачепленими і у вас залишилися документи підтверджують факт покупки. Для повернення вам необхідно заповнити форму в розділі повернення / обмін у нас на сайті і відправити товар нам назад.',
  },
  {
    header: 'Чи є можливість приміряти товар до здійснення покупки?',
    body: 'Така можливість є, якщо даний товар знаходиться в магазині того ж міста в якому й ви. Перелік магазинів, в яких доступна та чи інша річ, ви можете переглянути на картці товару.',
  },
  {
    header: 'Як відбувається доставка товару?',
    body: "На даний момент здійснюється за допомогою кур'єрської служби Нова Пошта. Доставка відбувається протягом 5-7ми робочих днів після підтвердження замовлення нашим менеджером.",
  },
  {
    header: 'Скільки коштує доставка?',
    body: 'Доставка по Україні є безкоштовною на замовлення від 3500 грн.',
  },
  {
    header: 'Як оплатити замовлення?',
    body: 'Здійснити оплату можна післяплатою при отриманні замовлення у відділенні Нової Пошти. Також можливий безготівковий розрахунок шляхом перерахування грошових коштів на банківську карту. В цьому випадку відправка товару буде проводитися тільки після підтвердження надходження грошей на наш рахунок.',
  },
];

export const FaqComponent = () => {
  const [open, setOpen] = useState();

  return (
    <Stack direction="column">
      <Typography variant="headingDesktopSmall">Питання та відповіді</Typography>
      <Divider sx={{ marginTop: 3.25, marginBottom: 2.75 }} />
      {FAQ.map(({ header, body }, index) => (
        <>
          <Stack direction="row" spacing={1.5}>
            <div>
              <img src={plusAccordion} alt="" />
            </div>
            <Typography
              onClick={() => setOpen(index === undefined || open !== index ? index : undefined)}
              variant="body2"
              className="dark-grey"
              sx={{ cursor: 'pointer' }}
            >
              {header}
            </Typography>
          </Stack>
          <Collapse in={open === index} timeout="auto" unmountOnExit>
            <Typography variant="body2" sx={{ marginTop: 2, paddingLeft: 4 }}>
              {body}
            </Typography>
          </Collapse>
          <Divider sx={{ marginY: 2.5 }} />
        </>
      ))}
    </Stack>
  );
};
