/* eslint-disable */
import { useEffect, useState } from 'react';
import markerIcon from '../../assets/images/marker.svg';

export const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker({ icon: markerIcon }));
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, []);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
