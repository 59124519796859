export default function themeTypography(theme) {
  return {
    fontFamily: `Montserrat, Tahoma, Sans-Serif`,
    title1: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '29px',
      color: theme.colors?.darkPrimaryMain,
      '&.bold': {
        fontWeight: 600,
      },
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '30px',
      color: theme.colors?.darkPrimaryMain,
      '&.lato': {
        fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      },
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      color: theme.colors?.primary200,
      '&.lato': {
        fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      },
    },
    subtitle3: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      color: theme.colors?.darkPrimaryMain,
      '&.bold': {
        fontWeight: 600,
      },
    },
    p: {
      fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      fontSize: '16px',
      lineHeight: '28px',
      '& a': {
        textDecoration: 'underline',
      },
      '&.montserrat': {
        fontFamily: `Montserrat, Tahoma, Sans-Serif`,
        fontWeight: '500',
      },
      '&.dark': {
        color: theme.colors?.darkPrimaryMain,
      },
      '&.bold': {
        fontWeight: 600,
      },
    },
    textLink: {
      fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      fontSize: '13px',
      lineHeight: '24px',
      fontWeight: '400',
      color: theme.colors?.primary200,
      cursor: 'pointer',
      '&.dark': {
        color: theme.colors?.darkPrimaryMain,
      },
    },
    buttons: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      textTransform: 'uppercase',
      color: theme.colors?.primary200,
    },
    label12Medium: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      color: theme.colors?.primary200,
    },
    label13: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.colors?.darkPrimaryMain,
    },
    label13Gray: {
      fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      fontSize: '13px',
      lineHeight: '24px',
      fontWeight: 400,
      color: theme.colors?.primary200,
    },
    label14Semibold: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      color: theme.colors?.darkPrimaryMain,
    },
    // subHeadingMedium: {
    //   fontSize: '18px',
    //   lineHeight: '30px',
    //   fontWeight: 500,
    //   marginTop: '5px',
    // },
    headingSmall: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      '&.semi-bold': {
        fontWeight: 600,
      },
      '&.dark': {
        color: theme.colors?.darkPrimaryMain,
      },
    },
    crossedText: {
      textDecoration: 'line-through',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '28px',
      color: theme.colors?.primary200,
    },
    // underlinedText: {
    //   textDecoration: 'underline',
    //   fontSize: '12px',
    //   fontWeight: 500,
    //   lineHeight: '14px',
    //   textTransform: 'uppercase',
    //   textAlign: 'center',
    // },
    h1: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      color: theme.colors?.darkPrimaryMain,
    },
    h4: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 400,
      fontSize: '36px',
      color: theme.colors?.darkPrimaryMain,
      '&.white': {
        color: '#fff',
      },
    },
    h5: {
      color: theme.colors?.darkPrimaryMain,
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 500,
    },
    h6: {
      color: theme.colors?.darkPrimaryMain,
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 500,
    },
    body1: {
      fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      fontSize: '14px',
      color: theme.colors?.darkPrimaryMain,
      '&.gray': {
        color: '#4F4F4F',
      },
      '&.montserrat': {
        fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      },
      '&.regular': {
        fontWeight: 400,
      },
      '&.medium': {
        fontWeight: 500,
      },
      '&.semi-bold': {
        fontWeight: 600,
      },
    },
    body2: {
      fontFamily: `'Lato', 'Lucida Grande', Tahoma, Sans-Serif`,
      lineHeight: '1.8em',
      color: theme.colors?.primary200,
      '&.dark-grey': {
        color: theme.colors?.darkPrimaryMain,
      },
    },
    subheadingMedium: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.colors?.darkPrimaryMain,
    },
    subheadingLMedium: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '26px',
      color: theme.colors?.darkPrimaryMain,
    },
    headingDesktopSmall: {
      fontFamily: `Montserrat, Tahoma, Sans-Serif`,
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
    },
  };
}
