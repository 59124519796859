import React from 'react';
import { Chip, styled } from '@mui/material';
import * as PropTypes from 'prop-types';

const SaleChip = styled(Chip)((theme) => ({
  color: theme.colors?.primaryRed,
  fontSize: '14px',
  lineHeight: '18px',
  backgroundColor: 'transparent',
  borderRadius: '0',
  border: `1px solid ${theme.colors?.primaryRed}`,
  height: '25px',
}));

export function ProductChip({ label }) {
  return <SaleChip label={label} />;
}

ProductChip.propTypes = {
  label: PropTypes.string,
};
