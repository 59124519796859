import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CatalogContainer } from '../../components/catalog/catalog.container';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';

export function SearchCatalog() {
  const { t } = useTranslation('translation');
  const [searchParams] = useSearchParams();
  const menu = useMemo(() => JSON.parse(searchParams.get('menu')), [searchParams]);
  const searchField = useMemo(() => JSON.parse(searchParams.get('vendorCode')), [searchParams]);
  return (
    <PageWrapper>
      <CatalogContainer title={`${t('findResult')} "${menu?.Name || searchField}"`} />
    </PageWrapper>
  );
}
