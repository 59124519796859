import { useMutation } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useGetRemnants() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.post(`RemnantsNomenclature/`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}
