/* eslint-disable */
import React from 'react';
import { PageWrapper } from '../page-wrapper/page-wrapper.component';
import { Button, Container, Stack, Typography } from '@mui/material';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageWrapper main>
          <Container maxWidth="1440" disableGutters sx={{ margin: '150px 0' }}>
            <Stack spacing={5} direction="column" alignItems="center">
              <Typography
                variant="subheadingLMedium"
                sx={{ fontSize: '151px', lineHeight: '240px' }}
              >
                404
              </Typography>
              <Typography variant="subheadingLMedium">{t('errorMessage')}</Typography>
              <Stack spacing={3} direction="row" alignItems="center">
                <Button variant="contained" onClick={window.location.reload}>
                  {t('updateButton')}
                </Button>
                <Button href="/" variant="outlined">
                  {t('toMainButton')}
                </Button>
              </Stack>
            </Stack>
          </Container>
        </PageWrapper>
      );
    }

    return this.props.children;
  }
}
