import React from 'react';
import { Container } from '@mui/material';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { SavedProductsComponent } from './saved-products.component';

export function SavedProductsContainer() {
  return (
    <PageWrapper main>
      <Container maxWidth="1440" disableGutters sx={{ mt: 7, mb: 11 }}>
        <SavedProductsComponent />
      </Container>
    </PageWrapper>
  );
}
