import React, { useEffect, useMemo } from 'react';
import { Container, Grid, Skeleton } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Banner } from '../../components/banner/banner.component';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { useCollectionService } from '../../services/collection.service';
import { mainRoutes } from '../../constants/routes';
import { useFilterList } from '../../services/filter-list.service';
import { ProductListContainer } from '../../components/product-list/product-list.container';

export function Collection() {
  const { mutate, data, isSuccess } = useCollectionService();
  const { data: filtersData } = useFilterList();
  const collections = useMemo(
    () =>
      data?.slice(0, 4).map((item) => ({
        GUID: filtersData?.collection.find(({ Name }) => Name === item.name).GUID,
        ...item,
      })),
    [data, filtersData]
  );
  const navigate = useNavigate();

  const toAll = (name) => {
    navigate({
      pathname: mainRoutes[8].path,
      search: `?${createSearchParams({ collection: JSON.stringify([{ Name: name }]) })}`,
    });
  };

  useEffect(() => {
    mutate();
  }, []);

  return (
    <PageWrapper>
      {isSuccess ? (
        Array.isArray(collections) &&
        collections.map((item) => (
          <>
            <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 0 } }}>
              <Banner title={item.name} toAll={() => toAll(item.name)} />
            </Container>
            <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 2 }, mb: 5.5 }}>
              <ProductListContainer
                preview
                fromKey="Collections"
                filter={{
                  collection: [
                    {
                      Name: item.name,
                      GUID: item.GUID,
                    },
                  ],
                }}
                maxCount={4}
                toAll={() => toAll(item.name)}
              />
            </Container>
          </>
        ))
      ) : (
        <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 2 }, mb: 5.5 }}>
          <Skeleton variant="rectangular" width="100%" height={400} sx={{ marginBottom: '40px' }} />
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width="100%" height={416} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width="100%" height={416} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width="100%" height={416} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width="100%" height={416} />
            </Grid>
          </Grid>
        </Container>
      )}
    </PageWrapper>
  );
}
