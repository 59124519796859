/* eslint-disable */
import { Box, Button, IconButton, Modal, Snackbar, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InputField } from '../../../components/input/input-field';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useChangeEmail, useChangePhone } from '../../../services/profile.service';
import { Alert } from '@mui/lab';
import { useNotification } from '../../../utils/notification.context';

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
});

const ChangePhoneScheme = Yup.object().shape({
  phone: Yup.string().required(),
  confirmPhone: Yup.string()
    .required()
    .oneOf([Yup.ref('phone'), null], "Phone don't match"),
});

export function ChangePhoneModal({ open, handleClose }) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { mutate, isSuccess, data } = useChangePhone();
  const { addNotification } = useNotification();

  const formik = useFormik({
    initialValues: {
      phone: '',
      confirmPhone: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ChangePhoneScheme,
    onSubmit: (values) => {
      mutate({ Phone: values.phone });
    },
  });

  useEffect(() => {
    if (data) {
      if (isSuccess) {
        addNotification(t('phoneChangedSuccess'), 'success');
      } else {
        addNotification(t('phoneChangedError'), 'error');
      }
      handleClose();
    }
  }, [isSuccess, data]);

  return (
    <StyledModal open={open} onClose={handleClose} BackdropComponent={Backdrop}>
      <Box sx={style('440px')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="headingSmall" component="p">
            {t('changePhoneTitle')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} mt={3}>
            <InputField
              label={t('newPhone')}
              placeholder={t('newPhonePlaceholder')}
              id="phone"
              name="phone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone}
            />
            <InputField
              label={t('confirmPhone')}
              placeholder={t('confirmPhonePlaceholder')}
              id="confirmPhone"
              name="confirmPhone"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.confirmPhone}
              error={formik.touched.confirmPhone && formik.errors.confirmPhone}
            />
            <Button
              variant="contained"
              sx={{ mt: 2, width: '100%' }}
              onClick={() => formik.submitForm()}
            >
              {t('save')}
            </Button>
          </Stack>
        </form>
      </Box>
    </StyledModal>
  );
}
