import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { NavArrowPrev } from '../../../../icons/nav-arrow-prev';

export function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <Box className={className} onKeyPress={onClick} onClick={onClick} role="button" tabIndex={0}>
      <NavArrowPrev
        sx={{ transform: 'rotate(180deg)', position: 'absolute', right: { md: 'auto', xs: 0 } }}
      />
    </Box>
  );
}

export function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <Box className={className} onKeyPress={onClick} onClick={onClick} role="button" tabIndex={0}>
      <NavArrowPrev sx={{ position: 'absolute', left: { xs: '100%', md: 'auto' } }} />
    </Box>
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
