import React, { useContext } from 'react';
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../utils/cart.context';
import { HeartIcon } from '../../icons/heart.icon';
import { CancelIcon } from '../../icons/cancel.icon';

export const PreviewCartItem = ({
  mainPhoto,
  brand,
  name,
  size,
  color,
  price,
  UID,
  count,
  actionButton,
  imageSx,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation('translation');
  const { removeItem } = useContext(CartContext);

  return (
    <Stack direction="row" sx={{ position: 'relative' }}>
      <Box
        sx={{
          width: '128px',
          height: '179px',
          flex: '0 1 auto',
          ...imageSx,
          background: `url("${mainPhoto.url}") no-repeat center center`,
          backgroundSize: 'contain',
          backgroundOrigin: 'border-box',
        }}
      />
      <Stack direction="column" sx={{ marginLeft: 3 }}>
        <Typography
          variant="body1"
          className="gray montserrat medium"
          sx={{ marginRight: { md: 0, xs: 3 } }}
        >
          {brand}
        </Typography>
        <Typography variant="subtitle3" sx={{ marginTop: 1 }}>
          {name}
        </Typography>
        <Stack
          direction="row"
          sx={{ marginTop: 2 }}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Stack direction="row">
            {isDesktop && (
              <Typography variant="body1" className="gray">
                {t('size')}:
              </Typography>
            )}
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: { md: 1, xs: 0 } }}>
              {size.name}
            </Typography>
          </Stack>
          <Stack direction="row">
            {isDesktop && (
              <Typography variant="body1" className="gray">
                {t('color')}:
              </Typography>
            )}
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: { md: 1, xs: 0 } }}>
              {color}
            </Typography>
          </Stack>
          <Stack direction="row">
            {isDesktop && (
              <Typography variant="body1" className="gray">
                {t('count')}:
              </Typography>
            )}
            <Typography variant="body1" className="semi-bold" sx={{ marginLeft: { md: 1, xs: 0 } }}>
              {count}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="subtitle3" className="bold" sx={{ marginTop: 'auto' }}>
          {price} грн
        </Typography>
        {actionButton && (
          <Stack
            sx={{ marginTop: { md: 'auto', xs: 0 } }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {isDesktop && (
              <Stack sx={{ cursor: 'pointer' }} direction="row" alignItems="center">
                <Typography variant="body1">{t('favorites')}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 1.25,
                  }}
                >
                  <HeartIcon
                    sx={{
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </Box>
              </Stack>
            )}
            <Stack
              sx={{
                cursor: 'pointer',
                position: { xs: 'absolute', md: 'static' },
                top: 0,
                right: 0,
              }}
              direction="row"
              alignItems="center"
              onClick={() => removeItem(UID, size)}
            >
              {isDesktop && <Typography variant="body1"> {t('remove')}</Typography>}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 1.25,
                }}
              >
                <CancelIcon
                  sx={{
                    width: { xs: '24px', md: '14px' },
                    height: { xs: '24px', md: '14px' },
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

PreviewCartItem.propTypes = {
  mainPhoto: PropTypes.string,
  brand: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  size: PropTypes.object,
  color: PropTypes.string,
  UID: PropTypes.string,
  count: PropTypes.number,
  actionButton: PropTypes.bool,
  imageSx: PropTypes.object,
};
