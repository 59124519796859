import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { xor } from 'lodash/array';
import DoneIcon from '@mui/icons-material/Done';
import { KeyboardBackspace } from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import { CloseIcon } from '../../../icons/close.icon';

export function MobileSublist({ filter, value, onClose, onChange, onSave }) {
  const { t } = useTranslation('translation');

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        maxHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
        <IconButton onClick={onClose}>
          <KeyboardBackspace sx={{ color: '#121212' }} />
        </IconButton>
        <Typography variant="subtitle3" className="bold" component="div">
          {filter.label}
        </Typography>
        <IconButton sx={{ margin: 0 }} onClick={onClose}>
          <CloseIcon sx={{ color: '#121212' }} />
        </IconButton>
      </Stack>
      <List
        component="nav"
        aria-label="main-menu"
        sx={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden', p: 2 }}
      >
        {filter.options.map((item) => (
          <>
            <Divider />
            <ListItem
              disableGutters
              component="div"
              onClick={() =>
                onChange(
                  filter,
                  xor(
                    value.map(({ Name }) => Name),
                    [item.Name]
                  )
                )
              }
              secondaryAction={
                value.find((option) => option.Name === item.Name) && (
                  <IconButton edge="end">
                    <DoneIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText primary={item.Name} />
            </ListItem>
          </>
        ))}
        <Divider />
      </List>
      <Stack sx={{ p: 2 }}>
        <Button variant="contained" color="primary" sx={{ width: '100%' }} onClick={onSave}>
          {t('apply')}
        </Button>
        <Button color="error" sx={{ mt: 2, width: '100%' }} onClick={onClose}>
          {t('cancel')}
        </Button>
      </Stack>
      /
    </Box>
  );
}

MobileSublist.propTypes = {
  filter: PropTypes.object,
  value: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};
