import React from 'react';
import { Container, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

export function HalfColorBlock({ children, title, color }) {
  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        mb: 12,
        px: { md: 6, xs: 3 },
        pt: 5.5,
        background: `linear-gradient(0, #fff 49%, ${
          color === 'purple' ? '#E9EBFC' : '#EEE0D8'
        } 49%)`,
      }}
    >
      {title && (
        <Typography variant="h5" component="h2" align="center" sx={{ mb: 5.5 }}>
          {title}
        </Typography>
      )}
      {children}
    </Container>
  );
}

HalfColorBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.oneOf(['purple', 'beige']),
};
