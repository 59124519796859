import {
  Box,
  IconButton,
  Modal,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
  maxHeight: '100vh',
  overflow: 'auto',
});

function createData(ukraine, global, germany, usa, england, italy) {
  return { ukraine, global, germany, usa, england, italy };
}

const womenClothes = [
  createData('40', 'XS', 34, '-', '-'),
  createData('42', 'S', 36, 8, '-'),
  createData('44', 'M', 38, 10, 8),
  createData('46', 'M', 40, 12, 10),
  createData('48', 'L', 42, 14, 12),
  createData('50', 'L', 44, 16, 14),
  createData('52', 'XL', 46, 18, 16),
  createData('54', 'XXL', 48, 20, 18),
  createData('58', 'XXL', 50, 22, '-'),
  createData('58', 'XXXL', 52, '-', '-'),
];

const womenPants = [
  createData('42', 'XS'),
  createData('44-46', 'S'),
  createData('46', 'M'),
  createData('46-48', 'M'),
  createData('48', 'L'),
  createData('48-50', 'XL'),
  createData('50', 'XXL'),
  createData('52', 'XXXL'),
  createData('54', 'XXXXL'),
  createData('56', 'XXXXL'),
];

const womenFootwear = [
  createData('34', '-', '-', 4, 2.5, '-'),
  createData('35', '-', '-', 4.5, 3, 36),
  createData('36', '-', '-', 5, 4, 36),
  createData('37', '-', '-', 5.5, 5, 37),
  createData('38', '-', '-', 6, 5.5, '-'),
  createData('39', '-', '-', 7, 6.5, '38'),
  createData('40', '-', '-', 8, 7, '39'),
  createData('41', '-', '-', 9, 8, '40'),
];

const manClothes = [
  createData('46', '-', '-', '36/S', '-', 46),
  createData('48', '-', '-', '38/M', '38/40', 48),
  createData('50', '-', '-', '40/M', '40/42', 50),
  createData('52', '-', '-', '42/L', '42/44', 52),
  createData('54', '-', '-', '44/L', '44/46', 54),
  createData('56', '-', '-', '46/XL', '46/48', 56),
  createData('58', '-', '-', '48/XL', '48/50', 58),
  createData('60', '-', '-', '50/XXL', '-', 60),
];

const manPants = [
  createData('42', 'XS'),
  createData('44', 'S'),
  createData('46', 'M'),
  createData('48', 'L'),
  createData('50', 'XL'),
  createData('52', 'XXL'),
  createData('54', 'XXXL'),
  createData('56', 'XXXXL'),
];

const manFootwear = [
  createData('38', '-', '-', 6, '-', 38),
  createData('38.5', '-', '-', 6.5, 6, 39),
  createData('39', '-', '-', 7, 6.5, 40),
  createData('40', '-', '-', 7.5, 7, 42),
  createData('41', '-', '-', 8.5, 8, 44),
  createData('42', '-', '-', 9, 9, 45),
  createData('43', '-', '-', 10, 9.5, 56),
  createData('44', '-', '-', 11, 10.5, 47),
  createData('45', '-', '-', 12, 11.5, '-'),
];

export function SizesModal({ show, handleClose }) {
  const { t } = useTranslation('translation');
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledModal open={show} onClose={handleClose} BackdropComponent={Backdrop}>
      <Box sx={style('900px')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="headingSmall" component="p">
            {t('sizeCells')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" sx={{ mt: 3 }}>
          <Box sx={{ mt: 5, borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t('womenClothes')} {...a11yProps(0)} sx={{ margin: '0px 15px' }} />
              <Tab label={t('womenPants')} {...a11yProps(1)} sx={{ margin: '0px 15px' }} />
              <Tab label={t('womenFootwear')} {...a11yProps(2)} sx={{ margin: '0px 15px' }} />
              <Tab label={t('manClothes')} {...a11yProps(3)} sx={{ margin: '0px 15px' }} />
              <Tab label={t('manPants')} {...a11yProps(4)} sx={{ margin: '0px 15px' }} />
              <Tab label={t('manFootwear')} {...a11yProps(5)} sx={{ margin: '0px 15px' }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesInternational')}</TableCell>
                    <TableCell variant="head">{t('sizesGermany')}</TableCell>
                    <TableCell variant="head">{t('sizesUSA')}</TableCell>
                    <TableCell variant="head">{t('sizesEngland')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {womenClothes.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.global}</TableCell>
                      <TableCell>{row.germany}</TableCell>
                      <TableCell>{row.usa}</TableCell>
                      <TableCell>{row.england}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesInternational')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {womenPants.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.global}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesUSA')}</TableCell>
                    <TableCell variant="head">{t('sizesEngland')}</TableCell>
                    <TableCell variant="head">{t('sizesItaly')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {womenFootwear.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.usa}</TableCell>
                      <TableCell>{row.england}</TableCell>
                      <TableCell>{row.italy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesUSA')}</TableCell>
                    <TableCell variant="head">{t('sizesEurope')}</TableCell>
                    <TableCell variant="head">{t('sizesEngland')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manClothes.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.usa}</TableCell>
                      <TableCell>{row.italy}</TableCell>
                      <TableCell>{row.england}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesInternational')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manPants.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.global}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell variant="head">{t('sizesUkraine')}</TableCell>
                    <TableCell variant="head">{t('sizesUSA')}</TableCell>
                    <TableCell variant="head">{t('sizesEngland')}</TableCell>
                    <TableCell variant="head">{t('sizesItaly')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manFootwear.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.size}
                      </TableCell>
                      <TableCell>{row.ukraine}</TableCell>
                      <TableCell>{row.usa}</TableCell>
                      <TableCell>{row.england}</TableCell>
                      <TableCell>{row.italy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Stack>
      </Box>
    </StyledModal>
  );
}

SizesModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.bool,
};
