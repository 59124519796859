export const selectMenuProps = (width) => ({
  disablePortal: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: 364,
      width,
    },
  },
});
