import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  IconButton,
  OutlinedInput,
  InputLabel,
  Modal,
  Stack,
  styled,
  Typography,
  Rating,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAddComment } from '../../../../services/comments.service';

const StyledModal = styled(Modal)(() => ({
  position: 'fixed',
  zIndex: 99999,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(64, 65, 78, 0.5);
`;

const style = (width) => ({
  zIndex: 999999,
  maxWidth: width,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '2px',
  outline: 'none',
  p: 4,
});

export function AddCommentModal({ show, handleClose }) {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState('');
  const { mutate, isInitialLoading, isSuccess } = useAddComment();

  useEffect(() => {
    const timer1 = setTimeout(() => isSuccess && handleClose(true), 5000);

    return () => {
      clearTimeout(timer1);
    };
  }, [isSuccess]);

  return (
    <StyledModal open={show} onClose={handleClose} BackdropComponent={Backdrop}>
      <Box sx={style('874px')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="headingSmall" component="p">
            {t('leaveFeedback')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" sx={{ mt: 3 }}>
          <FormControl color="primary" variant="filled" fullWidth>
            <InputLabel id="name" shrink={false}>
              {t('rating')}
            </InputLabel>
            <Stack direction="row">
              <Rating
                size="medium"
                value={rating}
                onChange={(e, newValue) => setRating(newValue)}
                sx={{ ml: -0.75 }}
              />
              <Typography variant="headingSmall" component="span" sx={{ ml: 2 }}>
                {rating}
              </Typography>
            </Stack>
          </FormControl>
          <FormControl color="primary" variant="filled" fullWidth sx={{ mt: 2 }}>
            <InputLabel id="name" shrink={false}>
              {t('feedbackText')}
            </InputLabel>
            <OutlinedInput multiline value={comment} onChange={(e) => setComment(e.target.value)} />
          </FormControl>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} spacing={2}>
          {isInitialLoading ? (
            <LoadingButton loading variant="outlined">
              {t('leaveFeedback')}
            </LoadingButton>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => mutate({ ID_nomenclature: id, comment, grade: rating })}
              disabled={isSuccess}
            >
              {isSuccess ? t('leaveFeedbackDone') : t('leaveFeedback')}
            </Button>
          )}

          <Button color="primary" variant="outlined" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </Stack>
      </Box>
    </StyledModal>
  );
}

AddCommentModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.bool,
};
