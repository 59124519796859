/* eslint-disable */
import React, { useState } from 'react';
import { Box, Container, Dialog, Grid, IconButton, Paper } from '@mui/material';
import * as PropTypes from 'prop-types';
import Slider from 'react-slick';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { SampleNextArrow, SamplePrevArrow } from '../slider-arrows/arrows';
import { CloseIcon } from '../../../../icons/close.icon';

export function ZoomPhotoModal({ open, setOpen, photos }) {
  const [currentSlide, setCurrentSlide] = useState(1);
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    touchMove: false,
    dots: true,
    dotsClass: 'slick-dots slick-dots__image slick-dots__vertical',
    afterChange: (current) => setCurrentSlide(current + 1),
    customPaging: function (i) {
      return (
        <a>
          <Paper
            sx={{
              width: '100%',
              height: '100%',
              boxShadow: 'none',
              background: `url(${photos[i].url}) no-repeat center center`,
              backgroundSize: 'cover',
            }}
            alt=""
          />
        </a>
      );
    },
    responsive: [
      {
        breakpoint: 980,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <Dialog
      fullScreen
      sx={{ zIndex: 9999 }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          position: { md: 'absolute', xs: 'static' },
          right: '34px',
          top: '28px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          background: '#fff',
          zIndex: 1,
          width: { md: 'auto', xs: '100%' },
        }}
      >
        <Box sx={{ marginLeft: '16px' }}>
          {currentSlide} / {photos.length}
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 3, xs: 0 } }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box sx={{ maxWidth: { md: '40vw', xs: '100vw' } }}>
              <Slider {...settings} slidesToShow={1} className="preview-slider">
                {photos.map((item) => (
                  <Box className="slide-big" sx={{ maxHeight: '100vh', maxWidth: '100vw' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                      <TransformWrapper>
                        {({ zoomIn, state, resetTransform }) => {
                          return (
                            <React.Fragment>
                              <Box
                                className={`button_plus ${state.scale !== 1 && 'active'}`}
                                onClick={() => {
                                  state.scale === 1 ? zoomIn(1.5) : resetTransform();
                                }}
                              />
                              <TransformComponent>
                                <img className="slide-img" src={item.url} alt="placeholder" />
                              </TransformComponent>
                            </React.Fragment>
                          );
                        }}
                      </TransformWrapper>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}

ZoomPhotoModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  photos: PropTypes.array,
};
