import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box, Grid } from '@mui/material';
import {
  SampleNextArrow,
  SamplePrevArrow,
} from '../../../pages/product/components/slider-arrows/arrows';
import { ProductItem } from '../../product-item/product-item.component';
import { Dots } from './dots';

export const ProductsComponent = ({ slider, products, columns, fromKey }) => {
  const settings = useMemo(
    () => ({
      slidesToScroll: 1,
      slidesToShow: 4,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      infinite: false,
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: Dots,
            dotsClass: 'slick-dots slick-dots__flex',
            infinite: true,
            dots: true,
          },
        },
      ],
    }),
    []
  );

  return slider ? (
    <Box>
      <Slider {...settings} className="products-list">
        {Array.isArray(products) &&
          products.map((item) => (
            <Box>
              <ProductItem item={item} fromKey={fromKey} />
            </Box>
          ))}
      </Slider>
    </Box>
  ) : (
    <Grid container spacing={5}>
      {Array.isArray(products) &&
        products.map((item) => (
          <Grid item md={12 / columns} xs={6}>
            <ProductItem item={item} fromKey={fromKey} />
          </Grid>
        ))}
    </Grid>
  );
};

ProductsComponent.propTypes = {
  columns: PropTypes.number,
  slider: PropTypes.bool,
  products: PropTypes.array,
  fromKey: PropTypes.string,
};
