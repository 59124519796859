import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CatalogContainer } from '../../components/catalog/catalog.container';
import { useCollectionService } from '../../services/collection.service';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';

export function CollectionCatalog() {
  const [searchParams] = useSearchParams();
  const collection = useMemo(() => JSON.parse(searchParams.get('collection')), [searchParams]);
  const { mutate, data } = useCollectionService();

  const currentBrand = useMemo(
    () => collection && data?.find((item) => item.name === collection[0]?.Name),
    [data]
  );

  useEffect(() => {
    mutate();
  }, []);

  return (
    <PageWrapper>
      <CatalogContainer title={currentBrand?.name} description={currentBrand?.description} />
    </PageWrapper>
  );
}
