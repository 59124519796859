/* eslint-disable */
import React, { useContext } from 'react';
import { Stack, Drawer, IconButton, Typography, Box, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShoppingBag } from '../../icons/shopping-bag.icon';
import { CartContext } from '../../utils/cart.context';
import { EmptyCart } from '../empty-cart/empty-cart.component';
import { PreviewCartItem } from '../preview-cart-item/preview-cart-item.component';
import { CART } from '../../constants/routes';

export const CartDrawer = () => {
  const { t } = useTranslation('translation');
  const { cart, totalPrice, showCart, setShowCart } = useContext(CartContext);
  const navigate = useNavigate();

  const toCart = () => {
    setShowCart(false);
    navigate(CART.path);
  };

  const toMain = () => {
    setShowCart(false);
  };

  return (
    <Drawer anchor="right" open={showCart} onClose={() => setShowCart(false)} sx={{ zIndex: 9999 }}>
      <Box sx={{ maxWidth: '520px', width: '100vw' }}>
        <Stack
          sx={{ padding: { md: '28px 40px', xs: '16px 24px' }, borderBottom: '1px solid #ECECEC' }}
          direction="row"
          alignItems="center"
        >
          <Box sx={{ p: '0px' }}>
            <ShoppingBag />
          </Box>
          <Typography variant="subtitle3" component="div" sx={{ marginLeft: '10px' }}>
            {t('cart')} ({(cart && cart.length) || 0})
          </Typography>
          <IconButton sx={{ marginLeft: 'auto' }} onClick={() => setShowCart(false)}>
            <CloseIcon sx={{ color: '#282932' }} />
          </IconButton>
        </Stack>
        {cart.length > 0 ? (
          <Box
            sx={{
              padding: { md: 5, xs: 3 },
            }}
          >
            <Stack spacing={5} divider={<Divider orientation="horizontal" flexItem />}>
              {cart &&
                cart.map((item) => <PreviewCartItem key={item.UID} {...item} actionButton />)}
            </Stack>
            <Stack sx={{ marginTop: 7.75 }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="p" component="p" className="montserrat dark">
                  {t('orderPrice')}
                </Typography>
                <Typography variant="p" component="p" className="montserrat dark">
                  {totalPrice} грн
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '26px' }}>
                <Typography variant="p" component="p" className="montserrat dark">
                  {t('discount')}
                </Typography>
                <Typography variant="p" component="p" className="montserrat dark">
                  0 грн
                </Typography>
              </Stack>
              <Divider orientation="horizontal" sx={{ marginTop: '26px' }} flexItem />
              <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 3 }}>
                <Typography variant="headingSmall" component="p" className="semi-bold dark">
                  {t('total')}
                </Typography>
                <Typography variant="headingSmall" component="p" className="semi-bold dark">
                  {totalPrice} грн
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ marginTop: 3 }}>
              <Button variant="contained" sx={{ mt: 2, width: '100%' }} onClick={toCart}>
                {t('checkoutOrder')}
              </Button>
              <Button variant="outlined" sx={{ mt: 2, width: '100%' }}>
                {t('complementImage')}
              </Button>
            </Stack>
          </Box>
        ) : (
          <EmptyCart onClose={toMain} />
        )}
      </Box>
    </Drawer>
  );
};
