/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@mui/material';
import * as PropTypes from 'prop-types';

export const PeopleIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" sx={{ fill: 'transparent' }} {...props}>
    <path
      d="M5 19.9996C5 17.4996 7 15.5996 9.4 15.5996H14.5C17 15.5996 18.9 17.5996 18.9 19.9996"
      stroke="#121212"
      stroke-width="1.4824"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0005 5.2C16.7005 6.9 16.7005 9.6 15.0005 11.2C13.3005 12.8 10.6005 12.9 9.00048 11.2C7.40048 9.5 7.30048 6.8 9.00048 5.2C10.7005 3.6 13.3005 3.6 15.0005 5.2"
      stroke="#121212"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);

PeopleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
