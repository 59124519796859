import React from 'react';
import { SvgIcon } from '@mui/material';

export const CancelIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#121212" fill="#fff" />
    <path
      d="M5.3335 5.33398L10.6668 10.6673M10.6668 5.33398L5.3335 10.6673"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
