/* eslint-disable */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { generatePathWithParameters, nameToURL } from './friendlyURl';
import { useMainCategoriesService } from '../services/main-categories.service';
import {
  MAN_MENU_GUID,
  MAN_SEX_OBJECT,
  WOMEN_MENU_GUID,
  WOMEN_SEX_OBJECT,
} from '../constants/categories';
import { findSome, joinUrlValues } from './strings';

export const CategoryFiltersContext = createContext({});

export const CategoryFiltersContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams] = useSearchParams();
  const vendorCode = useMemo(() => JSON.parse(searchParams.get('vendorCode')), [searchParams]);

  const { data: menu } = useMainCategoriesService();

  const [urlValues, setUrlValues] = useState([]);
  const [genderCategory, setGenderCategory] = useState();
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [filterValues, setFilterValues] = useState({});
  const [filters, setFilters] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');

  const [priceOutlet, setPriceOutlet] = useState(false);

  const updatePath = (items) => {
    let filterToUpdate = { ...filterValues, ...items.filters };
    let gender;

    if (items.filters && items.filters.sex && items.filters.sex.length > 0) {
      if (items.filters.sex[0].GUID === WOMEN_SEX_OBJECT.GUID) {
        gender = menu.find(({ GUID }) => GUID === WOMEN_MENU_GUID);
      } else if (items.filters.sex[0].GUID === MAN_SEX_OBJECT.GUID) {
        gender = menu.find(({ GUID }) => GUID === MAN_MENU_GUID);
      }
    }

    const pathname = generatePathWithParameters(currentUrl, {
      gender: nameToURL(gender?.Name || items.gender),
      category: nameToURL(items.category || category?.Name),
      subcategory: nameToURL(items.subcategory || subcategory?.Name),
      ...Object.keys(filterToUpdate).reduce(
        (acc, key) => ({
          ...acc,
          [key]: joinUrlValues(filterToUpdate[key].map(({ Name }) => nameToURL(Name))),
        }),
        {}
      ),
    });

    navigate({
      pathname,
    });
  };

  useEffect(() => {
    if (menu) {
      const foundedGender = findSome(urlValues, menu);
      if (foundedGender.length > 0 && foundedGender[0].Kids.length > 0) {
        setGenderCategory(foundedGender[0]);
        const foundedCategory = findSome(urlValues, foundedGender[0].Kids);
        if (foundedCategory.length > 0) {
          setCategory(foundedCategory[0]);
          const foundedSubcategory = findSome(urlValues, foundedCategory[0].Kids);
          if (foundedSubcategory.length > 0) setSubcategory(foundedSubcategory[0]);
          else setSubcategory(undefined);
        } else {
          setCategory(undefined);
        }
      } else {
        setGenderCategory(menu[0]);
      }
    }

    let newFilters = {};
    filters.forEach(({ key, options }) => {
      newFilters = { ...newFilters, [key]: findSome(urlValues, options) };
    });
    newFilters = Object.keys(newFilters).reduce(
      (acc, key) => (newFilters[key].length > 0 ? { ...acc, [key]: newFilters[key] } : acc),
      {}
    );

    if (!newFilters.sex && filterValues.sex) {
      newFilters.sex = filterValues.sex;
    }

    setFilterValues(newFilters);
  }, [urlValues, filters]);

  useEffect(() => {
    setUrlValues(Object.values(params));
  }, [
    params.brand,
    params.parameter1,
    params.parameter2,
    params.parameter3,
    params.parameter4,
    params.parameter5,
    params.parameter6,
    params.parameter7,
    params.parameter8,
    params.parameter9,
    menu,
  ]);

  useEffect(() => {
    if (!genderCategory && menu) {
      setGenderCategory(menu[0]);
    }
  }, [menu]);

  const props = {
    menu,
    genderCategory,
    category,
    subcategory,
    filterValues,
    updatePath,
    filters,
    setFilters,
    priceOutlet,
    setPriceOutlet,
    setCurrentUrl,
    vendorCode,
  };

  return (
    <CategoryFiltersContext.Provider value={props}>{children}</CategoryFiltersContext.Provider>
  );
};

CategoryFiltersContextProvider.propTypes = {
  children: PropTypes.node,
};
