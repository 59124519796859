export const MENU_PATHS = [
  {
    link: '/search-brand',
    title: 'brands',
  },
  {
    link: '/collection',
    title: 'collections',
  },
  {
    link: '/outlet',
    title: 'outlet',
  },
  {
    link: '/outlet',
    title: 'sale',
  },
  {
    link: '/location',
    title: 'map',
  },
];
