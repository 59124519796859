import { useQuery } from '@tanstack/react-query';
import { apiService } from './api.service';

export function useGetCity() {
  const { isSuccess, data: serverData } = useQuery({
    queryKey: 'useGetCity',
    queryFn: () => apiService.post(`city/`),
  });
  const data = serverData ? serverData.filter(({ parent }) => parent === 'УКРАЇНА') : [];

  return { isSuccess, data };
}
