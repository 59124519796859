import { useState } from 'react';
import { isEqual } from 'lodash';
import i18n from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { apiService } from './api.service';

export function useNomenclatureListGet(key = 'nomenclatures') {
  const [parameters, setStateParameters] = useState({});

  const setParameters = (params) => {
    if (!isEqual(parameters, params)) {
      setStateParameters(params);
    }
  };

  const { isSuccess, data, isLoading } = useQuery({
    queryKey: [key, parameters],
    queryFn: ({ queryKey }) =>
      apiService.post(`nomenclature/getnomenclature/`, {
        language: i18n.language,
        token_user: '',
        page_number: '0',
        items: '24',
        ...queryKey[1],
      }),
    enabled:
      Object.keys(parameters).length !== 0 &&
      (!parameters?.filter || Object.keys(parameters?.filter).length > 0),
  });

  return { setParameters, isSuccess, data, isLoading };
}
