import React from 'react';
import { SvgIcon } from '@mui/material';
import * as PropTypes from 'prop-types';

export const SliderArrowIcon = ({ sx }) => (
  <SvgIcon viewBox="0 0 48 48" sx={sx}>
    <rect
      x="-0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      transform="matrix(-1 0 0 1 47 0)"
      fill="#EEE0D8"
    />
    <path
      d="M31 24H16M31 24L26 29M31 24L26 19"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

SliderArrowIcon.propTypes = {
  sx: PropTypes.object,
};
