/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { ProductItem } from './product-item.component';
import { useNomenclatureGet } from '../../services/nomenclature.service';

export const ProductItemContainer = ({ UID, onProductLoaded, ...props }) => {
  const { mutate, data, isSuccess } = useNomenclatureGet();

  useEffect(() => {
    mutate({ ID_nomenclature: UID });
  }, []);

  useEffect(() => {
    if (data) {
      onProductLoaded && onProductLoaded(data.data[0]);
    }
  }, [isSuccess]);

  return isSuccess && data ? <ProductItem item={data.data[0]} {...props} /> : <div></div>;
};

ProductItemContainer.propTypes = {
  sale: PropTypes.bool,
  UID: PropTypes.string,
  children: PropTypes.node,
};
