import React from 'react';
import { SvgIcon } from '@mui/material';

export const SearchIcon = () => (
  <SvgIcon>
    <path
      d="M17 17L13.05 13.05M8.05 0.99999C6.1789 0.99999 4.38443 1.74328 3.06136 3.06635C1.73829 4.38942 0.994999 6.18389 0.994999 8.05499C0.994999 9.92609 1.73829 11.7206 3.06136 13.0436C4.38443 14.3667 6.1789 15.11 8.05 15.11C9.92243 15.11 11.7182 14.3662 13.0422 13.0422C14.3662 11.7182 15.11 9.92242 15.11 8.04999C15.11 6.17756 14.3662 4.38182 13.0422 3.05782C11.7182 1.73381 9.92243 0.98999 8.05 0.98999V0.99999Z"
      stroke="#121212"
      fill="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
