import React, { useContext, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import { pickBy } from 'lodash';
import { FilterDesktop } from './filter.desktop';
import { FilterMobile } from './filter.mobile';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';
import { ProductListContainer } from '../product-list/product-list.container';

export function Filter({ columns = 4, toAll, maxCount, header, pagination, fromKey }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    updatePath,
    filterValues,
    filters,
    genderCategory,
    category,
    subcategory,
    vendorCode,
    priceOutlet,
  } = useContext(CategoryFiltersContext);

  const menuFilter = useMemo(() => {
    const currentMenu = subcategory || category || genderCategory;
    if (currentMenu)
      return {
        menu: [{ GUID: currentMenu.GUID, Name: currentMenu.Name, Parent: currentMenu.Parent }],
      };
    return {};
  }, [subcategory, category, genderCategory]);

  const optionsList = useMemo(
    () =>
      Object.keys(filterValues)
        .filter((key) => filters.find((item) => item.key === key))
        .reduce((acc, key) => [...acc, ...filterValues[key].map(({ Name }) => Name)], []),
    [filterValues]
  );

  const setFilters = (item, value) => {
    const newOptions = item.options.filter((option) => value.includes(option.Name));

    updatePath({ filters: { [item.key]: newOptions } });

    // if (item.key !== 'brand' || location.pathname != '/brand-catalog')
    // navigate({
    //   pathname: generatePathWithParameters(location.pathname, [nameToURL(value[0])]),
    // });
  };

  return (
    <>
      {isDesktop ? (
        <FilterDesktop
          filters={filters}
          columns={columns}
          setFilters={setFilters}
          optionsList={optionsList}
          values={filterValues}
        />
      ) : (
        <FilterMobile
          filters={filters}
          columns={columns}
          updatePath={updatePath}
          optionsList={optionsList}
          values={filterValues}
        />
      )}

      <ProductListContainer
        fromKey={fromKey}
        filter={pickBy(
          {
            ...menuFilter,
            ...filterValues,
            vendorCode: vendorCode || '',
          },
          (value) => !!value
        )}
        priceOutlet={priceOutlet}
        columns={columns}
        header={header}
        pagination={pagination}
        toAll={toAll}
        maxCount={maxCount}
      />
    </>
  );
}

Filter.propTypes = {
  columns: PropTypes.number,
  maxCount: PropTypes.number,
  toAll: PropTypes.func,
  header: PropTypes.bool,
  pagination: PropTypes.bool,
  fromKey: PropTypes.string,
};
