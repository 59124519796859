/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, IconButton, Link, Stack } from '@mui/material';
import { SearchIcon } from '../../icons/search.icon';
import { useTranslation } from 'react-i18next';
import { MenuDrawer } from './menu-drawer/menu-drawer';
import { SearchDrawer } from './search-drawer/search-drawer.component';
import logo from '../../assets/images/logo.png';
import { generatePathWithParameters, nameToURL } from '../../utils/friendlyURl';
import { CATEGORY_CATALOG } from '../../constants/routes';
import { MENU_PATHS } from './main-menu.constants';

export const MainMenuDesktop = ({ open, setOpen, mainMenu }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'mainMenu' });
  const [parentMenu, setParentMenu] = useState(mainMenu[0]);
  const [searchShow, setSearchShow] = useState(false);

  return (
    <Box sx={{ position: 'sticky', top: '0', zIndex: '999', boxShadow: '0px 1px 1px #ECECEC' }}>
      <Container
        maxWidth="1440"
        disableGutters
        sx={{
          px: { md: 7, xs: 3 },
          position: 'relative',
          zIndex: '9999',
          background: '#fff',
        }}
      >
        <Grid container sx={{ height: '66px', mt: '1px' }} alignItems="center">
          <Grid item xs={2}>
            <Link className="uppercase" variant="h4" href="../" sx={{ display: 'flex' }}>
              <img style={{ maxWidth: '90px' }} src={logo} alt="" />
            </Link>
          </Grid>
          <Grid item xs={8} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={4} justifyContent="center">
              {Array.isArray(mainMenu) &&
                mainMenu.map((item) => (
                  <Link
                    key={item.GUID}
                    className={`uppercase main-menu ${
                      parentMenu?.GUID === item.GUID && open && 'active'
                    }`}
                    variant="textLink"
                    aria-owns={'main-menu'}
                    href={generatePathWithParameters(CATEGORY_CATALOG.path, {
                      gender: nameToURL(item.Name),
                    })}
                    onMouseEnter={() => {
                      setParentMenu(item);
                      setOpen(true);
                    }}
                    onMouseLeave={() => setOpen(false)}
                  >
                    {item.Name}
                  </Link>
                ))}
              {MENU_PATHS.map(({ link, title }) => (
                <Link className="uppercase main-menu" variant="textLink" href={link}>
                  {t(title)}
                </Link>
              ))}
            </Stack>
          </Grid>
          <Grid item alignItems="flex-end" sx={{ ml: 'auto', mr: -1 }}>
            <Stack direction="row">
              <IconButton sx={{ mt: '16px' }} onClick={() => setSearchShow(!searchShow)}>
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {parentMenu && <MenuDrawer menu={parentMenu} setShowMenu={setOpen} showMenu={open} />}
      <SearchDrawer setShow={setSearchShow} show={searchShow} mainMenu={mainMenu} />
    </Box>
  );
};
