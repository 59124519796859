/* eslint-disable */
import React from 'react';
import Slider from 'react-slick';
import { Box, Paper } from '@mui/material';
import * as PropTypes from 'prop-types';
import { ZoomPhotoModal } from '../modal/zoom-photo.component';
import { SampleNextArrow, SamplePrevArrow } from '../slider-arrows/arrows';

export function ProductSlider({ photo }) {
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          dotsClass: 'slick-dots slick-dots__image',
          slidesToShow: Math.min(2, photo.length),
          customPaging: function (i) {
            return (
              <a>
                <Paper
                  sx={{
                    width: '100%',
                    height: '100%',
                    boxShadow: 'none',
                    background: `url(${photo[i].url}) no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                  alt=""
                />
              </a>
            );
          },
        },
      },
      {
        breakpoint: 980,
        settings: {
          nextArrow: undefined,
          prevArrow: undefined,
          slidesToScroll: 1,
          slidesToShow: 1,
          appendDots: (dots) => (
            <div className="slick-dots slick-dots__flex">
              <ul>{dots}</ul>
            </div>
          ),
          dotsClass: 'slick-dots slick-dots__flex',
          dots: true,
        },
      },
    ],
  };

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ pb: 3 }}>
        <Slider {...settings} className={'preview-slider'}>
          {photo.map((item) => (
            <div
              className="slide-big"
              onClick={() => {
                setOpen(true);
              }}
            >
              <img className="slide-img" src={item.url} alt="placeholder" />
            </div>
          ))}
        </Slider>
      </Box>
      <ZoomPhotoModal open={open} setOpen={setOpen} photos={photo} />
    </>
  );
}

ProductSlider.propTypes = {
  photo: PropTypes.array,
};
