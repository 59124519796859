import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { BRAND } from '../../../../constants/routes';
import { generatePathWithParameters, nameToURL } from '../../../../utils/friendlyURl';

export function BrandItem({ name, image, description, lastItem, searchText }) {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const toCatalog = () => {
    navigate({
      pathname: generatePathWithParameters(BRAND.path, { brand: nameToURL(name) }),
    });
  };

  const renderName = useMemo(() => {
    const index = name.indexOf(searchText);
    if (index >= 0 && searchText) {
      return (
        <>
          {name.substring(0, index)}
          <strong>{name.substring(index, index + searchText.length)}</strong>
          {name.substring(index + searchText.length)}
        </>
      );
    }
    return name;
  }, [name, searchText]);

  return (
    <div>
      <Grid container>
        <Grid item xs={3.5} sx={{ alignItems: 'center', display: { md: 'flex', xs: 'none' } }}>
          <div>
            <Typography variant="p" className="montserrat">
              {renderName}
            </Typography>
          </div>
        </Grid>
        <Grid item md={8.5} xs={12}>
          <Paper
            elevation={0}
            square
            sx={{
              width: '160px',
              height: '80px',
              background: `url(${image}) center / contain no-repeat`,
            }}
          />

          <div>
            <Typography variant="body1" sx={{ mt: 3 }}>
              {description || t('noDescription')}
            </Typography>
          </div>
          <Button variant="outlined" size="small" sx={{ mt: 3 }} onClick={toCatalog}>
            {t('toCatalog')}
          </Button>
        </Grid>
      </Grid>
      {!lastItem && (
        <Grid container justifyContent="flex-end">
          <Grid item md={8.5} xs={12}>
            <Divider sx={{ my: 6.25 }} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

BrandItem.propTypes = {
  lastItem: PropTypes.bool,
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  searchText: PropTypes.string,
};
