/* eslint-disable */
import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { StarIcon } from '../../icons/star.icon';

export const Bonus = () => {
  return (
    <Container disableGutters>
      <Typography variant="headingDesktopSmall">Бонуси</Typography>
      <Stack mt={5.25} spacing={5} direction="row">
        <Box sx={{ width: '100%', background: '#F3F9F7', padding: '16px', borderRadius: '4px' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                display: 'flex',
                borderRadius: '100px',
                width: '32px',
                height: '32px',
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StarIcon sx={{ color: '#6FCF97', height: '14px' }} />
            </Box>
            <Stack spacing="2px">
              <Typography variant="body1" className="medium">
                Активні
              </Typography>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="headingDesktopSmall">0</Typography>
                <Typography variant="body1" className="medium">
                  бонусів
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ width: '100%', background: '#F6F6FB', padding: '16px', borderRadius: '4px' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                display: 'flex',
                borderRadius: '100px',
                width: '32px',
                height: '32px',
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StarIcon sx={{ color: '#DADAF2', height: '14px' }} />
            </Box>
            <Stack spacing="2px">
              <Typography variant="body1" className="medium">
                Неактивні
              </Typography>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="headingDesktopSmall">0</Typography>
                <Typography variant="body1" className="medium">
                  бонусів
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ width: '100%', background: '#FCF5F3', padding: '16px', borderRadius: '4px' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                display: 'flex',
                borderRadius: '100px',
                width: '32px',
                height: '32px',
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StarIcon sx={{ color: '#F9D3C8', height: '14px' }} />
            </Box>
            <Stack spacing="2px">
              <Typography variant="body1" className="medium">
                Використані
              </Typography>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="headingDesktopSmall">0</Typography>
                <Typography variant="body1" className="medium">
                  бонусів
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
