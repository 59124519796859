import React from 'react';
import { SvgIcon } from '@mui/material';
import * as PropTypes from 'prop-types';

export const NavArrowPrev = ({ sx }) => (
  <SvgIcon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={sx}
  >
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#EEE0D8" fill="#fff" />
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="white" fillOpacity="0.9" />
    <path
      d="M25 30L19 24L25 18"
      stroke="#535353"
      fill="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

NavArrowPrev.propTypes = {
  sx: PropTypes.object,
};
