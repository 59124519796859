/* eslint-disable */
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Container, Paper, styled, Typography } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetBrand } from '../../../../services/brand.service';
import { mainRoutes, routes } from '../../../../constants/routes';
import { useTranslation } from 'react-i18next';

const Slide = styled((props) => <Paper className="brand-logo" elevation={0} square {...props} />)(
  (props) => ({
    position: 'relative',
    width: '200px',
    height: '80px',
    background: `url(${props.link}) center / contain no-repeat`,
  })
);

export const BrandList = () => {
  const { t } = useTranslation('translation');
  const { data } = useGetBrand();
  const navigate = useNavigate();

  const toCatalog = (name) => {
    navigate({
      pathname: mainRoutes[2].path,
      search: `?${createSearchParams({ brand: JSON.stringify([{ Name: name }]) })}`,
    });
  };

  const settings = {
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container maxWidth="1440" disableGutters sx={{ px: { md: 6, xs: 3 }, pt: 7.25, mt: 2.5 }}>
        <Typography variant="h6" component="h2" align="center" sx={{ mb: 5 }}>
          {t('brandsInOurSite')}
        </Typography>
      </Container>
      {data !== undefined && (
        <div>
          <Slider {...settings}>
            {data
              .filter(({ link }) => link)
              .map(({ link, name }) => (
                <Slide link={link} onClick={() => toCatalog(name)} className="brand-logo" />
              ))}
          </Slider>
        </div>
      )}
    </>
  );
};
