import React, { createContext, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useLocalStorage } from './useLocalStorage';
import { GTMAddToCart, GTMRemoveFromCart } from '../services/analytic-events';
import { parsePrice } from './parse-price';

export const CartContext = createContext({});

export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useLocalStorage('cart', []);
  const [showCart, setShowCart] = useState(false);
  const totalCount = useMemo(
    () => (cart && cart.reduce((acc, item) => acc + item.count, 0)) || 0,
    [cart]
  );

  const calcTotalPrice = (items) =>
    (items &&
      items.length > 0 &&
      items.reduce((acc, item) => acc + parsePrice(item.price) * item.count, 0)) ||
    0;

  const addItem = (item, size, color) => {
    const newCart = [...cart];
    const itemCartIndex = cart.findIndex(
      (cartItem) => cartItem.UID === item.UID && cartItem.size === size
    );
    const cartItem = {
      UID: item.UID,
      mainPhoto: item.mainPhoto,
      name: item.fullname,
      brand: item.additionalinfo.manufacturer,
      price: item.price,
      category: item.category,
      count: 1,
      size,
      color,
    };

    GTMAddToCart(calcTotalPrice([cartItem]), [cartItem]);

    if (itemCartIndex >= 0) {
      newCart[itemCartIndex].count += 1;
    } else {
      newCart.push(cartItem);
    }

    setCart(newCart);
  };

  const removeItem = (UID, size) => {
    const removedItems = cart.filter((item) => item.UID === UID && item.size === size);
    GTMRemoveFromCart(calcTotalPrice(removedItems), removedItems);
    setCart(cart.filter((item) => item.UID !== UID));
  };

  const clearCart = (pure = false) => {
    if (!pure && cart.length > 0) GTMRemoveFromCart(calcTotalPrice(cart), cart);
    setCart([]);
  };

  const changeCount = (UID, size, count) => {
    const cartItems = cart.map((item) => {
      if (item.UID === UID && size === item.size) {
        const updatedItem = { ...item, count };
        if (count >= item.count) {
          const addedItem = { ...item, count: count - item.count };
          GTMAddToCart(calcTotalPrice([addedItem]), [addedItem]);
        } else {
          const removedItem = { ...item, count: item.count - count };
          GTMRemoveFromCart(calcTotalPrice([removedItem]), [removedItem]);
        }
        return updatedItem;
      }
      return item;
    });
    setCart(cartItems);
  };

  const props = useMemo(
    () => ({
      cart,
      totalPrice: calcTotalPrice(cart),
      totalCount,
      addItem,
      removeItem,
      showCart,
      setShowCart,
      changeCount,
      clearCart,
      calcTotalPrice,
    }),
    [cart, showCart]
  );

  return <CartContext.Provider value={props}>{children}</CartContext.Provider>;
};

CartContextProvider.propTypes = {
  children: PropTypes.node,
};
