import React from 'react';
import { CatalogContainer } from '../../components/catalog/catalog.container';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { OUTLET_CATALOG } from '../../constants/routes';

export function OutletCatalog() {
  return (
    <PageWrapper>
      <CatalogContainer title="Outlet" catalogPath={OUTLET_CATALOG.path} priceOutlet />
    </PageWrapper>
  );
}
