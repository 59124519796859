import React from 'react';
import { Container, Grid, Link, Stack } from '@mui/material';
import * as PropTypes from 'prop-types';
import { TopDrawer } from '../../top-drawer/top-drawer';
import { generatePathWithParameters, nameToURL } from '../../../utils/friendlyURl';
import { CATEGORY_CATALOG } from '../../../constants/routes';
import { MenuBannerComponent } from '../menu-banner/menu-banner.component';

export const MenuDrawer = ({ menu, setShowMenu, showMenu }) => (
  <TopDrawer show={showMenu} setShow={setShowMenu}>
    <Container
      maxWidth="1440"
      disableGutters
      sx={{ mt: 7, mb: 9.5 }}
      onMouseLeave={() => setShowMenu(false)}
    >
      <Grid container alignItems="flex-start" wrap>
        <Grid item xs={3}>
          <MenuBannerComponent menu={menu} />
        </Grid>
        <Grid item xs={9}>
          <Stack spacing={7.5} direction="row" sx={{ justifyContent: 'stretch' }}>
            {menu.Kids.map(({ Kids, Name, GUID }) => (
              <div style={{ width: '100%' }}>
                <Link
                  key={GUID}
                  variant="label13"
                  className="main-menu-link"
                  style={{ textTransform: 'capitalize' }}
                  href={generatePathWithParameters(CATEGORY_CATALOG.path, {
                    gender: nameToURL(menu.Name),
                    category: nameToURL(Name),
                  })}
                >
                  {Name}
                </Link>
                <Stack direction="column" spacing={1} sx={{ mt: 3 }} alignItems="flex-start">
                  {Kids.map((item) => (
                    <Link
                      key={item.GUID}
                      variant="body1"
                      className="main-menu-link"
                      style={{ textTransform: 'capitalize' }}
                      href={generatePathWithParameters(CATEGORY_CATALOG.path, {
                        gender: nameToURL(menu.Name),
                        category: nameToURL(Name),
                        subcategory: nameToURL(item.Name),
                      })}
                    >
                      {item.Name}
                    </Link>
                  ))}
                </Stack>
              </div>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </TopDrawer>
);

MenuDrawer.propTypes = {
  menu: PropTypes.object,
  setShowMenu: PropTypes.func,
  showMenu: PropTypes.bool,
};
