import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isArray } from 'lodash';
import { WishlistContext } from '../../utils/wishlist.context';
import { ProductItemContainer } from '../../components/product-item/product-item.container';
import { CartContext } from '../../utils/cart.context';
import { EmptyCart } from '../../components/empty-cart/empty-cart.component';
import { HeartIcon } from '../../icons/heart.icon';

export function SavedProductsComponent() {
  const { t } = useTranslation('translation');
  const { items, changeSize } = useContext(WishlistContext);
  const { addItem, setShowCart } = useContext(CartContext);

  return (
    <div>
      <Stack direction="row" spacing="10.5px">
        <HeartIcon />
        <Typography variant="subtitle3" component="h2" className="bold">
          {t('saved')}
        </Typography>
      </Stack>
      <Grid container spacing={5} sx={{ mt: '1px' }}>
        {items && items.length > 0 ? (
          items.map((item) => (
            <Grid key={item.UID} item md={3} xs={6}>
              <Box>
                <ProductItemContainer key={item.UID} UID={item.UID} fromKey="Wishlist">
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Select
                      labelId="size-label"
                      value={item.size || item.additionalinfo.size[0] || ''}
                      onChange={(value) => {
                        changeSize(item.UID, value.target.value);
                      }}
                      IconComponent={KeyboardArrowDownIcon}
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>{t('chooseSize')}</em>
                      </MenuItem>
                      {item.additionalinfo &&
                        item.additionalinfo.size &&
                        isArray(item.additionalinfo) &&
                        item.additionalinfo.size.map((size) => (
                          <MenuItem value={size}>{size}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, width: '100%', textTransform: 'uppercase' }}
                    onClick={() => {
                      addItem(item, item.size, item.color);
                      setShowCart(true);
                    }}
                  >
                    {t('addToCart')}
                  </Button>
                </ProductItemContainer>
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item md={12} alignItems="center">
            <EmptyCart title={t('emptySavedTitle')} description={t('emptySavedDescription')} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
