import React from 'react';
import { SvgIcon } from '@mui/material';

export const PlusIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 5v14m7-7H5"
    />
  </SvgIcon>
);
