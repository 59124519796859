import React, { useContext, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CatalogComponent } from './catalog.component';
import { useFilterList } from '../../services/filter-list.service';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';
import { compare } from '../../utils/sort-brand';

export function CatalogContainer({ catalogPath, priceOutlet, ...props }) {
  const { t } = useTranslation('translation');
  const { data: filtersData } = useFilterList();
  const { setFilters, setCurrentUrl, setPriceOutlet } = useContext(CategoryFiltersContext);

  const filters = useMemo(
    () => [
      { label: t('gender'), key: 'sex', options: filtersData?.sex || [], multiple: false },
      {
        label: t('size'),
        key: 'size',
        options: [{ Name: '30' }, { Name: '31' }, { Name: '32' }, { Name: '33' }],
        multiple: true,
      },
      { label: t('color'), key: 'color', options: filtersData?.color || [], multiple: true },
      { label: t('season'), key: 'season', options: filtersData?.season || [], multiple: true },
      {
        label: t('price'),
        key: 'price',
        options: [
          { Name: '0 - 500' },
          { Name: '500 - 2000' },
          { Name: '2000 - 5000' },
          { Name: '5000 - 10000' },
        ],
        multiple: true,
      },
      {
        label: t('brand'),
        key: 'brand',
        options:
          filtersData?.brand.map((item) => ({ ...item, name: item.Name })).sort(compare) || [],
        multiple: true,
      },
    ],
    [filtersData]
  );

  useEffect(() => {
    setCurrentUrl(catalogPath);
    setPriceOutlet(priceOutlet);
  }, []);

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  return <CatalogComponent {...props} />;
}

CatalogContainer.propTypes = {
  priceOutlet: PropTypes.bool,
  catalogPath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
