/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../components/input/input-field';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { ChangeEmailModal } from './modals/change-email';
import { ChangePhoneModal } from './modals/change-phone';
import { ChangePasswordModal } from './modals/change-password';
import { useUser } from '../../utils/user.context';
import { useProfileEdit } from '../../services/profile.service';
import { useNotification } from '../../utils/notification.context';

const SettingsScheme = Yup.object().shape({
  FirstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  LastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  Birthday: Yup.date(),
  Gender: Yup.string(),
  city: Yup.string(),
  street: Yup.string(),
  house: Yup.string(),
  flat: Yup.string(),
});

const GENDERS = { MALE: 'Male', FEMALE: 'Female' };
const GENDERS_CYRILLIC = { MALE: 'Мужской', FEMALE: 'Женский' };

export const Settings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { user, loadUser } = useUser();
  const { mutate, data, isSuccess } = useProfileEdit();
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changePhoneModal, setChangePhoneModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const { addNotification } = useNotification();

  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      Birthday: '',
      Gender: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      Phone: '',
      email: '',
      ...user,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: SettingsScheme,
    onSubmit: (values) => {
      mutate({ ...values, Birthday: moment(values.Birthday).format('YYYY-MM-DD') });
    },
  });

  useEffect(() => {
    user && formik.setValues(user);
  }, [user]);

  useEffect(() => {
    if (data) {
      if (isSuccess) {
        loadUser();
        addNotification(t('profileChangedSuccess'), 'success');
      } else {
        addNotification(t('profileChangedError'), 'error');
      }
    }
  }, [isSuccess, data]);

  return user ? (
    <Container maxWidth="md" disableGutters>
      <Typography variant="headingDesktopSmall">Мій профіль</Typography>
      <Stack mt={5.25}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={3.75} columnSpacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle3" component="h2">
                {t('contactDetails')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('firstName')}
                placeholder={t('firstNamePlaceholder')}
                id="FirstName"
                name="FirstName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.FirstName}
                error={formik.touched.FirstName && formik.errors.FirstName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('lastName')}
                placeholder={t('lastNamePlaceholder')}
                id="LastName"
                name="LastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.LastName}
                error={formik.touched.LastName && formik.errors.LastName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormLabel id="birthday">{t('birthday')}</FormLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  sx={{ width: '100%' }}
                  fullWidth
                  onChange={(value) =>
                    formik.setFieldValue('Birthday', moment(value).format('MM/DD/YYYY'), true)
                  }
                  value={formik.values?.Birthday ? moment(formik.values.Birthday) : undefined}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(params) => (
                    <InputField
                      id="Birthday"
                      name="Birthday"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.Birthday}
                      error={formik.touched.Birthday && formik.errors.Birthday}
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormLabel id="gender">{t('gender')}</FormLabel>
              <div>
                <ButtonGroup variant="outlined">
                  <Button
                    variant={
                      formik.values.Gender === GENDERS.MALE ||
                      formik.values.Gender === GENDERS_CYRILLIC.MALE
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => formik.setFieldValue('Gender', GENDERS.MALE, true)}
                  >
                    {t('male')}
                  </Button>
                  <Button
                    variant={
                      formik.values.Gender === GENDERS.FEMALE ||
                      formik.values.Gender === GENDERS_CYRILLIC.FEMALE
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => formik.setFieldValue('Gender', GENDERS.FEMALE, true)}
                  >
                    {t('female')}
                  </Button>
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 4.5, marginBottom: 4 }} orientation="horizontal" flexItem />
          <Grid container rowSpacing={3.75} columnSpacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle3" component="h2">
                {t('contacts')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                onClick={() => {
                  setChangePhoneModal(true);
                }}
                label={t('phone')}
                placeholder={t('phonePlaceholder')}
                id="phone"
                name="phone"
                type="text"
                value={formik.values.Phone}
                error={formik.touched.Phone && formik.errors.Phone}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography
                      variant="subtitle4"
                      component="span"
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('change')}
                    </Typography>
                  </InputAdornment>
                }
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                onClick={() => {
                  setChangeEmailModal(true);
                }}
                label={t('email')}
                placeholder={t('emailPlaceholder')}
                id="email"
                name="email"
                type="text"
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography
                      variant="subtitle4"
                      component="span"
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('change')}
                    </Typography>
                  </InputAdornment>
                }
                disabled
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 4.5, marginBottom: 4 }} orientation="horizontal" flexItem />
          <Grid container rowSpacing={3.75} columnSpacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle3" component="h2">
                {t('shippingAddress')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('city')}
                placeholder={t('cityPlaceholder')}
                id="city"
                name="city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
                error={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('street')}
                placeholder={t('streetPlaceholder')}
                id="street"
                name="street"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.street}
                error={formik.touched.street && formik.errors.street}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('house')}
                placeholder={t('housePlaceholder')}
                id="house"
                name="house"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.house}
                error={formik.touched.house && formik.errors.house}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                label={t('flat')}
                placeholder={t('flatPlaceholder')}
                id="flat"
                name="flat"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.flat}
                error={formik.touched.flat && formik.errors.flat}
              />
            </Grid>
          </Grid>
        </form>
      </Stack>
      <Stack direction="row" spacing={3} sx={{ mt: 4, alignItems: 'flex-end' }}>
        <Button
          variant="contained"
          sx={{ mt: 2, width: '100%' }}
          onClick={() => formik.submitForm()}
        >
          {t('save')}
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 2, width: '100%' }}
          onClick={() => setChangePasswordModal(true)}
        >
          {t('changePassword')}
        </Button>
      </Stack>
      <ChangeEmailModal open={changeEmailModal} handleClose={() => setChangeEmailModal(false)} />
      <ChangePhoneModal open={changePhoneModal} handleClose={() => setChangePhoneModal(false)} />
      <ChangePasswordModal
        open={changePasswordModal}
        handleClose={() => setChangePasswordModal(false)}
      />
    </Container>
  ) : (
    <div></div>
  );
};
