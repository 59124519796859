import { useMutation } from '@tanstack/react-query';
import i18n from 'i18next';
import { apiService } from './api.service';

export function useOrder() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.post(`orders`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}
