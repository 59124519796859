import React from 'react';
import { SvgIcon } from '@mui/material';

export const ArrowRightRoundedIcon = () => (
  <SvgIcon
    sx={{ fontSize: '40px' }}
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 21.5H28.5M14.5 21.5L19.5 26.5M14.5 21.5L19.5 16.5"
      stroke="#121212"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 41C32.2696 41 41 32.2696 41 21.5H43C43 33.3741 33.3741 43 21.5 43V41ZM2 21.5C2 32.2696 10.7304 41 21.5 41V43C9.62588 43 0 33.3741 0 21.5H2ZM21.5 2C10.7304 2 2 10.7304 2 21.5H0C0 9.62588 9.62588 0 21.5 0V2ZM21.5 0C33.3741 0 43 9.62588 43 21.5H41C41 10.7304 32.2696 2 21.5 2V0Z"
      fill="#121212"
    />
  </SvgIcon>
);
