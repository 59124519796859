import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import i18n from 'i18next';
import { isArray } from 'lodash';
import { apiService } from './api.service';
import { normalizeOrders } from '../utils/normalizers';

export function useLogin() {
  const { mutate, isSuccess, data, isError } = useMutation({
    mutationFn: (params) =>
      apiService.post(`authorization/authorization`, { language: i18n.language, ...params }),
  });

  return { mutate, isSuccess, data, isError };
}

export function useRegistration() {
  const { mutate, isSuccess, data, isError } = useMutation({
    mutationFn: (params) =>
      apiService.post(`registration/registration`, {
        language: i18n.language,
        token_user: '',
        ...params,
      }),
  });

  return { mutate, isSuccess, data, isError };
}

export function useGetOtp() {
  const { mutate, isSuccess, data, isError } = useMutation({
    mutationFn: (params) => apiService.post(`SMS/GET`, { language: i18n.language, ...params }),
  });

  return { mutate, isSuccess, data, isError };
}

export function useProfileRead() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.post(`UserInfoRead`, { language: i18n.language, ...params }),
  });

  return { mutate, isSuccess, data };
}

export function useProfileEdit() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.post(`UserInfoEdit`, { language: i18n.language, ...params }),
  });

  return { mutate, isSuccess, data };
}

export function useChangeEmail() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.post(`UserChangeEmail`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}

export function useChangePhone() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.post(`UserChangePhone`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}

export function useChangePassword() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.post(`UserChangePassword`, {
        language: i18n.language,
        ...params,
      }),
  });
  return { mutate, isSuccess, data };
}

export function useFavoritesRead() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.post(`UserFavorites`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}

export function useFavoritesAdd() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) => apiService.put(`UserFavorites`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}

export function useFavoritesDelete() {
  const { mutate, isSuccess, data } = useMutation({
    mutationFn: (params) =>
      apiService.delete(`UserFavorites`, { language: i18n.language, ...params }),
  });
  return { mutate, isSuccess, data };
}

export function useOrdersHistory() {
  const {
    mutate,
    isSuccess,
    data: responseData,
  } = useMutation({
    mutationFn: (params) =>
      apiService.post(`HistoryOrders`, { language: i18n.language, ...params }),
  });

  const data = useMemo(
    () => (isArray(responseData) ? normalizeOrders(responseData) : responseData),
    [responseData]
  );
  return { mutate, isSuccess, data, responseData };
}
