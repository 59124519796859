import React from 'react';
import { Navigate } from 'react-router-dom';
import { Main } from '../pages/main/main';
import { Product } from '../pages/product/product';
import { BrandContainer } from '../pages/brand/brand.container';
import { Collection } from '../pages/collection/collection';
import { SearchBrand } from '../pages/search-brand/search-brand';
import { CategoryCatalog } from '../pages/category-catalog/category-catalog';
import { BrandCatalog } from '../pages/brand-catalog/brand-catalog';
import { CollectionCatalog } from '../pages/collection-catalog/collection-catalog';
import { SearchCatalog } from '../pages/search-catalog/search-catalog';
import { CartPage } from '../pages/cart/cart';
import { Order } from '../pages/order/order';
import { ErrorBoundary } from '../components/error-boundary/error-boundary';
import { ShopMap } from '../pages/shop-map/shop-map';
import { OutletCatalog } from '../pages/outlet-catalog/outlet-catalog';
import { Information } from '../pages/information/information';
import { PublicOfferComponent } from '../pages/information/components/public-offer.component';
import { TermsOfUseComponent } from '../pages/information/components/terms-of-use.component';
import { ReturnAndSwapComponent } from '../pages/information/components/return-and-swap.component';
import { PrivacyPolicyComponent } from '../pages/information/components/privacy-policy.component';
import { FaqComponent } from '../pages/information/components/faq.component';
import { PaymentComponent } from '../pages/information/components/payment.component';
import { DeliveryComponent } from '../pages/information/components/delivery.component';
import { Profile } from '../pages/profile/profile';
import { Settings } from '../pages/profile/settings';
import { ThankYou } from '../pages/thank-you/thank-you';
import { PageWrapper } from '../components/page-wrapper/page-wrapper.component';
import { SavedProductsComponent } from '../pages/saved-products/saved-products.component';
import { SavedProductsContainer } from '../pages/saved-products/saved-products.container';
import { Orders } from '../pages/profile/orders';
import { Bonus } from '../pages/profile/bonus';

export const PARAMETERS = {
  parameter1: ':parameter1/',
  parameter2: ':parameter2/',
  parameter3: ':parameter3/',
  parameter4: ':parameter4/',
  parameter5: ':parameter5/',
  parameter6: ':parameter6/',
  parameter7: ':parameter7/',
  parameter8: ':parameter8/',
  parameter9: ':parameter9/',
};

export const BRAND = {
  path: '/brand',
  ...PARAMETERS,
  parameter1: ':brand/',
};

export const CART = {
  path: '/cart',
};

export const SEARCH_BRAND = {
  path: '/search-brand',
};

export const ORDER = {
  path: '/order',
};

export const LOCATION = {
  path: '/location',
};

export const CATEGORY_CATALOG = {
  path: '/category-catalog',
};

export const OUTLET_CATALOG = {
  path: '/outlet',
};

export const mainRoutes = [
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/product/:id',
    element: <Product />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: BRAND.path,
    element: (
      <PageWrapper>
        <BrandContainer />
      </PageWrapper>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: `${BRAND.parameter1}${BRAND.parameter2}${BRAND.parameter3}${BRAND.parameter4}${BRAND.parameter5}`,
        element: (
          <PageWrapper>
            <BrandContainer />
          </PageWrapper>
        ),
      },
      {
        path: `${BRAND.parameter1}${BRAND.parameter2}${BRAND.parameter3}${BRAND.parameter4}`,
        element: (
          <PageWrapper>
            <BrandContainer />
          </PageWrapper>
        ),
      },
      {
        path: `${BRAND.parameter1}${BRAND.parameter2}${BRAND.parameter3}`,
        element: (
          <PageWrapper>
            <BrandContainer />
          </PageWrapper>
        ),
      },
      {
        path: `${BRAND.parameter1}${BRAND.parameter2}`,
        element: (
          <PageWrapper>
            <BrandContainer />
          </PageWrapper>
        ),
      },
      {
        path: `${BRAND.parameter1}`,
        element: (
          <PageWrapper>
            <BrandContainer />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: '/collection',
    element: <Collection />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: SEARCH_BRAND.path,
    element: <SearchBrand />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: CATEGORY_CATALOG.path,
    element: <CategoryCatalog />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}${PARAMETERS.parameter8}${PARAMETERS.parameter9}`,
        element: <CategoryCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}${PARAMETERS.parameter8}`,
        element: <CategoryCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}`,
        element: <CategoryCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}`,
        element: <CategoryCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}`,
        element: <CategoryCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}`,
        element: <CategoryCatalog />,
      },

      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}`,
        element: <CategoryCatalog />,
      },

      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}`,
        element: <CategoryCatalog />,
      },
      {
        path: PARAMETERS.parameter1,
        element: <CategoryCatalog />,
      },
    ],
  },
  {
    path: '/brand-catalog',
    element: <BrandCatalog />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/collection-catalog',
    element: <CollectionCatalog />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/search-catalog',
    element: <SearchCatalog />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: CART.path,
    element: <CartPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/thank-you',
    element: <ThankYou />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: ORDER.path,
    element: <Order />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: LOCATION.path,
    element: <ShopMap />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/saved',
    element: <SavedProductsContainer />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/information',
    element: <Information />,
    children: [
      {
        title: 'Публічна оферта',
        path: 'public-offer',
        element: <PublicOfferComponent />,
      },
      {
        title: 'Умови використання',
        path: 'terms-of-use',
        element: <TermsOfUseComponent />,
      },
      {
        title: 'Політика конфіденційності',
        path: 'privacy-policy',
        element: <PrivacyPolicyComponent />,
      },
      {
        title: 'Повернення та обмін товару',
        path: 'return-and-swap',
        element: <ReturnAndSwapComponent />,
      },
      {
        title: 'Питання та відповіді',
        path: 'faq',
        element: <FaqComponent />,
      },
      {
        title: 'Оплата',
        path: 'payment',
        element: <PaymentComponent />,
      },
      {
        title: 'Доставка та оплата',
        path: 'delivery-payment',
        element: <DeliveryComponent />,
      },
    ],
  },
  {
    path: OUTLET_CATALOG.path,
    element: <OutletCatalog />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}${PARAMETERS.parameter8}${PARAMETERS.parameter9}`,
        element: <OutletCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}${PARAMETERS.parameter8}`,
        element: <OutletCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}${PARAMETERS.parameter7}`,
        element: <OutletCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}${PARAMETERS.parameter6}`,
        element: <OutletCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}${PARAMETERS.parameter5}`,
        element: <OutletCatalog />,
      },
      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}${PARAMETERS.parameter4}`,
        element: <OutletCatalog />,
      },

      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}${PARAMETERS.parameter3}`,
        element: <OutletCatalog />,
      },

      {
        path: `${PARAMETERS.parameter1}${PARAMETERS.parameter2}`,
        element: <OutletCatalog />,
      },
      {
        path: PARAMETERS.parameter1,
        element: <OutletCatalog />,
      },
    ],
  },
];

export const routes = (isLoggedIn) => [
  ...mainRoutes,
  {
    path: '/profile',
    element: isLoggedIn ? <Profile /> : <Navigate to="/" />,
    children: [
      {
        title: 'Мої замовлення',
        path: 'orders',
        element: isLoggedIn ? <Orders /> : <Navigate to="/" />,
      },
      {
        title: 'Дані профілю',
        path: 'settings',
        element: isLoggedIn ? <Settings /> : <Navigate to="/" />,
      },
      {
        title: 'Обрані',
        path: 'saved',
        element: isLoggedIn ? <SavedProductsComponent /> : <Navigate to="/" />,
      },
      {
        title: 'Бонуси',
        path: 'bonuses',
        element: isLoggedIn ? <Bonus /> : <Navigate to="/" />,
      },
    ],
  },
];
