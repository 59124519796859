import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import * as PropTypes from 'prop-types';

export const ProductListLoaderComponent = ({ columns }) => (
  <Grid container spacing={5}>
    <Grid item md={12 / columns} xs={6}>
      <Skeleton variant="rounded" width="100%" height={400} />
      <Skeleton variant="text" width="100%" height={28} />
      <Skeleton variant="text" width="100%" height={28} />
    </Grid>
    <Grid item md={12 / columns} xs={6}>
      <Skeleton variant="rounded" width="100%" height={400} />
      <Skeleton variant="text" width="100%" height={28} />
      <Skeleton variant="text" width="100%" height={28} />
    </Grid>
    <Grid item md={12 / columns} xs={6}>
      <Skeleton variant="rounded" width="100%" height={400} />
      <Skeleton variant="text" width="100%" height={28} />
      <Skeleton variant="text" width="100%" height={28} />
    </Grid>
    <Grid item md={12 / columns} xs={6}>
      <Skeleton variant="rounded" width="100%" height={400} />
      <Skeleton variant="text" width="100%" height={28} />
      <Skeleton variant="text" width="100%" height={28} />
    </Grid>
  </Grid>
);

ProductListLoaderComponent.propTypes = {
  columns: PropTypes.number,
};
