import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Box, Divider, IconButton, List, ListItem, ListItemText } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MENU_PATHS } from '../main-menu.constants';

export const MainMenuDrawerMobileComponent = ({ menu, setSubmenu }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'mainMenu' });

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#fff',
      }}
    >
      <List component="nav" aria-label="main-menu" sx={{ mt: 2 }}>
        {Array.isArray(menu) &&
          menu.map((item) => (
            <>
              <Divider />
              <ListItem
                disableGutters
                onClick={() => {
                  setSubmenu(item);
                }}
                secondaryAction={
                  <IconButton edge="end">
                    <NavigateNextIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={item.Name} />
              </ListItem>
            </>
          ))}
        <Divider />
        {MENU_PATHS.map(({ link, title }) => (
          <>
            <ListItem disableGutters component={RouterLink} to={link}>
              <ListItemText primary={t(title)} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  );
};

MainMenuDrawerMobileComponent.propTypes = {
  menu: PropTypes.object,
  setSubmenu: PropTypes.func,
};
