/* eslint-disable */
import React, { useContext, useMemo } from 'react';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { NewChip, PaperStyle, TransparentChip } from './product-item.styled';
import { HeartIcon } from '../../icons/heart.icon';
import { WishlistContext } from '../../utils/wishlist.context';
import { GTMClickItem } from '../../services/analytic-events';
import { normalizeNomenclature } from '../../utils/normalizers';

const paperWrapperStyles = {
  position: 'relative',
};

export const ProductItem = ({ item, fromKey, children }) => {
  const { addItem, inWishlist } = useContext(WishlistContext);

  const productItem = useMemo(() => {
    if (item !== undefined) return normalizeNomenclature(item);
    return undefined;
  }, [item]);

  return productItem ? (
    <div>
      <Link
        href={`/product/${productItem.UID}`}
        onClick={() => {
          GTMClickItem(productItem, fromKey);
        }}
        style={{ position: 'relative', display: 'block', marginBottom: '6px' }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: { md: '16px', xs: '8px' },
            right: { md: '16px', xs: '8px' },
            zIndex: 1,
            background: '#fff',
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            addItem(productItem, productItem.additionalinfo.size[0]);
          }}
        >
          <HeartIcon fill={inWishlist(productItem.UID) ? '#121212' : 'white'} />
        </IconButton>
        <Stack direction="column">
          <div style={paperWrapperStyles}>
            <PaperStyle variant="outlined" square image={productItem?.mainPhoto?.url} />
            {productItem.newproduct && <NewChip label="New" background="#000" />}
            {productItem.discount && <NewChip label="Sale" background="#F34D4D" />}
          </div>
          {productItem.additionalinfo && (
            <Typography variant="subtitle2" component="h5" sx={{ mt: 2 }}>
              {productItem.additionalinfo.manufacturer}
            </Typography>
          )}
          <Typography variant="subtitle1" component="h5">
            {productItem.fullname}
          </Typography>
          {productItem.discount ? (
            <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1, fontWeight: '600' }}>
              <Typography variant="crossedText" component="h5">
                {productItem.firstPrice} грн
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {productItem.price} грн
              </Typography>
              <TransparentChip label={`-${productItem.discount}%`} chipColor="#F34D4D" />
            </Stack>
          ) : (
            <Typography variant="subtitle1" component="h5" sx={{ mt: 1, fontWeight: '600' }}>
              {productItem.price} грн
            </Typography>
          )}
        </Stack>
        {children}
      </Link>
    </div>
  ) : (
    <div />
  );
};

ProductItem.propTypes = {
  item: PropTypes.object,
  fromKey: PropTypes.string,
  children: PropTypes.node,
};
