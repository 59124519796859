/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Badge, Box, Container, Grid, IconButton, Link, Stack } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { SearchIcon } from '../../icons/search.icon';
import { ShoppingBag } from '../../icons/shopping-bag.icon';
import { MobileMenuDrawerComponent } from './mobile-menu-drawer/mobile-menu-drawer.component';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/logo.png';
import { CartDrawer } from '../cart-drawer/cart-drawer';
import { CartContext } from '../../utils/cart.context';
import { SearchDrawer } from './search-drawer/search-drawer.component';

export const MainMenuMobile = ({ open, setOpen, mainMenu, goToCategory }) => {
  const { cart, setShowCart } = useContext(CartContext);
  const [searchShow, setSearchShow] = useState(false);

  return (
    <Box sx={{ position: 'sticky', top: '0', zIndex: '999', boxShadow: '0px 1px 1px #ECECEC' }}>
      <Container
        maxWidth="1440"
        disableGutters
        sx={{ px: { md: 7, xs: 3, position: 'relative', zIndex: '9999', background: '#fff' } }}
      >
        <Grid container sx={{ height: '66px', mt: '1px' }} alignItems="center">
          <Grid item xs={2}>
            <Link className="uppercase" variant="h4" href="../" sx={{ display: 'flex' }}>
              <img style={{ maxWidth: '90px' }} src={logo} alt="" />
            </Link>
          </Grid>
          <Grid item alignItems="flex-end" sx={{ ml: 'auto', mr: -1 }}>
            <Stack direction="row">
              <IconButton sx={{ pt: '16px' }} onClick={() => setSearchShow(!searchShow)}>
                <SearchIcon />
              </IconButton>
              <Stack direction="row" sx={{ display: 'flex' }}>
                <IconButton onClick={() => setShowCart(true)}>
                  <Badge badgeContent={cart.length}>
                    <ShoppingBag />
                  </Badge>
                </IconButton>
                <IconButton sx={{ pt: '12px' }} onClick={() => setOpen(!open)}>
                  {!open ? (
                    <MenuIcon sx={{ color: '#121212' }} />
                  ) : (
                    <IconButton onClick={() => setOpen(false)}>
                      <CloseIcon sx={{ color: '#121212' }} />
                    </IconButton>
                  )}
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <MobileMenuDrawerComponent menu={mainMenu} open={open} goToCategory={goToCategory} />
      <CartDrawer />
      <SearchDrawer setShow={setSearchShow} show={searchShow} mainMenu={mainMenu} />
    </Box>
  );
};
