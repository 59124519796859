import React from 'react';
import { Button, Link, styled } from '@mui/material';
import * as PropTypes from 'prop-types';
import { ArrowIcon } from '../../icons/arrow.icon';

const ArrowButtonStyled = styled(Button)(() => ({
  justifyContent: 'flex-start',
  padding: '16px 16px 16px 0',
  marginLeft: '5px',
  '.MuiSvgIcon-root': {
    width: '40px',
    height: '40px',
    marginRight: '12px',
  },
}));

export const ArrowButton = ({ children, sx, href, onClick }) => (
  <Link href={href}>
    <ArrowButtonStyled variant="text" startIcon={<ArrowIcon />} sx={sx} onClick={onClick}>
      {children}
    </ArrowButtonStyled>
  </Link>
);

ArrowButton.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  href: PropTypes.string,
  onClick: PropTypes.func,
};
