import React from 'react';
import { SvgIcon } from '@mui/material';

export const DislikeIcon = () => (
  <SvgIcon
    sx={{ width: '16px', height: '16px' }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="none"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M11.333 1.333h1.78a1.54 1.54 0 0 1 1.554 1.334v4.666a1.54 1.54 0 0 1-1.554 1.334h-1.78M6.667 10v2.667a2 2 0 0 0 2 2l2.666-6V1.333h-7.52A1.333 1.333 0 0 0 2.48 2.467l-.92 6A1.333 1.333 0 0 0 2.893 10h3.774Z"
    />
  </SvgIcon>
);
