import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Paper, Stack, styled, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

const PaperStyle = styled(Paper)(({ image, double }) => ({
  paddingTop: double ? '65%' : '136%',
  background: `url(${image}?uuid=${uuidv4()}) no-repeat center center`,
  backgroundSize: 'cover',
  backgroundOrigin: 'border-box',
  '&.clickable': {
    cursor: 'pointer',
  },
}));

export const SmallBanner = ({ banner, clickable, double }) =>
  banner ? (
    <a href={banner.link}>
      <Stack direction="column">
        <PaperStyle
          className={clickable && 'clickable'}
          variant="outlined"
          square
          image={banner.image}
          double={double}
        />
        <Typography variant="subtitle2" component="h5" sx={{ mt: 2 }}>
          {banner.title}
        </Typography>
        <Typography variant="subtitle1" component="h5" sx={{ mt: 1 }}>
          {banner.description}
        </Typography>
      </Stack>
    </a>
  ) : (
    <div />
  );

SmallBanner.propTypes = {
  clickable: PropTypes.bool,
  double: PropTypes.bool,
  banner: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
  }),
};
