/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNomenclatureGet } from '../../../services/nomenclature.service';
import { OrderProductCard } from './order-product-card';

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  background: '#fff',
  padding: 0,
  '&:not(:first-of-type)': { marginTop: '-1px' },

  '&.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-root': {
    padding: '16px',
    minHeight: 0,
    borderBottom: '1px solid #ECECEC',
    borderTop: '1px solid #ECECEC',

    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& .MuiCollapse-wrapper': { paddingTop: '24px', paddingBottom: '42px' },
  '& .MuiAccordion-region': {
    marginTop: 0,
  },
}));

const OrderInfo = styled(Box)(() => ({
  background: '#F6F6F6',
  padding: '24px',
  width: '100%',
  maxWidth: '346px',
}));

export const OrderItem = ({ item, index }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <AccordionStyled expanded={expanded}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => setExpanded(!expanded)}
        expandIcon={<AddIcon sx={{ fill: '#9C9C9C' }} />}
      >
        <Typography variant="label14Semibold" component="div" sx={{ width: '100%' }}>
          <Stack direction="row">
            <Box flex={1}>
              Замовлення №{index}{' '}
              <Typography variant="body1" component="span" className="montserrat">
                від {item.orderDate}
              </Typography>
            </Box>
            <Box flex={1}>
              1 товар на сумму{' '}
              <Typography variant="label14Semibold" component="span" className="montserrat">
                1231 грн
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle1" component="span" className="lato">
                {item.status}
              </Typography>
            </Box>
          </Stack>
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            {item.products.map((item) => (
              <OrderProductCard id={item.productId} sizeId={item.sizeId} />
            ))}
          </Stack>
          <OrderInfo>
            <Typography variant="subheadingMedium">Інформація</Typography>
            <Stack direction="column" spacing={1.5} sx={{ mt: 1.5 }}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" className="gray">
                  Адреса: {'  '}
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {item.address}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" className="gray">
                  Форма оплати: {'  '}
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {item.paymentType}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" className="gray">
                  Статус замовлення: {'  '}
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {item.status}
                </Typography>
              </Stack>
            </Stack>
          </OrderInfo>
        </Stack>
      </AccordionDetails>
    </AccordionStyled>
  );
};
