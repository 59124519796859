import { parsePrice } from '../utils/parse-price';

const KEY = 'AW-11098642885';
const CURRENCY = 'UAH';

export function GtagPurchase(items) {
  window.gtag('event', 'purchase', {
    send_to: KEY,
    value: items.map(({ price }) => parsePrice(price)),
    items: items.map(({ UID }) => ({
      id: UID,
      google_business_vertical: 'retail',
    })),
  });
}

export function GtagViewSearchResult(items) {
  window.gtag('event', 'view_search_results', {
    send_to: KEY,
    value: items.map(({ price }) => parsePrice(price)),
    items: items.map(({ UID }) => ({
      id: UID,
      google_business_vertical: 'retail',
    })),
  });
}

export function GtagViewItem(item) {
  window.gtag('event', 'view_item', {
    send_to: KEY,
    value: parsePrice(item.price),
    items: [
      {
        id: item.UID,
        google_business_vertical: 'retail',
      },
    ],
  });

  window.fbq('track', 'ViewContent', {
    content_type: 'product',
    content_ids: [item.UID],
    content_name: item.fullname,
    content_category: item.category || 'NO CATEGORY',
    value: parsePrice(item.price),
    currency: CURRENCY,
  });
}

export function GtagViewItemList(items) {
  window.gtag('event', 'view_item_list', {
    send_to: KEY,
    value: items.map(({ price }) => parsePrice(price)),
    items: items.map(({ UID }) => ({
      id: UID,
      google_business_vertical: 'retail',
    })),
  });
}

export function GtagAddToCart(totalPrice, items) {
  window.gtag('event', 'add_to_cart', {
    send_to: KEY,
    value: items.map(({ price }) => parsePrice(price)),
    items: items.map(({ UID }) => ({
      id: UID,
      google_business_vertical: 'retail',
    })),
  });
}

export function GtagViewSearchResults(items) {
  window.gtag('event', 'view_search_results', {
    send_to: KEY,
    value: items.map((price) => parsePrice(price)),
    items: items.map(({ UID }) => ({
      id: UID,
      google_business_vertical: 'retail',
    })),
  });
}

export function GTMViewItemList(items, listName) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_name: listName,
      item_list_id: listName,
      items: items.map(({ fullname, UID, price, brand, category }) => ({
        item_name: fullname,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category || 'NO CATEGORY',
        item_list_name: listName,
        item_list_id: listName,
      })),
    },
  });
}

export function GTMClickItem(item, from) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      item_list_name: from,
      item_list_id: from,
      items: [
        {
          item_name: item.fullname,
          item_id: item.UID,
          price: parsePrice(item.price),
          item_brand: item.brand,
          item_category: item.category || 'NO CATEGORY',
        },
      ],
    },
  });
}

export function GTMViewItem(item) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: CURRENCY,
      value: parsePrice(item.price),
      items: [
        {
          item_name: item.fullname,
          item_id: item.UID,
          price: parsePrice(item.price),
          item_brand: item.brand,
          item_category: item.category || 'NO CATEGORY',
          quantity: '1',
        },
      ],
    },
  });
}

export function GTMAddToCart(totalPrice, items) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: CURRENCY,
      value: totalPrice,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });

  window.fbq('track', 'AddToCart', {
    content_type: 'product',
    content_ids: items.map(({ UID }) => UID),
    content_name: items.map(({ name }) => name),
    value: totalPrice,
    currency: CURRENCY,
  });
}

export function GTMRemoveFromCart(totalPrice, items) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: CURRENCY,
      value: totalPrice,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });
}

export function GTMAddToWishlist(item) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: CURRENCY,
      value: parsePrice(item.price),
      items: [
        {
          item_name: item.fullname,
          item_id: item.UID,
          price: parsePrice(item.price),
          item_brand: item.brand,
          item_category: item.category || 'NO CATEGORY',
          quantity: '1',
        },
      ],
    },
  });
}

export function GTMBeginCheckout(totalPrice, items, totalCount) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: CURRENCY,
      value: totalPrice,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });

  window.fbq('track', 'InitiateCheckout', {
    content_ids: items.map(({ UID }) => UID),
    content_name: items.map(({ name }) => name),
    content_category: items.map(({ category }) => category),
    value: totalPrice,
    currency: CURRENCY,
    num_items: totalCount,
  });
}

export function GTMAddShippingInfo(shippingType, totalPrice, items) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      shipping_tier: shippingType,
      currency: CURRENCY,
      value: totalPrice,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });
}

export function GTMAddPaymentInfo(paymentType, totalPrice, items) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      paymentType,
      currency: CURRENCY,
      value: totalPrice,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });
}

export function GTMAddPurchase(orderId, totalPrice, items, totalCount) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderId,
      value: totalPrice,
      currency: CURRENCY,
      tax: 0,
      shipping: 0,
      items: items.map(({ name, brand, UID, price, count, category }) => ({
        item_name: name,
        item_id: UID,
        price: parsePrice(price),
        item_brand: brand,
        item_category: category,
        quantity: count,
      })),
    },
  });

  window.fbq('track', 'Purchase', {
    content_ids: items.map(({ UID }) => UID),
    content_name: items.map(({ name }) => name),
    content_category: items.map(({ category }) => category),
    value: totalPrice,
    currency: CURRENCY,
    num_items: totalCount,
  });
}
