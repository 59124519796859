import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ru: {
    translation: {
      mainMenu: {
        logIn: 'войти',
        mens: 'Мужчины',
        woman: 'Женщины',
        brands: 'Бренды',
        collections: 'Коллекции',
        outlet: 'Outlet',
        sale: 'Sale',
        map: 'Карта магазинов',
      },
    },
  },
  ua: {
    translation: {
      mainMenu: {
        logIn: 'увійти',
        mens: 'Чоловіки',
        woman: 'Жінки',
        brands: 'Бренди',
        collections: 'Колекції',
        outlet: 'Outlet',
        sale: 'Sale',
        map: 'Карта магазинів',
        bannerTitle: 'Переглянути',
        bannerDescription: 'Всі товари цієї категорії',
        bannerButton: 'В каталог',
      },
      emptyCart: {
        title: 'ВАШ КОШИК ЩЕ ПРОЖНІЙ',
        description:
          'Додавайте товари що сподобались в кошик, щоб полегшити процес підбору цілісного образу.',
      },
      orderForm: {
        contactDetails: 'Контактні дані',
        name: "Ім'я",
        namePlaceholder: "Вкажіть ім'я",
        surname: 'Фамілія',
        surnamePlaceholder: 'Вкажіть фамілію',
        number: 'Номер телефону',
        numberPlaceholder: 'Вкажіть номер телефону',
        emailPlaceholder: 'Вкажіть email',
        street: 'Вулиця',
        streetPlaceholder: 'Вкажіть вулицю',
        house: 'Дім',
        housePlaceholder: 'Вкажіть дім',
        deliveryMethod: 'Спосіб доставки',
        deliveryNovaPoshta: 'Доставка на склад "Нова Пошта"',
        deliveryCourier: "Адресна доставка кур'єром",
        deliverySelf: 'Самовивіз з магазину',
        noOptionsText: 'Нічого не знайдено',
        chooseCity: 'Пошук за містами',
        city: 'Місто',
        warehouseNumber: '№ відділення "Нова Пошта"',
        chooseWarehouse: 'Пошук відділення',
        paymentMethod: 'Спосіб оплати',
        paymentAfterDelivery: 'Оплата після отримання',
        paymentCard: 'Оплата картою',
        comment: 'Коментар',
        addComment: 'Залишити коментар',
        noReCall: 'Мені можна не телефонувати для підтверження замовлення',
      },
      footer: {
        contacts: 'Контакти',
        tel: '+38 067 491 83 81',
        email: 'totheninesorders@gmail.com',
        workingHours: 'Графік роботи',
        workingHoursList: 'пн-пт 9:00 - 18:00',
        return: 'Повернення',
        sizeTable: 'Таблиця розмірів',
        bonusProgram: 'Бонусна програма',
        brends: 'Бренди',
        shopMap: 'Карта магазинів',
        blog: 'Блог',
        news: 'Новини',
        vacancies: 'Вакансії',
        giftCertificates: 'Подарункові сертифікати',
        marketplace: 'Маркетплейс',
        leaveFeedback: 'Залишити відгук',
        paymentMethods: 'Способи оплати',
        followUs: 'Слідкуйте за нами',
        rightsReserved: '© 2023 Shop',
        publicOffer: 'Публічна оферта',
        termsOfUse: 'Умови використання',
        privacyPolicy: 'Політика конфіденційності',
        returnAndSwap: 'Поверенння та обмін товару',
        faq: 'Запитання та відповіді',
        payment: 'Оплата',
        delivery: 'Доставка та оплата',
        officialInformation: 'Службова інформація',
        aboutCompany: 'Про компанію',
      },
      products: 'Товари',
      orderPrice: 'Сумма замовлення',
      delivery: 'Доставка',
      discount: 'Знижка',
      goodsWorth: 'товар у сумі',
      total: 'Всього',
      checkoutOrder: 'Оформити замовлення',
      confirmOrder: 'Підтвердити замовлення',
      complementImage: 'Доповнити образ',
      recommended: 'Вам може сподобатись',
      feedback: 'Відгуки',
      leaveFeedback: 'Залишити відгук',
      rating: 'Оцінка',
      addFeedback: 'Додати відгук',
      feedbackText: 'Текст відгуку',
      noRating: 'Немає оцінок',
      addAnswer: 'Відповісти',
      noFeedback: 'У цього товару немає відгуків. Будь першим хто напише відгук!',
      deliveryWithinUkraine: 'Доставка по Україні від 2 до 7 днів',
      returnsGoods: 'Повернення товару протягом 14 днів',
      color: 'Колір',
      size: 'Розмір',
      gender: 'Стать',
      season: 'Сезон',
      brand: 'Бренд',
      price: 'Ціна',
      count: 'Кількість',
      favorites: 'Вибране',
      remove: 'Видалити',
      readMore: 'Читати більше',
      clearAll: 'Очистити все',
      seeAll: 'Переглянути все',
      more: 'Детальніше',
      apply: 'Застосувати',
      cancel: 'Скасувати',
      filter: 'Фільтр',
      sum: 'Сума',
      sorting: 'Сортування',
      cart: 'Кошик',
      popularGoods: 'Популярні товари',
      goods: 'товарів',
      brandsInOurSite: 'БРЕНДИ НА НАШОМУ САЙТІ',
      aboutUsDescription: 'Зареєструйся та будь в курсі всіх оновлень та найактуальніших знижок',
      aboutUsButton: 'Зареєструватись',
      breadcrumbsMain: 'Головна',
      newProduct: 'НОВИНКИ',
      name: "Вкажіть ім'я",
      number: "Вкажіть ім'я",
      chooseSize: 'Оберіть розмір',
      chooseColor: 'Оберіть колір',
      sizeTable: 'Таблиця розмірів',
      addToCart: 'Додати в кошик',
      remnantsInShop: 'Наявність в магазинах',
      aboutProduct: 'Про продукт',
      chooseCity: 'Оберіть місто',
      city: 'Місто',
      sizeCells: 'Розмірна сітка',
      womenClothes: 'Жіночий одяг',
      womenPants: 'Жіночі штани',
      womenFootwear: 'Жіноче взуття',
      manClothes: 'Чоловічий одяг',
      manPants: 'Чоловічі штани',
      manFootwear: 'Чоловіче взуття',
      sizesUkraine: 'Україна',
      sizesInternational: 'Міжнародні',
      sizesGermany: 'Німеччина',
      sizesUSA: 'США',
      sizesEngland: 'Англія',
      sizesItaly: 'Італія',
      sizesEurope: 'Європа',
      searchByBrand: 'Пошук по бренду',
      searchByBrandOrProduct: 'Пошук по товару або бренду',
      noDescription: 'Немає опису',
      toCatalog: 'В каталог',
      loading: 'Завантаження...',
      shopMap: 'Мапа магазинів',
      saved: 'Обране',
      emptySavedTitle: 'Нічого не додано',
      emptySavedDescription: 'Додайте товар до обраного щоб не загубити',
      findResult: 'Результати по запиту',
      errorMessage: 'Сталася помилка під час завантаження сторінки!',
      updateButton: 'Оновити',
      toMainButton: 'На головну',
      newProducts: 'НОВИНКИ',
      saleProducts: 'РОЗПРОДАЖ',
      leaveFeedbackDone: 'Відгук на модерації',
      profile: {
        logIn: 'Увійти',
        error: 'Помилка.',
        password: 'Пароль',
        passwordPlaceholder: 'Пароль',
        pin: 'Код*',
        pinPlaceholder: ' Код з повідомлення',
        pinInfo: 'Код відправлено на номер ',
        pinNotReceived: 'Не отримали код?',
        pinSendNow: 'Відправити ще раз',
        changeEmailTitle: 'Змінити Email',
        changePhoneTitle: 'Змінити номер телефону',
        changePasswordTitle: 'Змінити пароль',
        contactDetails: 'Особисті дані',
        profileChangedSuccess: 'Зміни збережено!',
        profileChangedError: 'Виникла помилка!',
        emailChangedSuccess: 'Email ушпішно змінено!',
        phoneChangedSuccess: 'Номер телефону ушпішно змінено!',
        emailChangedError: 'Виникла помилка при зміні Email.',
        phoneChangedError: 'Виникла помилка при зміні номеру телефону.',
        passwordChangedSuccess: 'Пароль успішно змінено.',
        passwordChangedError: 'Виникла помилка при зміні пароля.',
        save: 'Зберегти зміни',
        changePassword: 'Змінити пароль',
        change: 'Змінити',
        firstName: 'Імʼя',
        firstNamePlaceholder: 'Імʼя',
        lastName: 'Прізвище',
        lastNamePlaceholder: 'Прізвище',
        birthday: 'Дата народження',
        gender: 'Стать',
        contacts: 'Контактні дані',
        phone: 'Номер телефону',
        phonePlaceholder: 'Номер телефону',
        email: 'Email',
        emailPlaceholder: 'Email',
        shippingAddress: 'Адреса доставки',
        city: 'Місто',
        cityPlaceholder: 'Місто',
        street: 'Вулиця',
        streetPlaceholder: 'Вулиця',
        house: 'Будинок',
        housePlaceholder: 'Будинок',
        flat: 'Квартира',
        flatPlaceholder: 'Квартира',
        male: 'Чоловіча',
        female: 'Жіноча',
        newEmail: 'Новий Email',
        newEmailPlaceholder: 'Новий Email',
        emailConfirm: 'Підтвердіть новий Email',
        emailConfirmPlaceholder: 'Підтвердіть новий Email',
        newPhone: 'Новий номер телефону',
        newPhonePlaceholder: 'Новий номер телефону',
        confirmPhone: 'Підтвердіть новий номер телефону',
        confirmPhonePlaceholder: 'Підтвердіть новий номер телефону',
        newPassword: 'Новий пароль',
        newPasswordPlaceholder: 'Новий пароль',
        confirmPassword: 'Підтвердіть новий пароль',
        confirmPasswordPlaceholder: 'Підтвердіть новий пароль',
        emptyOrdersTitle: 'Ще немає замовлень :(',
        emptyOrdersDescription: 'Зробіть замовлення на нашому сайті',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    backend: {
      backends: [LocalStorageBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
      ],
    },
    lng: 'ua', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
