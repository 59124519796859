import { Chip, Paper, styled } from '@mui/material';

export const PaperStyle = styled(Paper)(({ theme, image }) => ({
  width: '100%',
  paddingTop: '138%',
  background: `url(${image}) no-repeat center center`,
  backgroundSize: 'contain',
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    paddingTop: '138%',
  },
}));

export const NewChip = styled(Chip)(({ background }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  color: '#fff',
  fontSize: '14px',
  lineHeight: '24px',
  backgroundColor: `${background}`,
  borderRadius: '0',
  height: '25px',
  width: '45px',
  '& .MuiChip-label': {
    padding: '0',
  },
}));

export const TransparentChip = styled(Chip)(({ chipColor }) => ({
  color: `${chipColor}`,
  fontSize: '14px',
  lineHeight: '24px',
  border: `1px solid ${chipColor}`,
  backgroundColor: 'transparent',
  borderRadius: '0',
  height: '25px',
  '& .MuiChip-label': {
    padding: '0 8px',
  },
}));
