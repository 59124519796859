/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useMainCategoriesService } from '../../services/main-categories.service';
import { mainRoutes } from '../../constants/routes';
import {
  MAN_MENU_GUID,
  MAN_SEX_OBJECT,
  WOMEN_MENU_GUID,
  WOMEN_SEX_OBJECT,
} from '../../constants/categories';
import { MainMenuMobile } from './main-menu.mobile';
import { useMediaQuery, useTheme } from '@mui/material';
import { MainMenuDesktop } from './main-menu.desktop';
import { CategoryFiltersContext } from '../../utils/categoryFiltersContex';

export const MainMenu = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [showMenu, setShowMenu] = useState(false);
  const { menu: mainMenu } = useContext(CategoryFiltersContext);
  const navigate = useNavigate();

  const goToCategory = ({ sex, menu }) => {
    setShowMenu(false);
    const updatedValue = {};
    if (menu) {
      updatedValue.menu = JSON.stringify([menu]);
    }
    if (sex === WOMEN_MENU_GUID) {
      updatedValue.sex = JSON.stringify([WOMEN_SEX_OBJECT]);
    } else if (sex === MAN_MENU_GUID) {
      updatedValue.sex = JSON.stringify([MAN_SEX_OBJECT]);
    }
    navigate({
      pathname: mainRoutes[6].path,
      search: `?${createSearchParams(updatedValue)}`,
    });
  };

  const location = useLocation();

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return mainMenu ? (
    isDesktop ? (
      <MainMenuDesktop open={showMenu} setOpen={setShowMenu} mainMenu={mainMenu} />
    ) : (
      <MainMenuMobile
        open={showMenu}
        setOpen={setShowMenu}
        mainMenu={mainMenu}
        goToCategory={goToCategory}
      />
    )
  ) : (
    <div />
  );
};
