/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { useProfileRead } from '../services/profile.service';
import { LoginModal } from '../components/login/login-modal';
import { TOKEN_USER } from '../services/api.service';
import { RegistrationModal } from '../components/login/registration-modal';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const { mutate, isSuccess, data } = useProfileRead();
  const [token, setToken] = useLocalStorage(TOKEN_USER, null);
  const [loginModal, setLoginModal] = useState(false);
  const [registrationModal, setRegistrationModal] = useState(false);

  const logOut = () => {
    setToken(null);
    setUser(undefined);
  };

  const openRegistration = () => {
    setLoginModal(false);
    setRegistrationModal(true);
  };

  const openLogin = () => {
    setLoginModal(true);
    setRegistrationModal(false);
  };

  const loadUser = () => {
    if (token) mutate();
  };

  useEffect(() => {
    if (isSuccess && data) {
      setUser(data[0]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (token) mutate();
  }, []);

  useEffect(() => {
    loadUser();
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loadUser,
        token,
        setToken,
        onLogin: () => setLoginModal(true),
        logOut,
      }}
    >
      {children}
      <LoginModal
        open={loginModal}
        handleClose={() => setLoginModal(false)}
        openRegistration={openRegistration}
      />
      <RegistrationModal
        open={registrationModal}
        handleClose={() => setRegistrationModal(false)}
        openLogin={openLogin}
      />
    </UserContext.Provider>
  );
};
