/* eslint-disable */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as PropTypes from 'prop-types';
import visa from '../../assets/images/visa.svg';
import liqpay from '../../assets/images/liqpay.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import appStore from '../../assets/images/app-store.png';
import googlePlay from '../../assets/images/google-play.png';
import { useTranslation } from 'react-i18next';
import { LOCATION, SEARCH_BRAND } from '../../constants/routes';

const Label = styled(Typography)(() => ({
  textTransform: 'uppercase',
  color: '#9C9C9C',
  fontWeight: '500',
  letterSpacing: '0.01em',
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  background: '#282932',
  [theme.breakpoints.down('md')]: {
    padding: '16px 0',
    borderBottom: '1px solid #4F4F4F',
  },
  '.accordion:nth-child(-n+2) &': {
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid #4F4F4F',
    },
  },
  '& .MuiAccordionSummary-root': {
    padding: 0,
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
}));

const Acc = ({ header, children }) => {
  const [expanded, setExpanded] = useState();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <AccordionStyled key={isDesktop} elevation={0} expanded={isDesktop || expanded}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => setExpanded(!expanded)}
        expandIcon={isDesktop ? <div /> : <AddIcon sx={{ fill: '#9C9C9C' }} />}
      >
        <Label variant="subtitle2" component="span">
          {header}
        </Label>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Stack direction="column" spacing={1.5} sx={{ mt: 1.5 }} alignItems="flex-start">
          {children}
        </Stack>
      </AccordionDetails>
    </AccordionStyled>
  );
};

Acc.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
};

export const Footer = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });
  return (
    <Container
      maxWidth="1440"
      disableGutters
      sx={{
        px: { md: 7, xs: 3 },
        mt: 'auto',
        py: 6,
        background: '#282932',
      }}
    >
      <Grid container alignItems="flex-start" wrap="wrap">
        <Grid item xs={12} md={2} sx={{ mt: { xs: 5, md: 0 } }}>
          <Stack direction="column" spacing={1.5} alignItems="flex-start">
            <Label variant="subtitle2" component="span">
              {t('contacts')}
            </Label>
            <Link component="a" variant="body2" sx={{ color: '#fff' }} href={`tel:${t('tel')}`}>
              {t('tel')}
            </Link>
            <Link component="a" variant="body2" sx={{ color: '#fff' }} href={`mail:${t('email')}`}>
              {t('email')}
            </Link>
          </Stack>
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ mt: { xs: 5, md: 8 } }}
            alignItems="flex-start"
          >
            <Label variant="subtitle2" component="span">
              {t('workingHours')}
            </Label>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('workingHoursList')}
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} md={2} sx={{ mt: { xs: 5, md: 0 } }} className="accordion">
          <Acc header="Допомога">
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/privacy-policy">
              {t('privacyPolicy')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/terms-of-use">
              {t('termsOfUse')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/public-offer">
              {t('publicOffer')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/faq">
              {t('faq')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/payment">
              {t('payment')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/delivery-payment">
              {t('delivery')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href="/information/return-and-swap">
              {t('returnAndSwap')}
            </Link>
          </Acc>
        </Grid>
        <Grid item xs={12} md={2} className="accordion">
          <Acc header={t('aboutCompany')}>
            <Link variant="body2" sx={{ color: '#fff' }} href={SEARCH_BRAND.path}>
              {t('brends')}
            </Link>
            <Link variant="body2" sx={{ color: '#fff' }} href={LOCATION.path}>
              {t('shopMap')}
            </Link>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('news')}
            </Link>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('vacancies')}
            </Link>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('giftCertificates')}
            </Link>
          </Acc>
        </Grid>
        <Grid item xs={12} md={2} className="accordion">
          <Acc header={t('officialInformation')}>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('marketplace')}
            </Link>
            <Link
              component="button"
              variant="body2"
              sx={{ color: '#fff' }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              {t('leaveFeedback')}
            </Link>
          </Acc>
        </Grid>
        <Grid item xs={12} md={3} sx={{ ml: 'auto', mt: { xs: 5, md: 0 } }}>
          <Stack direction="column" spacing={1.5} alignItems="flex-start">
            <Label variant="subtitle2" component="span">
              {t('paymentMethods')}
            </Label>
            <Stack direction="row" spacing={1.5} sx={{ mt: 8 }} alignItems="flex-start">
              <div>
                <img src={visa} alt="" />
              </div>
              <div>
                <img src={liqpay} alt="" />
              </div>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ mt: { xs: 5, md: 8 } }}
            alignItems="flex-start"
          >
            <Label variant="subtitle2" component="span">
              {t('followUs')}
            </Label>
            <Stack
              direction="row"
              spacing={1.5}
              sx={{ mt: { xs: 5, md: 8 } }}
              alignItems="flex-start"
            >
              <div>
                <img src={facebook} alt="" />
              </div>
              <div>
                <img src={instagram} alt="" />
              </div>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={1.5}
            sx={{ mt: { xs: 5, md: 8 } }}
            alignItems="flex-start"
          >
            <div>
              <img src={appStore} alt="" />
            </div>
            <div>
              <img src={googlePlay} alt="" />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 6 }} />
      <Typography variant="p" component="p" sx={{ mt: 3 }} color="secondary">
        {t('rightsReserved')}
      </Typography>
    </Container>
  );
};
