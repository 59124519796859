/* eslint-disable */
import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';
import { useNotification } from '../../utils/notification.context';

export const Notifications = () => {
  const { notifications, removeNotification } = useNotification();

  const handleClose = (id) => {
    removeNotification(id);
  };

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          sx={{ zIndex: 999999999999 }}
          key={notification.id}
          open
          autoHideDuration={6000}
          onClose={() => handleClose(notification.id)}
        >
          <Alert
            onClose={() => handleClose(notification.id)}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
