/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, OutlinedInput, Select, Stack } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MobileFilterDrawer } from './mobile-filter-drawer/mobile-filter-drawer';
import { useLocation } from 'react-router-dom';

export function FilterMobile(props) {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState();
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <Stack flexDirection="row" sx={{ mt: 4 }}>
        <FormControl fullWidth>
          <Select
            onClick={() => {
              setOpen(true);
            }}
            disabled
            value={[]}
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment disableTypography position="start">
                    {t('filter')}
                  </InputAdornment>
                }
              />
            }
          ></Select>
        </FormControl>
        <FormControl fullWidth sx={{ ml: 2 }}>
          <Select
            disabled
            value={[]}
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment disableTypography position="start">
                    {t('sorting')}
                  </InputAdornment>
                }
              />
            }
          ></Select>
        </FormControl>
      </Stack>
      <MobileFilterDrawer {...props} open={open} setOpen={setOpen} />
    </>
  );
}

FilterMobile.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.number,
};
