/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useTranslation } from 'react-i18next';
import {
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RoomIcon from '@mui/icons-material/Room';
import { PageWrapper } from '../../components/page-wrapper/page-wrapper.component';
import { theme } from '../../theme';
import { PageBreadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { useGetShops } from '../../services/shops.service';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Marker } from './marker.component';
import { StatusRenderer } from './status-renderer';

const Map = ({ center, zoom, style, children }) => {
  const ref = useRef();
  const [map, setMap] = React.useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [center, zoom]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export function ShopMap() {
  const [markerPosition, setMarkerPosition] = useState({ lat: 49.4436066, lng: 32.0534753 });
  const zoom = 17;
  const { t } = useTranslation('translation');
  const { data: shops } = useGetShops();
  const [city, setCity] = useState('Киев');
  const [open, setOpen] = useState({});
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const cities = useMemo(() => {
    return (
      shops &&
      shops.reduce((groups, item) => {
        const key = item.BusinessRegion['Наименование'];
        if (key !== '') {
          const group = groups[key] || [];
          group.push(item);
          groups[key] = group;
        }
        return groups;
      }, {})
    );
  }, [shops]);

  const storageList = useMemo(() => (cities ? cities[city] : []), [cities, city]);

  return (
    <PageWrapper main>
      <Grid container>
        <Grid item md={4} sx={{ padding: '45px 20px 0 56px' }}>
          <Stack direction="column" spacing={4.5} sx={{ paddingRight: '20px' }}>
            <PageBreadcrumbs productName={t('shopMap')} />
            <FormControl fullWidth>
              <InputLabel id="city-label" shrink={false}>
                {t('city')}
              </InputLabel>
              <Select
                labelId="city-label"
                value={city}
                placeholder={t('chooseCity')}
                onChange={handleCityChange}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ sx: { zIndex: 999999 } }}
              >
                {cities &&
                  Object.keys(cities).map((item) => <MenuItem value={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            sx={{ mt: 3, maxHeight: '32vw', overflow: 'scroll', paddingRight: '20px' }}
          >
            <Typography variant="label12Medium" component="p">
              Знайдено {storageList.length} магазинів
            </Typography>
            <Divider sx={{ mt: 1, mb: 1.5 }} />
            <Stack spacing={1}>
              {storageList.map(({ Name, phone, address, lat, lng, GUID, WorkTime }) => (
                <div>
                  <ListItemButton
                    disableGutters
                    component="div"
                    onClick={() => {
                      if (!open[GUID]) {
                        setMarkerPosition({
                          lat: parseFloat(lat.replace(',', '.')),
                          lng: parseFloat(lng.replace(',', '.')),
                        });
                      }
                      setOpen({ [GUID]: !open[GUID] || false });
                    }}
                  >
                    <Typography variant="subtitle1">{Name}</Typography>
                    <NavigateNextIcon
                      sx={{
                        marginLeft: 'auto',
                        fill: '#4F4F4F',
                        transform: !open[GUID] ? 'rotate(90deg)' : 'rotate(-90deg)',
                        transition: '0.2s',
                      }}
                    />
                  </ListItemButton>
                  <Collapse in={open[GUID] || false} timeout="auto" unmountOnExit sx={{ mt: 0.8 }}>
                    <Typography variant="subtitle1">{phone}</Typography>
                    <Stack direction="row" alignItems="center" sx={{ mt: 0.5, cursor: 'pointer' }}>
                      <RoomIcon fontSize="4" style={{ color: theme.color?.primary200 }} />
                      <Typography variant="body2" sx={{ ml: 0.5 }}>
                        {address}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mt: 0.5, cursor: 'pointer' }}>
                      <RoomIcon fontSize="4" style={{ color: theme.color?.primary200 }} />
                      <Typography variant="body2" sx={{ ml: 0.5 }}>
                        {JSON.stringify(WorkTime)}
                      </Typography>
                    </Stack>
                  </Collapse>
                </div>
                // <Accordion>
                //   <AccordionSummary>
                //     <Typography variant="subtitle1">{Name}</Typography>
                //   </AccordionSummary>
                //   <AccordionDetails sx={{ mt: 2 }}>
                //     <Typography variant="subtitle1">{phone}</Typography>
                //     <Stack
                //       direction="row"
                //       alignItems="center"
                //       sx={{ mt: 0.5 }}
                //       onClick={() =>
                //         setMarkerPosition({
                //           lat: parseFloat(lat.replace(',', '.')),
                //           lng: parseFloat(lng.replace(',', '.')),
                //         })
                //       }
                //     >
                //       <RoomIcon fontSize="4" style={{ color: theme.color?.primary200 }} />
                //       <Typography variant="subtitle1" sx={{ ml: 0.5 }}>
                //         {address}
                //       </Typography>
                //     </Stack>
                //   </AccordionDetails>
                // </Accordion>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={8}>
          <Wrapper apiKey="AIzaSyBlIdPwZlBQYkxUVJZkuSmj0tyOkk3hzYE" render={StatusRenderer}>
            <Map center={markerPosition} zoom={zoom} style={{ height: '45vw' }}>
              <Marker position={markerPosition} />
            </Map>
          </Wrapper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
