import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

export const ReturnAndSwapComponent = () => (
  <Stack direction="column" spacing={2}>
    <Typography variant="headingDesktopSmall">Повернення та обмін товару</Typography>
    <Divider />
    <Typography variant="body2">
      Якщо замовлений товар вам не підійшов - ви можете обміняти його на протязі 14 днів з моменту
      придбання
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Умови повернення</Typography>
    <Typography variant="body2">
      Ви можете повернути товар протягом 14 днів з моменту покупки за умови, що його товарний
      вигляд, властивості, все фабричні ярлики, пломби і упаковка збережені. При поверненні
      необхідно надати документ, який підтверджує покупку.
    </Typography>
    <Typography variant="body2">
      Поверненню за законодавством України не підлягають купальники, нижню білизну, ювелірні вироби
      та парфуми. Повернути замовлення, які не відповідають правилам повернення, неможливо. Вони
      будуть відправлені назад покупцеві.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Процедура повернення</Typography>
    <Typography variant="body2">
      По всій Україні повернення відбувається за допомогою служби &quot;Нова пошта&quot;.
    </Typography>
    <Typography variant="body2">У вас є 14 днів з моменту покупки, щоб повернути товар.</Typography>
    <Typography variant="body2">
      Товари, які вам не підійшли, потрібно відправити за допомогою компанії &quot;Нова пошта&quot;
      на одержувача:
    </Typography>
    <Typography variant="body2">ФОП Папуша Олександр Васильович</Typography>
    <Typography variant="body2">ЄДРПОУ 2804712656</Typography>
    <Typography variant="body2">
      м Черкаси, відділення Нової пошти № 9, контактний номер телефону +38 68 857 87 60;
    </Typography>
    <Typography variant="body2">При відправці повернення доставку оплачує покупець.</Typography>
    <Typography variant="body2">
      У посилці повинні бути товар з його упаковкою і супровідними аксесуарами, а також заповнену
      заяву форма бланка про повернення товару. Будь ласка, збережіть документ з номером ТТН посилки
      і повідомте його нам по телефону або в листі після відправки, щоб прискорити процес повернення
      +38 68 857 87 60.
    </Typography>
    <Divider />
    <Typography variant="subheadingMedium">Повернення коштів:</Typography>
    <Typography variant="body2">
      Повернення коштів клієнт отримує на карту, протягом 14 робочих днів з моменту отримання нами
      товару.
    </Typography>
    <Typography variant="body2">
      Для отримання коштів на банківську карту необхідно також повідомити повні платіжні реквізити
      рахунку (номер карти / IBAN / ПІБ / ІПН). Цю інформацію можна отримати в банку, клієнтом якого
      ви є.
    </Typography>
    <Typography variant="body2">
      Важливо: щоб уникнути махінацій з поверненням товару, ми повертаємо кошти тільки на банківські
      карти, зареєстровані на ім&apos;я покупця, вказане в замовленні.
    </Typography>
    <Typography variant="body2">Завантажити форму бланка про повернення товару. </Typography>
  </Stack>
);
