/* eslint-disable */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useOrdersHistory } from '../../services/profile.service';
import { EmptyCart } from '../../components/empty-cart/empty-cart.component';
import { normalizeOrders } from '../../utils/normalizers';
import { OrderItem } from './components/order-item';

export const Orders = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { data, mutate, isSuccess } = useOrdersHistory();

  useEffect(() => {
    mutate();
  }, []);

  return isSuccess && isArray(data) ? (
    data.map((item, index) => <OrderItem item={item} index={index + 1} />)
  ) : (
    <Grid item md={12} alignItems="center">
      <EmptyCart title={t('emptyOrdersTitle')} description={t('emptyOrdersDescription')} />
    </Grid>
  );
};
